import { useRouteMatch } from "react-router-dom";
import { useMemo } from "react";
import { CompanyProfileSkillRoutesDefinitions } from "../CompanyProfileSkillRoutes";

const useCompanyProfileSkillParams = () => {
  const match = useRouteMatch<any>(CompanyProfileSkillRoutesDefinitions.COMPANY_PROFILE_SKILL_EDIT);

  const params = useMemo(() => ({
    code: match?.params.id,
  }), [match?.params]);

  return params;
}
export default useCompanyProfileSkillParams;