import { CoreRouting } from "@build-buddy/core";
import { CommonState } from "common";
import { useSelector } from "common/state/store";
import OnboardingRoutesDefinitions from "modules/onboarding/OnboardingRouteDefinitions";
import { useEffect } from "react";

const CompanyGuard = (props: any) => {
  const routing = CoreRouting.useRouting();

  // Selector
  const companyId = useSelector(CommonState.App.selectCurrentCompanyId);

  useEffect(() => {
    if(!companyId) return routing.go(OnboardingRoutesDefinitions.INITIAL_DETAILS);    
  }, [companyId]);

  return props.children;
}

export default CoreRouting.withGuard(CompanyGuard);