import { CoreState, CoreUtils, TypeGuards } from "@build-buddy/core";
import transformJob from "./transformers/transformJob";
import transformTask from "@build-buddy/core/dist/state/task/transformers/transformTask";
import transformQuoteRequest from "@build-buddy/core/dist/state/quote/transformers/transformQuoteRequest";
import transformQuote from "@build-buddy/core/dist/state/quote/transformers/transformQuote";
import QuoteTags from "@build-buddy/core/dist/state/quote/QuoteTags";
import TaskTags from "@build-buddy/core/dist/state/task/TaskTags";
import { CommonState } from "common";

const qsMap = {
  "companyOnlySkills": "MyOrganisationSkills",
  "userOnlySkills": "MySkills",
  "companyRadius": "DistanceFromMyOrganisationAddressInKilometers",
  "userRadius": "DistanceFromMyAddressInKilometers",
  "byCompanyServiceAreas": "ByOrganisationServiceAreas",
}

export interface GetJobListParams {
  companyId: string;
  filters?: {
    byCompanyServiceAreas?: boolean;
    companyRadius?: number;
    userRadius?: number;
    userOnlySkills?: boolean;
    companyOnlySkills?: boolean;
    pageNumber?: number;
    pageSize?: number;
  }
}
const getJobList = CoreState.ApiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getJobList: builder.query<any, GetJobListParams>({
      queryFn: async (params, _queryApi, _extraOptions, fetchWithBaseQuery): Promise<any> => {
        const quoteRequestsRespPromise = fetchWithBaseQuery({
          url: [
            `${CoreState.baseUrl("worksite")}/worksite-management/v1/`,
            `quotes?${CoreUtils.Converter.toQueryString(params.filters, qsMap)}`
          ].join(""),
          method: "GET",
          //TODO: add this to base api
          headers: {
            OrganisationPublicId: params.companyId
          }
        })

        const workOrdersRespPromise = fetchWithBaseQuery({
          url: [
            `${CoreState.baseUrl("worksite")}/worksite-management/v1/organisations/${params.companyId}`,
            `/workOrders/`
          ].join(""),
          method: "GET"
        })

        const [quoteRequestsResp, workOrdersResp] = await Promise.all([quoteRequestsRespPromise, workOrdersRespPromise]);

        // dtos
        const quoteRequestDtos = quoteRequestsResp.data as Array<CoreState.Common.DTO.QuoteRequestDTO>;
        const workOrdersDtos = workOrdersResp.data as Array<CoreState.Common.DTO.WorkOrderDTO>
        const kvpWo: { [key: string]: Array<CoreState.Common.DTO.WorkOrderDTO> } = {};

        workOrdersDtos.forEach(wo => {
          if (!wo.quoteRequest) return;
          kvpWo[wo.quoteRequest.publicId] = kvpWo[wo.quoteRequest.publicId] || [];
          kvpWo[wo.quoteRequest.publicId].push(wo);
        })

        // transform dtos
        const jobs = quoteRequestDtos.map(qr => {
          const taskList = transformTask.toModel(qr.task);
          const currentTask = taskList?.find(x => x.isCurrent);
          const quoteRequest = transformQuoteRequest.toModel(qr);
          const quotes = kvpWo[qr.publicId]?.map(wo => transformQuote.toModel(wo)).filter(TypeGuards.nonNullable)
          return transformJob.toModel(currentTask, quoteRequest, quotes);
        })
        return { data: jobs }
      },
      providesTags: (result, _, args) => {
        if (!result) {
          return [
            { type: TaskTags.Task },
            { type: QuoteTags.Quote },
            { type: QuoteTags.QuoteRequest },
          ]
        }

        return [
          ...result.map((item: CommonState.Job.Job) => ({ type: TaskTags?.Task, id: item?.task.parentId })) ?? [{ type: TaskTags?.Task }],
          { type: QuoteTags.Quote },
          { type: QuoteTags.QuoteRequest },
        ]
      }
    }),
  }),
})

export default getJobList.endpoints.getJobList;