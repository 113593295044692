import { CoreModules } from "@build-buddy/core";
import { useSelector } from "common/state/store";
import { useQueryJob } from "../_hooks";
import { selectJobTask } from "../_selectors";

const useVm = () => {
  // queries
  const queryJob = useQueryJob();

  // selectors
  const jobTask = useSelector((s) => selectJobTask(s, queryJob.jobParams));

  return {
    url: jobTask?.extra?.bbProOverviewUrl
  }
}
const JobViewDescription = () => {
  const vm = useVm();
  if (!vm.url) return null;
  return (
    <CoreModules.Task.TaskView.TaskViewDescription url={vm.url} />
  )
}
export default JobViewDescription;