import * as yup from "yup";

const websiteRegex = /^((https):\/\/)?(www.)?(?!.*(https|www.))[a-zA-Z0-9_-]+(\.[a-zA-Z]+)+((\/)[\w#]+)*(\/\w+\?[a-zA-Z0-9_]+=\w+(&[a-zA-Z0-9_]+=\w+)*)?$/gm;

const MoreCompanyDetailsValidation = yup.object().shape({
  address: yup.object().shape({
    longitude: yup.mixed().required("Please enter an address"),
  }),
  website: yup.string().matches(websiteRegex, "Invalid url format"),
});

export default MoreCompanyDetailsValidation;
