import { CoreModules } from "@build-buddy/core";
import { Box, Button, Card, CardContent, Typography } from "@mui/material";

type InitialDetailsCompanyInviteProps = {
  error?: string;
  onCreateCompany(): void;
}
const InitialDetailsCompanyInvite = (props: InitialDetailsCompanyInviteProps) => {
  const { error, onCreateCompany } = props;
  
  return (
    <Box>
      <CoreModules.Membership.Invitation 
        HeaderProps={{ variant: "h2" }} 
        error={error}
      />
      <Card sx={{ mt: 2, mb: 2 }}>
        <CardContent 
          sx={{ 
            "&:last-of-type": {
              pb: 2
            }         
          }}
        >
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              flexDirection: {
                xs: "column",
                sm: "row"
              }
            }}
          >
            <Typography
              variant="h2"
              sx={{
                flex: 1,
                mb: {
                  xs: 2,
                  sm: 0
                }
              }}
            >
              Not your company?
            </Typography>
            <Button
              variant="contained"
              color="primary"
              onClick={() => onCreateCompany()}
            >
              Create a new Company
            </Button>
          </Box>
        </CardContent>
      </Card>
    </Box>
  )
}
export default InitialDetailsCompanyInvite;