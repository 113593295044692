import * as Core from "@build-buddy/core";
import { useSelector } from "common/state/store";
import useJobView from "modules/company/job/job-view/useJobView";
import { useParams } from "react-router";

const useVm = () => {
  const { job } = useJobView();
  const params = useParams<any>();
  
  const [addQuoteRequestNoteThreadMutation, addQuoteRequestNoteThreadQuery] = Core.CoreState.NoteThread.addQuoteNoteThread.useMutation();

  // TODO: Remove Legacy CurrentSelector
  const userId = useSelector(Core.CoreState.User.selectAuthUserId);

  const handleSave = (values: any) => {
    if (job?.quoteRequest?.id) {
      addQuoteRequestNoteThreadMutation({
        projectId: params.pid,
        stageId: params.sid,
        taskId: params.tid,
        quoteRequestId: job.quoteRequest.id,
        toUserId: job.quoteRequest.ownerId,
        content: values.message,
        title: values.topic,
        // TODO: Maybe fix
        authorOrgId: userId,
      });
    }
  };

  return {
    handleSave,
    isSuccess: addQuoteRequestNoteThreadQuery.isSuccess,
    isLoading: addQuoteRequestNoteThreadQuery.isLoading,
    taskName: job?.task?.name || "",
  };
};

const JobViewMessagesEmpty = () => {
  const vm = useVm();
  return (
    <>
      <Core.CoreModules.MessagesEmpty
        title={vm.taskName}
        isSuccess={vm.isSuccess}
        isLoading={vm.isLoading}
        handleSave={vm.handleSave} 
      />
    </>
  );
};
export default JobViewMessagesEmpty;
