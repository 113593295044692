import { Button } from "@mui/material";
import { FormikContextType } from "formik";

interface ServiceAreaFormResetButtonProps {
  formik: FormikContextType<any>;
}

const ServiceAreaFormResetButton = (props: ServiceAreaFormResetButtonProps) => {
  const { formik } = props;

  const initialValues = formik.initialValues;

  return (
    <Button
      variant="text"
      onClick={() => formik.resetForm(initialValues)}
    >
      Reset
    </Button>
  )

}

export default ServiceAreaFormResetButton;