
import * as yup from "yup";

const CompanyProfileTNCFormValidation = yup.object().shape({
  content: yup
  .string()
  .required("Terms and Condition can't be empty.")
  .test("check tAndC", "Terms and Condition can't be empty.", (value: any, context: any) => {
    if(value?.replace(/<\/?p>/g, '').trim() === '') return false
    return true
  }),
});

export default CompanyProfileTNCFormValidation;
