import { Theme } from "@mui/material";
declare module "@mui/material/styles/createPalette" {
  interface Palette {
    blue: ColorPartial;
    blueGrey: ColorPartial;
    red: ColorPartial;
    green: ColorPartial;
    yellow: ColorPartial;
    purple: ColorPartial;
    orange: ColorPartial;
    neutral: Palette['primary'];
  }
  interface PaletteOptions {
    blue: ColorPartial;
    blueGrey: ColorPartial;
    red: ColorPartial;
    green: ColorPartial;
    yellow: ColorPartial;
    purple: ColorPartial;
    orange: ColorPartial;
    neutral: Palette['primary'];
  }
}

const palette = (defaultTheme: Theme) => {
  defaultTheme.palette.primary.main = "#FF6F00";
}

export default palette;