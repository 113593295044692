
import { useRouteMatch } from "react-router";
import JobViewQualityAssuranceRouteDefinitions from "../JobViewQualityAssuranceRouteDefinitions";

const useJobViewQualityAssuranceParams = () => {
  const match = useRouteMatch<any>(JobViewQualityAssuranceRouteDefinitions.JOB_VIEW_QUALITY_ASSURANCE_DEFECTS_VIEW.path);
  return {
    defectId: match?.params.did
  };
}
export default useJobViewQualityAssuranceParams