import { CoreState } from "@build-buddy/core";
import JobStatus from "../../models/JobStatus";


const transformJobStatusIsClosed = (
  task: CoreState.Task.Task,
  taskQuoteRequest: CoreState.Quote.QuoteRequest,
  quotes?: Array<CoreState.Quote.Quote>
) => {
  const acceptedQuote = quotes?.find(q => [CoreState.Quote.QuoteStatus.AwaitingConfirmation, CoreState.Quote.QuoteStatus.Accepted].includes(q.status))

  // if the task is awaiting confirmation or the quote request as already been accepted
  if ((
    task.status === CoreState.Task.TaskStatus.AwaitingConfirmation || 
    taskQuoteRequest.status === CoreState.Quote.QuoteRequestStatus.Accepted
  ) &&
  // AND if the quote has not been accepted
  (!acceptedQuote)
  ) {
    return JobStatus.Closed
  }
}
export default transformJobStatusIsClosed