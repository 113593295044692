import { CoreState } from "@build-buddy/core";
import { useDispatch } from "common/state/store";
import { useEffect, useMemo } from "react";
import useJobViewParams from "./useJobViewParams";

const useQueryJobMessageList = () => {
  // consts
  const POLLING_INTERVAL_IN_MILLISECONDS = 30000;

  const dispatch = useDispatch();
  const { projectId, stageId, taskId, quoteRequestId } = useJobViewParams();

  const quoteNoteThreadListParams = useMemo(() => {
    return {
      projectId,
      stageId,
      taskId,
      quoteRequestId
    }
  }, [projectId, stageId, taskId, quoteRequestId])

  useEffect(() => {
    dispatch(CoreState.NoteThread.getQuoteNoteThreadList.initiate(
      quoteNoteThreadListParams,
      {
        subscriptionOptions:
        {
          pollingInterval: POLLING_INTERVAL_IN_MILLISECONDS,
        }
      }
    ));
  }, [quoteNoteThreadListParams])

  return {
    quoteNoteThreadListParams
  };
}
export default useQueryJobMessageList;