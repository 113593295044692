import {
    CoreComponents,
    CoreRouting,
    CoreState
} from "@build-buddy/core";

import {
    Verified as ABNIcon,
    Person as AccountIcon,
    LocationOn as AddressIcon,
    Business as BusinessTypeIcon,
    Edit as EditIcon,
    Percent as GSTIcon, MailLock as MailIcon,
    MoreVert as MoreIcon,
    PhoneLocked as PhoneIcon,
    Mail as PublicMailIcon,
    Phone as PublicPhoneIcon,
    Language as WebsiteIcon,
} from "@mui/icons-material";

import {
    Box,
    Grid,
    Link
} from "@mui/material";

import { useCompanyParams } from "modules/company/_hooks";

import { useSelector } from "common/state/store";

import { CompanyProfileRouteDefintions } from "../CompanyProfileRoutes";
import CompanyProfileDetailSkeleton from "./CompanyProfileDetailSkeleton";
import CompanyProfileDetailViewAvatar from "./CompanyProfileDetailViewAvatar";

const CompanyProfileDetailView = () => {
  const routing = CoreRouting.useRouting();

  // params
  const { companyId } = useCompanyParams();
  const getCompanyProfileParams = { companyId };
  const getAvatarByCompanyParams = { companyId };
  const getAddressByCompanyParams = { companyId, type: "Primary" };


  // selectors
  const company = useSelector((s) => CoreState.Company.selectCompany(s, getCompanyProfileParams));
  const address = useSelector((s) => CoreState.Address.selectAddressByCompany(s, getAddressByCompanyParams));
  const companyHasAvatar = useSelector((s) => CoreState.Company.selectCompanyHasAvatar(s, { companyId }));

  // queries
  const getCompanyProfileQuery = CoreState.Company.getCompany.useQuery(getCompanyProfileParams, { skip: !companyId });
  const getAvatarByCompanyQuery = CoreState.File.getAvatarByCompany.useQuery(getAvatarByCompanyParams, { skip: (!companyId || !companyHasAvatar) });
  const getAddressByCompanyQuery = CoreState.Address.getAddressByCompany.useQuery(getAddressByCompanyParams, { skip: !companyId });

  // locals
  const isLoading = Boolean(getCompanyProfileQuery.isLoading && getAvatarByCompanyQuery.isLoading && getAddressByCompanyQuery.isLoading);
  const companyName = company?.name;
  const companyGSTStatus = company?.gstRegistered ? "Registered" : "Not Registered";

  const profileItems = [
    { label: 'Company Address', icon: AddressIcon, value: address?.fullAddress },
    { label: 'Website', icon: WebsiteIcon, value: <Link sx={{ cursor: 'pointer' }} onClick={() => window.open(company?.website, "_blank")}>{company?.website}</Link> },
    { label: 'Company Number', icon: PhoneIcon, value: company?.contactNumber },
    { label: 'Company Public Number', icon: PublicPhoneIcon, value: company?.publicContactNumber },
    { label: 'Company Email', icon: MailIcon, value: company?.email },
    { label: 'Company Public Email', icon: PublicMailIcon, value: company?.publicEmailAddress },
    { label: 'Business Type', icon: BusinessTypeIcon, value: company?.type },
    { label: 'ABN', icon: ABNIcon, value: company?.abn },
    { label: 'GST Status', icon: GSTIcon, value: companyGSTStatus },
  ]

  const handleEdit = () => {
    routing.go(CompanyProfileRouteDefintions.COMPANY_PROFILE_EDIT)
  }

  return (
    <CoreComponents.Accordion
      expanded={true}
      icon={AccountIcon}
      title="Company Details"
      description="Overview of your company"
      actions={
        <CoreComponents.MoreMenu
          icon={MoreIcon}
        >
          <CoreComponents.MoreMenuItem
            onClick={handleEdit}
            icon={EditIcon}
            text="Edit"
          />
        </CoreComponents.MoreMenu>
      }
    >
      <CoreComponents.PreContent
        isLoading={isLoading}
        loader={<CompanyProfileDetailSkeleton />}
        isEmpty={false}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: {
              xs: "column",
              md: "row"
            },
            alignItems: {
              xs: "normal",
              md: "center"
            },
            mb: {
              xs: 2,
              md: 4
            },
          }}
        >
          <CompanyProfileDetailViewAvatar />
          <Box>
            <CoreComponents.Truncate
              text={companyName}
              TypographyProps={{ variant: "h3" }}
              lines={1}
            />
          </Box>
        </Box>
        <Grid container spacing={1} maxWidth={1200}>
          {profileItems.map(({ icon: Icon, label, value }, i) => (
            <Grid key={i} item xs={12} sm={6} sx={{ p: 1 }}>
              <CoreComponents.LabelValue
                icon={<Icon />}
                label={label}
                value={value}
              />
            </Grid>
          ))}
        </Grid>
      </CoreComponents.PreContent>
    </CoreComponents.Accordion>
  )
}

export default CompanyProfileDetailView;