import { CoreModules, CoreState } from "@build-buddy/core";
import { CommonState } from "common";
import { useSelector } from "common/state/store";
import { Route } from "react-router-dom";

export const UserWalletRouteDefinitions = CoreModules.Wallet.UserWalletRouteDefinitions;

const WalletModule = () => {
  const currentCompanyId = useSelector(CommonState.App.selectCurrentCompanyId);

  // queries
  const companyAddress = CoreState.Address.getAddressByCompany.useQuery({ companyId: currentCompanyId, type: "Primary" }, { skip: !currentCompanyId });

  const WalletFilterComponent = UserWalletRouteDefinitions.WALLET_FILTERS.component;
  const CreateWalletComponent = UserWalletRouteDefinitions.CREATE_WALLET.component;

  return (
    <>
      <Route
        path={UserWalletRouteDefinitions.WALLET_FILTERS.path}
        render={() => <WalletFilterComponent companyId={currentCompanyId} />}
      />
      <Route
        path={UserWalletRouteDefinitions.WITHDRAWAL_RECEIPT.path}
        component={UserWalletRouteDefinitions.WITHDRAWAL_RECEIPT.component}
      />
      <Route
        path={UserWalletRouteDefinitions.CREATE_WALLET.path}
        render={() => (
          <CreateWalletComponent
            companyId={currentCompanyId}
            address={companyAddress.data?.fullAddress}
          />
        )}
      />
      <Route
        path={UserWalletRouteDefinitions.ADD_BANK_ACCOUNT.path}
        component={UserWalletRouteDefinitions.ADD_BANK_ACCOUNT.component}
      />
      <Route
        path={UserWalletRouteDefinitions.WITHDRAW_MONEY.path}
        component={UserWalletRouteDefinitions.WITHDRAW_MONEY.component}
      />
    </>
  )
}
export default WalletModule
