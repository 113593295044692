import { CoreState } from "@build-buddy/core";
import { CheckCircle as CheckCircleIcon } from "@mui/icons-material";
import { Card, CardContent, Stack, Typography, useTheme } from "@mui/material";
import { CommonState } from "common";
import { useDispatch } from "common/state/store";
import { useEffect } from "react";

type InitialDetailsCompanySuccessProps = {
  company: CoreState.Company.Company;
}
const InitialDetailsCompanySuccess = (props: InitialDetailsCompanySuccessProps) => {
  const { company } = props;
  const theme = useTheme();
  const dispatch = useDispatch()

  useEffect(() => {
    if (!company?.id) return
    dispatch(CommonState.App.setCurrentCompany({ companyId: company.id }))
  }, [company.id])

  return (
    <Card sx={{ mb: 2 }}>
      <CardContent
        sx={{
          background: theme.palette.green[200],
          "&:last-of-type": { pb: 2 }
        }}
      >
        <Stack direction="row" alignItems="center">
          <CheckCircleIcon sx={{ mr: 1.5, fontSize: 36, color: theme.palette.green[500] }} />
          <Typography variant="h5" sx={{ color: theme.palette.green[600] }}>
            You are part of the company {company.name}
          </Typography>
        </Stack>
      </CardContent>
    </Card>
  )
}
export default InitialDetailsCompanySuccess;