import { CoreComponents, CoreRouting, CoreState, useMode } from "@build-buddy/core";
import { Delete as DeleteIcon, Edit as EditIcon, MoreVert as MoreVertIcon, ScheduleSend as ReviewIcon } from "@mui/icons-material";
import { Button } from "@mui/material";
import { useState } from "react";
import { useQueryJob } from "../_hooks";
import useJobView from "../useJobView";
import { selectShowVariationsTab } from "../_selectors";
import JobViewVariationsRouteDefinitions from "./JobViewVariationsRouteDefinitions";
import { useSelector } from "common/state/store";

const useVm = (taskVariation: CoreState.Variation.TaskVariation) => {
  const taskView = useJobView();
  const queryJob = useQueryJob();

  const [updateTaskVariationAction, updateTaskVariationQuery] = CoreState.Variation.updateTaskVariation.useMutation();
  const [deleteJobVariationAction, deleteJobVariationQuery] = CoreState.Variation.deleteTaskVariation.useMutation();

  const isApproved = taskVariation.status === CoreState.Variation.TaskVariationStatus.Approved;
  const showMoreMenu = taskVariation.status === CoreState.Variation.TaskVariationStatus.AwaitingApproval;

  const showActions = useSelector((s) => selectShowVariationsTab(s, queryJob.jobParams))

  const updateVariation = (status: CoreState.Variation.TaskVariationStatus) => {
    updateTaskVariationAction({
      projectId: taskView.params.projectId,
      stageId: taskView.params.stageId,
      taskId: taskView.params.taskId,
      variationId: taskVariation.id,
      taskVariation: {
        name: taskVariation.name,
        description: taskVariation.description,
        status,
        specificationType: 'Variation',
        additionalDays: taskVariation.additionalDays,
        cost: taskVariation.cost
      }
    })
  }

  const deleteDefect = () => {
    deleteJobVariationAction({
      ...taskView.params,
      variationId: taskVariation.id,
    })
  }

  return {
    params: taskView.params,
    taskVariation,
    isSaving: deleteJobVariationQuery.isLoading || updateTaskVariationQuery.isLoading,
    deleteDefect,
    updateVariation,
    isApproved,
    showMoreMenu,
    showActions
  }
}

const JobViewVariationActions = (props: any) => {
  const routing = CoreRouting.useRouting()
  const vm = useVm(props.taskVariation);
  const mode = useMode();
  const [showConfirmModal, setShowConfirmModal] = useState(false);

  const handleEdit = () => {
    const params = {
      pid: vm.params.projectId,
      sid: vm.params.stageId,
      qid: vm.params.quoteRequestId,
      tid: vm.params.taskId,
      type: 'variation',
      variationId: props.taskVariation.id
    };
    routing.go(JobViewVariationsRouteDefinitions.JOB_VIEW_VARIATIONS_EDIT, { params });
  }

  const sendToReview = () => {
    vm.updateVariation(CoreState.Variation.TaskVariationStatus.InReview);
  }

  const handleDelete = () => {
    vm.deleteDefect()
  }

  if (!vm.showActions) return null;
  return (
    <>
      <CoreComponents.Loader show={vm.isSaving} />
      <CoreComponents.Confirmation
        open={showConfirmModal}
        title="Delete Variation"
        text="Are you sure you want to delete?"
        onCancel={() => setShowConfirmModal(false)}
        onClose={() => setShowConfirmModal(false)}
        onConfirm={() => handleDelete()}
        loading={vm.isSaving}
      />
      {mode.md && vm.isApproved &&
        <Button
          variant="contained"
          size="small"
          onClick={() => sendToReview()}
        >
          Send for Review
        </Button>
      }
      <CoreComponents.MoreMenu
        icon={MoreVertIcon}
        IconProps={{ edge: "end", sx: { ml: 1 } }}
      >
        {(mode.xs && !mode.md) && vm.isApproved &&
          <CoreComponents.MoreMenuItem
            text="Send for Review"
            icon={ReviewIcon}
            onClick={() => sendToReview()}
          />
        }
        {vm.showMoreMenu &&
          <>
            <CoreComponents.MoreMenuItem
              text="Edit"
              icon={EditIcon}
              onClick={() => handleEdit()}
            />
            <CoreComponents.MoreMenuItem
              text="Delete"
              icon={DeleteIcon}
              color="secondary"
              onClick={() => setShowConfirmModal(true)}
            />
          </>
        }

      </CoreComponents.MoreMenu>
    </>
  )
}
export default JobViewVariationActions;