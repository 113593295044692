import CompanyProfile from "./CompanyProfile";

import CompanyProfileSettingsEdit from "./company-profile-settings/CompanyProfileSettingsEdit";
import CompanyProfileDetailEdit from "./company-profile-detail/CompanyProfileDetailEdit";
import ServiceAreaAdd from "modules/onboarding/components/setup-service-area/ServiceAreaAdd";
import ServiceAreaEdit from "modules/onboarding/components/setup-service-area/ServiceAreaEdit";

const CompanyProfileRouteDefintions = {
  COMPANY_PROFILE: {
    path: "/company/:cid/profile",
    component: CompanyProfile
  },
  COMPANY_PROFILE_EDIT: {
    path: "/company/:cid/profile/edit",
    component: CompanyProfileDetailEdit
  },
  COMPANY_PROFILE_SETTINGS_EDIT:{
    path: "/company/:cid/profile/settings/edit",
    component: CompanyProfileSettingsEdit
  },
  COMPANY_PROFILE_SERVICE_AREA_ADD:{
    path:"/company/:cid/profile/service-area/add",
    component: ServiceAreaAdd
  },
  COMPANY_PROFILE_SERVICE_AREA_EDIT:{
    path:"/company/:cid/profile/service-area/:said/edit",
    component: ServiceAreaEdit
  }
}

export { CompanyProfileRouteDefintions };
