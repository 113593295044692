import { createSelector } from "@reduxjs/toolkit";
import { CoreState } from "@build-buddy/core";
import { selectCurrentCompanyId } from "./selectAppContext";

const selectIsCurrentUserOwner = createSelector(
  [selectCurrentCompanyId, CoreState.User.selectCurrentUserCompanies],
  (currentCompanyId, ownerCompanies) => !!ownerCompanies?.find(company => company.companyId === currentCompanyId)
)

export default selectIsCurrentUserOwner;


