import { LocationOn as LocationOnIcon } from "@mui/icons-material";
import { Stack, Typography } from "@mui/material";
import { useSelector } from "common/state/store";
import { useQueryJob } from "../_hooks";
import { selectJobQuoteRequest } from "../_selectors";

const useVm = () => {
  // queries
  const queryJob = useQueryJob();

  // selectors
  const jobQuoteRequest = useSelector((s) => selectJobQuoteRequest(s, queryJob.jobParams));

  return {
    quoteRequest: jobQuoteRequest
  }
}

const JobViewContentAddress = () => {
  const vm = useVm();
  
  if(!vm.quoteRequest || !vm.quoteRequest.address) return null;

  return (
    <Stack direction="row" alignItems="center" sx={{ mb: 3 }}>
      <LocationOnIcon sx={{ mr: 0.75 }} />
      <Typography variant="body1"> {vm.quoteRequest.address.fullAddress}</Typography>
    </Stack>
  )
}
export default JobViewContentAddress;