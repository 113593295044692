
import { Route } from "react-router";
import CompanyProfileTNCEdit from "./CompanyProfileTNCEdit";
import CompanyProfileTNCAdd from "./CompanyProfileTNCAdd";

const CompanyProfileTNCRouteDefinitions = {
  COMPANY_PROFILE_TNC_ADD: {
    path: "/company/:cid/profile/tnc/add",
    component: CompanyProfileTNCAdd,
  },
  COMPANY_PROFILE_TNC_EDIT: {
    path: "/company/:cid/profile/tnc/edit",
    component: CompanyProfileTNCEdit,
  },
}

const CompanyProfileTNCRoutes = () => {
  return (
    <>
      <Route
        path={CompanyProfileTNCRouteDefinitions.COMPANY_PROFILE_TNC_ADD.path}
        component={CompanyProfileTNCRouteDefinitions.COMPANY_PROFILE_TNC_ADD.component}
      />
      <Route
        path={CompanyProfileTNCRouteDefinitions.COMPANY_PROFILE_TNC_EDIT.path}
        component={CompanyProfileTNCRouteDefinitions.COMPANY_PROFILE_TNC_EDIT.component}
      />
    </>
  );
};

export { CompanyProfileTNCRouteDefinitions, CompanyProfileTNCRoutes };
