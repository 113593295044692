import { CoreComponents, CoreRouting, CoreState } from "@build-buddy/core";
import { useSelector } from "common/state/store";
import { useCompanyParams } from "modules/company/_hooks";
import { useEffect } from "react";
import { CompanyProfileRouteDefintions } from "../CompanyProfileRoutes";
import { useCompanyProfileTeamParams } from "./_hooks";

const CompanyProfileTeamsMemberEdit = () => {
  const routing = CoreRouting.useRouting();

  // params
  const { companyId } = useCompanyParams();
  const { memberId } = useCompanyProfileTeamParams();
  const getMemberByCompanyParams = { companyId, memberId };

  // queries
  const getMemberByCompanyQuery = CoreState.Membership.getMemberByCompany.useQuery(getMemberByCompanyParams)

  // mutations  
  const [updateMemberByCompanyAction, updateMemberByCompanyQuery] = CoreState.Membership.updateMemberByCompany.useMutation();

  // locals
  const member = useSelector((s) => CoreState.Membership.selectMemberByCompany(s, getMemberByCompanyParams));
  const isLoading = Boolean(getMemberByCompanyQuery.isLoading);
  const isSaving = Boolean(updateMemberByCompanyQuery.isLoading);
  const isSuccess = Boolean(updateMemberByCompanyQuery.isSuccess);

  // events
  const handleClose = () => {
    routing.go(CompanyProfileRouteDefintions.COMPANY_PROFILE);
  }

  const handleSubmit = (values: any) => {
    const member: CoreState.Common.Subset<CoreState.Membership.Member> = {
      name: values.name,
      email: values.email,
      contact: values.contact,
      type: values.access
    }

    updateMemberByCompanyAction({ companyId, memberId, member })
  }

  useEffect(() => {
    if (!isSuccess) return;
    handleClose();
  }, [isSuccess])

  return (
    <CoreComponents.TeamMembersFormDialog 
      title="Edit Member"
      accessTypes={[CoreState.Company.CompanyAccessLevel.Manager]}
      defaults={{
        name: member?.name,
        email: member?.email,
        contact: member?.contact,
        access: member?.type
      }}      
      isSaving={isSaving}
      onSubmit={handleSubmit}
      onClose={handleClose}
    />
  )
}
export default CompanyProfileTeamsMemberEdit;