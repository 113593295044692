import { CoreState } from "@build-buddy/core";
import JobStatus from "../../models/JobStatus";

const transformJobStatusIsQuoting = (
  task: CoreState.Task.Task,
  taskQuoteRequest: CoreState.Quote.QuoteRequest,
  quotes?: Array<CoreState.Quote.Quote>
) => {
  if(!quotes || ![CoreState.Task.TaskStatus.QuotesRequested, CoreState.Task.TaskStatus.QuotesReceived].includes(task.status)) return; 

  if(quotes.find(x => x.status === CoreState.Quote.QuoteStatus.Submitted)) return JobStatus.QuoteSubmitted;

  if(quotes.find(x => x.status === CoreState.Quote.QuoteStatus.Declined)) return JobStatus.QuoteDeclined;
}
export default transformJobStatusIsQuoting