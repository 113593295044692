import { CoreState } from "@build-buddy/core";
import JobStatus from "../../models/JobStatus";

const transformJobStatusIsWip = (
  task: CoreState.Task.Task,
  taskQuoteRequest: CoreState.Quote.QuoteRequest,
  quotes?: Array<CoreState.Quote.Quote>
) => {
  if(task.type !== CoreState.Task.TaskType.QualityCheck) return;
  
  return JobStatus.InQA
}
export default transformJobStatusIsWip