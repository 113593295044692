import { CoreComponents, CoreRouting, CoreState } from "@build-buddy/core";
import { ServiceAreaType } from "@build-buddy/core/dist/state/common/dtos/profile-management.generated";
import { Delete as DeleteIcon, Edit as EditIcon } from "@mui/icons-material";
import { IconButton, Stack, Typography, useTheme } from "@mui/material";
import { CommonState } from "common";
import { useSelector } from "common/state/store";
import ServiceAreaRouteDefinitions from "./ServiceAreaRouteDefinitions";
import ServiceAreaTag from "./ServiceAreaTag";

interface ServiceAreaListItemProps {
  serviceAreaTrimmed: CoreState.Company.ServiceAreaTrimmed;
}

const ServiceAreaListItem = (props: ServiceAreaListItemProps) => {
  const theme = useTheme();
  const routing = CoreRouting.useRouting();

  const { serviceAreaTrimmed } = props;

  // mutations
  const [deleteServiceAreaByCompanyAction, deleteServiceAreaByCompanyQuery] = CoreState.Company.deleteServiceAreaByCompany.useMutation();

  // selectors
  const companyId = useSelector(CommonState.App.selectCurrentCompanyId);
  const serviceArea = useSelector((state) => CoreState.Company.selectServiceAreaByCompany(state, { companyId }));

  // handlers
  const handleDelete = (id: string) => {
    if (!serviceArea || !companyId) return;

    let deletedServiceAreas: Array<string> = [];
    let stack = [...serviceArea.children ?? []];

    while (stack.length) {
      const child = stack.pop();

      if (child?.type === ServiceAreaType.ServiceArea) {
        deletedServiceAreas.push(child.id);
      }
      if ((child?.id === id || child?.type === ServiceAreaType.ServiceAreaRegion) && child?.children) {
        stack.push(...child.children);
      }
    }
    deleteServiceAreaByCompanyAction({ companyId, deletedServiceAreas });
  }

  const handleEdit = (id: string) => {
    routing.go(ServiceAreaRouteDefinitions.SERVICE_AREA_EDIT, { params: { said: id } })
  };

  return (
    <Stack
      sx={{
        position:"relative",
        height: "90px",
        border: `1px solid ${theme.palette.grey[100]}`,
        borderRadius: theme.spacing(2),
        padding: theme.spacing(2)
      }}
    >
      <CoreComponents.Loader show={deleteServiceAreaByCompanyQuery.isLoading} />
      <Stack
        direction="row"
        justifyContent="space-between"
        alignItems="center"
      >
        <Typography variant="h3">{serviceAreaTrimmed.name}</Typography>
        <Stack
          direction="row"
          gap={1}
        >
          <IconButton
            onClick={() => handleEdit(serviceAreaTrimmed.id)}
            sx={{
              cursor: "pointer"
            }}
          >
            <EditIcon />
          </IconButton>
          <IconButton
            onClick={() => handleDelete(serviceAreaTrimmed.id)}
            edge="end"
          >
            <DeleteIcon />
          </IconButton>
        </Stack>
      </Stack>
      <ServiceAreaTag numSelected={serviceAreaTrimmed.areasSelected} />
    </Stack>
  )
}

export default ServiceAreaListItem