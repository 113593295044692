import { CoreComponents, CoreRouting, CoreState } from "@build-buddy/core";
import { CommonState } from "common";
import { useSelector } from "common/state/store";
import { CompanyRouteDefinitions } from "../../CompanyRoutes";

type CompanyProfileSettingsFormProps = {
  isLoading: boolean;
}
const CompanyProfileSettingsForm = (props: CompanyProfileSettingsFormProps) => {
  const { isLoading } = props;
  const routing = CoreRouting.useRouting();

  // params
  const companyId = useSelector(CommonState.App.selectCurrentCompanyId);
  const getNotificationSettingListByCompanyParams = { companyId };
  const getCompanyProfileParams = { companyId };

  // queries
  const getCompanyQuery = CoreState.Company.getCompany.useQuery(getCompanyProfileParams, { skip: !companyId });

  // mutataions
  const [updateCompanyAction, updateCompanyQuery] = CoreState.Company.updateCompany.useMutation();
  const [saveNotificationSettingByCompanyAction, saveNotificationSettingByCompanyActionQuery] = CoreState.Settings.saveNotificationSettingByCompany.useMutation();

  // locals
  const company = useSelector((s) => CoreState.Company.selectCompany(s, getCompanyProfileParams));
  const companyNotificationSettingIsEnabled = useSelector((s: CoreState.RootState) => CoreState.Settings.selectNotificationSettingByCompanyIsEnabled(s, getNotificationSettingListByCompanyParams));
  const companyNotificationSetting = useSelector((s: CoreState.RootState) => CoreState.Settings.selectNotificationSettingByCompanyGrouped(s, getNotificationSettingListByCompanyParams))
  const isSaving = Boolean(saveNotificationSettingByCompanyActionQuery.isLoading && !saveNotificationSettingByCompanyActionQuery.isUninitialized);
  const isSuccess = Boolean(saveNotificationSettingByCompanyActionQuery.isSuccess && !saveNotificationSettingByCompanyActionQuery.isUninitialized);

  const handleSubmit = (notificationSetting: CoreState.Common.Subset<CoreState.Settings.NotificationSetting>) => {
    if (!companyId) return;

    saveNotificationSettingByCompanyAction({
      companyId,
      notificationSetting
    })
  }

  const handleClose = () => {
    if (!company?.flags?.notificationReviewed) {
      updateCompanyAction({
        companyId,
        company: {
          ...company,
          flags: {
            ...company?.flags,
            notificationReviewed: true,
          }
        }
      });
    }
    routing.go(CompanyRouteDefinitions.COMPANY_PROFILE);
  }

  return (
    <CoreComponents.SettingFormDialog
      isSaving={isSaving}
      isSuccess={isSuccess}
      isLoading={isLoading}
      onClose={handleClose}
      onSubmit={handleSubmit}
      notificationSettingGrouped={companyNotificationSetting as CoreState.Settings.GroupedNotificationSettingType}
      isNotificationSettingEnabled={companyNotificationSettingIsEnabled}
    />
  )
}
export default CompanyProfileSettingsForm;