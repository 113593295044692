import { CoreModules, CoreState } from "@build-buddy/core";
import { useSelector } from "common/state/store";
import { useJobViewParams } from "../../_hooks";
import { JobViewQualityAssuranceDefectsDotProgress } from "../_common";

type JobViewQualityAssuranceChecklistDotProgressProps = {
  qualityAssuranceItem: CoreState.QualityAssurance.QualityAssurance;
}
const JobViewQualityAssuranceChecklistDotProgress = (props: JobViewQualityAssuranceChecklistDotProgressProps) => {
  const { qualityAssuranceItem } = props;

  // params
  const { projectId, stageId, taskId } = useJobViewParams();
  const getDefectListByTaskParams = { projectId, stageId, taskId };

  // queries
  CoreState.Defect.getDefectListByTask.useQuery(getDefectListByTaskParams);

  // selectors
  const defects = useSelector((s) => CoreState.Defect.selectDefectListByTask(s, getDefectListByTaskParams));

  return (
    <CoreModules.Task.TaskView.TaskViewQualityAssuranceChecklistDefectsDotProgress 
      defects={defects}
      qualityAssuranceItem={qualityAssuranceItem}
      dotProgress={JobViewQualityAssuranceDefectsDotProgress}
    />
  )
}
export default JobViewQualityAssuranceChecklistDotProgress;