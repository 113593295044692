import { createSelector } from "@reduxjs/toolkit";
import { CommonState } from "common";
import { CoreUtils } from "@build-buddy/core";

const selectDashboardJobList = createSelector(
  CommonState.Job.selectJobList,
  (jobList) => {
    return jobList;
  }
)

interface SeletDashboardJobListByStatusParams extends CommonState.Job.SelectJobListParams {
  status?: "active" | "new",
  bookmarked?: string;
}
const seletDashboardJobListByStatus = createSelector(
  [
    (state, params: SeletDashboardJobListByStatusParams) => selectDashboardJobList(state, params),
    (_, params: SeletDashboardJobListByStatusParams) => ({ bookmarked: params.bookmarked, status: params.status })
  ],
  (jobList, meta) => {
    if (!jobList) return [];
    if (!meta.bookmarked && !meta.status) return jobList;
    //Groups jobList By Status
    const jobListGroupedByStatus = jobList.reduce((acc, val) => {
      return ({
        ...acc,
        [val?.status ?? ""]: [...(acc[val?.status ?? ""] ?? []), val]
      });
    }, {} as Record<(CommonState.Job.JobStatus | string), Array<CommonState.Job.Job>>);

    const sortTaskListByStatuses = (statuses: Array<CommonState.Job.JobStatus>) => {
      return statuses.reduce((acc, val) => {
        acc = acc.concat(jobListGroupedByStatus[val]?.sort((a, b) => new Date(a.task?.startDate).getTime() - new Date(b.task?.startDate).getTime()) ?? []);
        return acc;
      }, [] as Array<CommonState.Job.Job>)
    }

    if (meta.status === "new") {
      const newStatuses = [
        CommonState.Job.JobStatus.AwaitingConfirmation,
        CommonState.Job.JobStatus.New,
        CommonState.Job.JobStatus.QuoteSubmitted,
        CommonState.Job.JobStatus.QuoteDeclined,
      ];


      // Creates an array of all the tasks sorted in sort order provided
      const orderedTasks = sortTaskListByStatuses(newStatuses);

      return [
        ...orderedTasks,
      ];
    }

    if (meta.status === "active") {
      const activeStatuses = [
        CommonState.Job.JobStatus.InProgress,
        CommonState.Job.JobStatus.AwaitingToStart,
        CommonState.Job.JobStatus.AwaitingSignOff,
        CommonState.Job.JobStatus.InQA,
        CommonState.Job.JobStatus.AwaitingOwner,
        CommonState.Job.JobStatus.AwaitingConstruction,
      ];

      // Creates an array of all the tasks sorted in sort order provided
      const orderedTasks = sortTaskListByStatuses(activeStatuses);

      return [
        ...orderedTasks,
      ];
    }

    if (meta.bookmarked) {
      return jobList.filter(job => job.task.extra?.bookmarks?.includes(meta.bookmarked as string));
    }

    if (meta.status === "completed") {
      const activeStatuses = [
        CommonState.Job.JobStatus.Completed,
      ];
      return jobList.filter(x => activeStatuses.includes(x.status));
    }

    return jobList;
  }
)

export interface SelectDashboardJobListByStatusSearchParams extends SeletDashboardJobListByStatusParams {
  search?: string;
}
const selectDashboardJobListByStatusSearch = createSelector(
  [
    (state, params: SelectDashboardJobListByStatusSearchParams) => seletDashboardJobListByStatus(state, params),
    (_, params: SelectDashboardJobListByStatusSearchParams) => ({ search: params.search })
  ],
  (jobList, meta) => {
    if (!meta.search) return jobList;
    return jobList
      .map(t => {
        if (!t.task.bbProDisplayText) return t;
        return (t.task.bbProDisplayText.search(new RegExp(meta.search as string, "i")) >= 0) ? t : undefined
      })
      .filter(CoreUtils.TypeGuards.nonNullable);
  }
)

interface SelectGroupedDashboardJobListByStateParams extends SelectDashboardJobListByStatusSearchParams {
  state?: string;
}

export const selectGroupedDashboardJobListByState = createSelector(
  [
    (state, params: SelectGroupedDashboardJobListByStateParams) => {
      const prevParams = {
        bookmarked: params.bookmarked,
        companyId: params.companyId,
        filters: params.filters,
        search: params.search,
        status: params.status,
      }
      return selectDashboardJobListByStatusSearch(state, prevParams)
    },
    (_, params: SelectGroupedDashboardJobListByStateParams) => ({ search: params.search, state: params.state })],
  (jobList, params) => {
    const groupByState = (data: Array<CommonState.Job.Job>, state?: string): Array<CommonState.Job.Job> => {
      // Separate the objects into the specified state and other states
      const stateObjects = data.filter(item => item.quoteRequest.address?.state === state);
      const otherStateObjects = data.filter(item => item.quoteRequest.address?.state !== state);

      // Group the other state objects by their state
      const groupedOtherStates = otherStateObjects.reduce<{ [key: string]: Array<CommonState.Job.Job> }>((acc, item) => {
        const itemState = item.quoteRequest.address?.state || '';
        if (!acc[itemState]) {
          acc[itemState] = [];
        }
        acc[itemState].push(item);
        return acc;
      }, {});

      // Flatten the grouped other state objects back into an array
      const groupedOtherStatesArray = Object.values(groupedOtherStates).flat();

      // Concatenate the specified state objects with the grouped other state objects
      return [...stateObjects, ...groupedOtherStatesArray];
    }
    const finalJobList = groupByState(jobList, params.state)
    return finalJobList
  }
)
