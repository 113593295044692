import * as Yup from "yup";

const contactNumberRegex = /^(\+614)\d{8}$/;
const publicContactNumberRegex = /^((\+61)\d{10}|(\+61)\d{9})$/;

const InitialDetailsFormValidation = Yup.object().shape({
  user: Yup.object().shape({
    firstName: Yup.mixed().required("Enter your first name"),
    lastName: Yup.mixed().required("Enter your last name"),
    mobile: Yup
      .string()
      .matches(contactNumberRegex, "Please enter mobile number in format of : 412345678")
      .required("Enter mobile number"),
  }),
  company: Yup.object().shape({
    name: Yup
      .mixed()
      .required("Enter your business name"),
    contactNumber: Yup
      .string()
      .matches(contactNumberRegex, "Please enter mobile number in format of : 412345678")
      .required("Enter mobile number"),
    email: Yup
      .string()
      .required("Enter email address.")
      .email("Please enter a valid email"),
    publicContactNumber: Yup
      .string()
      .matches(publicContactNumberRegex, "Please enter valid number."),
    publicEmailAddress: Yup
      .string()
      .email("Please enter a valid email"),
    usePersonalContactNumber: Yup.mixed().test("contact mobile check", "You have entered the same number from your user profile. To continue you must select the checkbox above", (value: boolean, context: any) => {
      if (value || context.parent.usePersonalNumberForContact) return true;
      if (!Boolean(context.parent.mobile)) return true;
      return (context.parent.contactNumber !== context.parent.mobile);
    }),
    usePersonalEmail: Yup.mixed().test("contact email check", "You have entered the same email from your user profile. To continue you must select the checkbox above", (value: boolean, context: any) => {
      if (value || context.parent.usePersonalEmailForContact) return true;
      if (!Boolean(context.parent.email)) return true;
      return (context.parent.contactEmail !== context.parent.email);
    }),
  }),
  tnc: Yup.bool().oneOf([true], "Please accept the terms and conditions"),
});

export default InitialDetailsFormValidation;