import { CoreComponents } from "@build-buddy/core";
import { Box } from "@mui/material";
import { FormikContextType, getIn } from "formik";
import { useEffect } from "react";

type FormikCompanyContactNumberProps = {
  formik: FormikContextType<any>;
  number?: string;
  checkboxName: string;
  textboxName: string;
}
const FormikCompanyContactNumber = (props: FormikCompanyContactNumberProps) => {
  const { formik, number, checkboxName, textboxName } = props;

  const initial = getIn(formik.values, textboxName);
  const isPersonalNumber = getIn(formik.values, checkboxName);

  useEffect(() => {
    if (!isPersonalNumber) {
      formik.setFieldValue(textboxName, initial ?? "");
      return;
    }
    formik.setFieldValue(textboxName, number);
  }, [isPersonalNumber])

  useEffect(() => {
    if(!isPersonalNumber) return;
    formik.setFieldValue(textboxName, number);
  }, [number])

  return (
    <Box>
      <CoreComponents.Label
        label="Company Contact Number*"
        sx={{ mb: 0.75 }}
        tooltip="This number will be used for quote requests and business SMS notifications from Build Buddy."
      />
      <CoreComponents.FormikCheckbox
        formik={formik}
        name={checkboxName}
        label="Use my personal number"
      />
      <CoreComponents.FormikMobileNumber
        formik={formik}
        name={textboxName}
        disabled={Boolean(isPersonalNumber)}
      />
    </Box>
  )
}
export default FormikCompanyContactNumber;