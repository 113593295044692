
import { Route } from "react-router";
import CompanyProfileInsuranceAdd from "./CompanyProfileInsuranceAdd";
import CompanyProfileInsuranceEdit from "./CompanyProfileInsuranceEdit";

const CompanyProfileInsuranceRoutesDefinitions = {
  COMPANY_PROFILE_INSURANCE_ADD: {
    path: "/company/:cid/profile/insurance/add",
    component: CompanyProfileInsuranceAdd,
  },
  COMPANY_PROFILE_INSURANCE_EDIT: {
    path: "/company/:cid/profile/insurance/:iid/edit",
    component: CompanyProfileInsuranceEdit,
  }
}

const CompanyProfileInsuranceRoutes = () => {
  return (
    <>
      <Route
        path={CompanyProfileInsuranceRoutesDefinitions.COMPANY_PROFILE_INSURANCE_ADD.path}
        component={CompanyProfileInsuranceRoutesDefinitions.COMPANY_PROFILE_INSURANCE_ADD.component}
      />
      <Route
        path={CompanyProfileInsuranceRoutesDefinitions.COMPANY_PROFILE_INSURANCE_EDIT.path}
        component={CompanyProfileInsuranceRoutesDefinitions.COMPANY_PROFILE_INSURANCE_EDIT.component}
      />
    </>
  );
};

export { CompanyProfileInsuranceRoutesDefinitions, CompanyProfileInsuranceRoutes };
