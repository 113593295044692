import { CoreComponents, CoreRouting, CoreState } from "@build-buddy/core";
import { Dialog, DialogContent } from "@mui/material";
import { useEffect } from "react";
import { useParams } from "react-router";
import useJobView from "../useJobView";
import JobViewVariationForm from "./JobViewVariationForm";
import JobViewVariationsRouteDefinitions from "./JobViewVariationsRouteDefinitions";

const useVm = () => {
  const params = useParams<any>();
  const taskView = useJobView();
  const getJobVariationQuery = CoreState.Variation.getVariationById.useQuery({ ...taskView.params, variationId: params.variationId })
  const [updateJobVariationAction, updateJobVariationQuery] = CoreState.Variation.updateTaskVariation.useMutation();
  const addTaskDefects = (values: any) => {
    updateJobVariationAction({
      projectId: taskView.params.projectId,
      stageId: taskView.params.stageId,
      taskId: taskView.params.taskId,
      variationId: taskView.params.variationId,
      taskVariation: {
        name: values.title,
        description: values.description,
        cost: values.additionalCost,
        additionalDays: values.extraDays,
        specificationType: 'Variation'
      }
    })
  }
  return {
    params: taskView.params,
    taskDefect: getJobVariationQuery.data,
    addTaskDefects,
    isSaving: updateJobVariationQuery.isLoading,
    isSaved: updateJobVariationQuery.isSuccess,
    error: (updateJobVariationQuery?.error as { message: any })?.message
  }
}

const JobViewVariationEdit = () => {
  const vm = useVm();
  const routing = CoreRouting.useRouting();

  const handleClose = () => {
    const params = {
      pid: vm.params.projectId,
      sid: vm.params.stageId,
      qid: vm.params.quoteRequestId,
      tid: vm.params.taskId
    };
    routing.go(JobViewVariationsRouteDefinitions.JOB_VIEW_VARIATIONS, { params });
  }

  const handleSubmit = (values: any) => {
    vm.addTaskDefects(values)
  }

  useEffect(() => {
    if (!vm.isSaved) return;
    handleClose();
  }, [vm.isSaved]);

  return (
    <>
      <Dialog open={true}>
        <CoreComponents.Loader show={vm.isSaving} />
        <CoreComponents.DialogHeader title={`Edit Variation`} onClose={() => handleClose()} />
        <DialogContent>
          <JobViewVariationForm
            onSubmit={(values) => handleSubmit(values)}
            onClose={() => handleClose()}
            type={'Variation'}
            values={vm.taskDefect as CoreState.Variation.TaskVariation}
          />
        </DialogContent>
      </Dialog>
    </>
  )

}
export default JobViewVariationEdit;