import { CoreComponents, CoreRouting, CoreState } from '@build-buddy/core';
import { CommonState } from 'common';
import { JobRouteDefinitions } from 'modules/company/job/JobRoutes';
import { useEffect } from 'react';
import { useJobViewParams, useQueryJob } from '../../_hooks';
import { selectJobQuoteCurrent } from '../../_selectors';
import { useDispatch, useSelector } from 'common/state/store';

const JobViewPaymentReceiptViewer = () => {
  const routing = CoreRouting.useRouting();
  const dispatch = useDispatch();
  const queryJob = useQueryJob();

  const currentCompanyId = useSelector(CommonState.App.selectCurrentCompanyId);

  // params
  const { projectId, taskId, stageId, paymentScheduleId, quoteRequestId } = useJobViewParams();
  const quoteId = quoteRequestId;

  // params
  const quoteDocListParams = { projectId, quoteId, taskId, companyId: currentCompanyId };
  const quoteReceiptParams = { projectId, quoteId, taskId, paymentScheduleId, companyId: currentCompanyId };

  // queries
  const quoteDocListQuery = CoreState.File.getDocListByTask.useQuery(quoteDocListParams);

  // Generate receipt
  const [generateReceiptAction, generateReceiptQuery] = CoreState.Payment.generatePaymentReceipt.useMutation();

  // Selector
  const receipt = useSelector((s) => CoreState.File.selectReceiptWithFeeByPaymentSchedule(s, quoteReceiptParams));
  const fileState = useSelector((s) => CoreState.File.selectFileState(s, receipt?.id));
  const jobQuote = useSelector((s) => selectJobQuoteCurrent(s, queryJob.jobParams));

  useEffect(() => {
    if (!jobQuote) return;
    if (receipt) return;
    generateReceiptAction({
      projectId,
      taskId,
      paymentScheduleId,
      quoteId: jobQuote?.id,
      quoteRequestId: quoteRequestId,
      companyId: currentCompanyId
    })
  }, [])

  useEffect(() => {
    if (!receipt) return
    dispatch(CoreState.File.downloadDocByType(receipt));
  }, [receipt])

  // event handlers
  const handleBack = () => {
    const params = { pid: projectId, sid: stageId, tid: taskId, qid: quoteRequestId }
    routing.go(JobRouteDefinitions.JOB_VIEW_PAYMENT_SCHEDULE, { params })
  }

  return (
    <CoreComponents.DocumentViewer
      open={true}
      doc={receipt as any}
      fileState={fileState}
      onClose={handleBack}
      isLoading={generateReceiptQuery.isLoading || quoteDocListQuery.isLoading}
      loader={<CoreComponents.DocumentViewerLoading onClose={handleBack} />}
      isEmpty={!receipt}
      empty={<CoreComponents.DocumentViewerEmpty onClose={handleBack} />}
      isError={generateReceiptQuery.isError}
      error={<CoreComponents.DocumentViewerError onClose={handleBack} error={generateReceiptQuery.error as CoreState.Common.Error} />}
    />
  )
}

export default JobViewPaymentReceiptViewer;