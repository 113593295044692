import { ServiceAreaType } from "@build-buddy/core/dist/state/common/dtos/profile-management.generated";
import { FormikContextType } from "formik";
import ServiceAreaTag from "./ServiceAreaTag";
import { ServiceAreaFormikValues } from "./setup-service-area-form-dialog/ServiceAreaFormDialogContent";

interface ServiceAreaSelectLabelContainerProps {
  formik: FormikContextType<ServiceAreaFormikValues>;
}

const ServiceAreaSelectLabelContainer = (props: ServiceAreaSelectLabelContainerProps) => {
  const { formik } = props;

  const getCount = () => {
    let count = 0;
    let stack = [...(formik.values.serviceAreas?.children ?? [])];

    while (stack.length) {
      const child = stack.pop();
      
      if (child && child.children && child.children.length) {
        stack.push(...child.children);
      }

      if (child && child.isChecked && child.type === ServiceAreaType.ServiceArea) {
        count++;
      }
    }

    return count;
  }

  return (
    <ServiceAreaTag
      numSelected={getCount()}
    />
  )
}

export default ServiceAreaSelectLabelContainer;