import { CommonState } from "common";
import { useSelector } from "common/state/store";
import { useMemo } from "react";
import { useParams } from "react-router";

const useJobViewParams = () => {
  
  // Selectors
  const companyId = useSelector(CommonState.App.selectCurrentCompanyId);

  const qs = useParams<any>();

  const params = useMemo(() => ({
    projectId: qs.pid,
    stageId: qs.sid,
    taskId: qs.tid,
    quoteRequestId: qs.qid,
    companyId: companyId || '',
    quoteId: qs.quoteId,
    paymentScheduleId: qs.paymentScheduleId,
  }), [qs, companyId]);

  return params;
}
export default useJobViewParams;