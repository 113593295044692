import { CoreState } from "@build-buddy/core";
import { getConfig } from "common";
import { useSelector } from "common/state/store";
import { useMemo, useState } from "react";
import { useRouteMatch } from "react-router";
import { DashboardRouteDefinitions } from "../DashboardRoutes";

interface MatchParams {
  filter: "new-tasks" | "active" | "completed" | "saved-tasks";
}

const ALL_ZONES_RADIUS = 999999;

const useDashboardFilter = () => {
  const COMPANY_ONLY_SKILLS = getConfig().CompanyOnlySkills === "true";
  
  //vars
  const params = useRouteMatch<MatchParams>(DashboardRouteDefinitions.DASHBOARD.path);
  const tabFilter = params?.params.filter || "active";
  const initialRadius = ALL_ZONES_RADIUS;

  //local state
  const [search, setSearch] = useState("");
  const [companyRadius, setCompanyRadius] = useState(initialRadius);
  const [companyOnlySkills, setCompanyOnlySkills] = useState(COMPANY_ONLY_SKILLS);
  const [byCompanyServiceAreas, setByCompanyServiceAreas] = useState(true);

  // selectors
  const userId = useSelector(CoreState.User.selectCurrentUserId);

  // locals
  const statusKvp: any = {
    "new-tasks": "new",
    "active": "active",
    "completed": "completed"
  };

  const status = statusKvp[tabFilter];
  const bookmarked = Boolean(tabFilter === "saved-tasks") ? userId : undefined;

  return useMemo(() => ({
    filter: {
      bookmarked,
      status,
      search,
      companyRadius,
      companyOnlySkills,
      byCompanyServiceAreas,
    },
    setSearch,
    setCompanyRadius,
    setCompanyOnlySkills,
    setByCompanyServiceAreas
  }), [tabFilter, search, companyRadius, companyOnlySkills, userId, byCompanyServiceAreas]);
}

export default useDashboardFilter;