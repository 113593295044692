import { CoreComponents } from "@build-buddy/core";
import { SxProps, Theme } from "@mui/material";

type DashboardJobListItemTemplateProps = {
  children: any;
  sx?: SxProps<Theme>;
  hasLastTypeStyles?: boolean;
  hasHoverStyles?: boolean;
  onClick?(): void;
}
const DashboardJobListItemTemplate = (props: DashboardJobListItemTemplateProps) => {
  return (
    <CoreComponents.GridListTemplate
      sx={{
        gridTemplateAreas: {
          xs: `
            'status status .'
            'task-name task-name action'
            'suburb start-date .'
          `,
          md: `
            'action suburb customer task-name status start-date end-date'
          `
        },
        gridTemplateColumns: {
          xs: `auto 1fr`,
          md: `40px 15% 80px auto 200px 90px 90px`,
        },
        rowGap: 0,
        ...props.sx
      }}      
      hasLastTypeStyles={props.hasLastTypeStyles}
      hasHoverStyles={props.hasHoverStyles}
      onClick={props.onClick}
    >
      {props.children}
    </CoreComponents.GridListTemplate>
  )
}
export default DashboardJobListItemTemplate;