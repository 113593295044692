import { CoreModules, CoreRouting, CoreState } from "@build-buddy/core";
import { useSelector } from "common/state/store";
import { useJobViewParams } from "../../../_hooks";
import JobViewQualityAssuranceRouteDefinitions from "../../JobViewQualityAssuranceRouteDefinitions";
import { useJobViewQualityAssuranceParams } from "../../_hooks";
import { JobViewQualityAssuranceDefectsActions, JobViewQualityAssuranceDefectsDotProgress } from "../_common";

const JobViewQualityAssuranceDefectsView = () => {
  const routing = CoreRouting.useRouting();

  // params
  const { projectId, stageId, taskId } = useJobViewParams();
  const { defectId } = useJobViewQualityAssuranceParams();
  const getTaskListByParentParams = { projectId, stageId, taskId };
  const getDefectItemQueryParams = { projectId, stageId, taskId, defectId };
  const getNoteThreadListByDefectQueryParams = { projectId, stageId, taskId, defectId };

  // queries
  const getTaskListByParentQuery = CoreState.Task.getTaskListByParent.useQuery(getTaskListByParentParams)
  const getDefectItemQuery = CoreState.Defect.getDefectItem.useQuery(getDefectItemQueryParams);
  const getNoteThreadListByDefectQuery = CoreState.NoteThread.getNoteThreadListByDefect.useQuery(getNoteThreadListByDefectQueryParams)

  // selectors
  const task = useSelector((s) => CoreState.Task.selectTaskListByParentCurrentTask(s, getTaskListByParentParams));
  const defect = useSelector((s) => CoreState.Defect.selectDefectItem(s, getDefectItemQueryParams));
  const noteThreads = useSelector((s) => CoreState.NoteThread.selectNoteThreadListByDefectOrderedByLatest(s, getNoteThreadListByDefectQueryParams));

  // locals
  const isLoading = getTaskListByParentQuery.isLoading || getDefectItemQuery.isLoading || getNoteThreadListByDefectQuery.isLoading;

  const handleAdd = () => {
    routing.go(JobViewQualityAssuranceRouteDefinitions.JOB_VIEW_QUALITY_ASSURANCE_DEFECTS_VIEW_NOTE_ADD);
  }

  return (
    <CoreModules.Task.TaskView.TaskViewDefectView
      defect={defect}
      task={task}
      noteThreads={noteThreads}
      isLoading={isLoading}
      actions={JobViewQualityAssuranceDefectsActions}
      dotProgress={JobViewQualityAssuranceDefectsDotProgress}
      onAddClick={handleAdd}
    />
  )
}
export default JobViewQualityAssuranceDefectsView;