
import { Route } from "react-router";
import CompanyProfileTeamsInviteAdd from "./CompanyProfileTeamsInviteAdd";
import CompanyProfileTeamsMemberEdit from "./CompanyProfileTeamsMemberEdit";

const CompanyProfileTeamsRoutesDefinitions = {
  COMPANY_PROFILE_TEAMS_INVITE: {
    path: "/company/:cid/profile/teams/invite",
    component: CompanyProfileTeamsInviteAdd
  },
  COMPANY_PROFILE_TEAMS_MEMBER_EDIT: {
    path: "/company/:cid/profile/teams/:mid/edit",
    component: CompanyProfileTeamsMemberEdit
  }
}

const CompanyProfileTeamsRoutes = () => {
  return (
    <>
      <Route
        path={CompanyProfileTeamsRoutesDefinitions.COMPANY_PROFILE_TEAMS_INVITE.path}
        component={CompanyProfileTeamsRoutesDefinitions.COMPANY_PROFILE_TEAMS_INVITE.component}
      />
      <Route
        path={CompanyProfileTeamsRoutesDefinitions.COMPANY_PROFILE_TEAMS_MEMBER_EDIT.path}
        component={CompanyProfileTeamsRoutesDefinitions.COMPANY_PROFILE_TEAMS_MEMBER_EDIT.component}
      />
    </>
  );
};

export { CompanyProfileTeamsRoutesDefinitions, CompanyProfileTeamsRoutes };
