import {
  CoreComponents,
  CoreRouting,
  CoreState,
  OnboardingActions
} from "@build-buddy/core";
import { Add as AddIcon } from '@mui/icons-material';
import {
  Box,
  Button,
  Card,
  CardContent,
  Grow,
  Typography,
  useTheme
} from "@mui/material";
import { CommonState } from "common";
import { useDispatch, useSelector } from "common/state/store";
import ServiceAreaList from "./ServiceAreaList";
import ServiceAreaRouteDefinitions from "./ServiceAreaRouteDefinitions";

const SetupServiceAreaContent = () => {
  // hooks
  const dispatch = useDispatch();
  const theme = useTheme();
  const routing = CoreRouting.useRouting();

  // selectors
  const companyId = useSelector(CommonState.App.selectCurrentCompanyId);
  const areAllServiceAreasSelected = useSelector((state) => CoreState.Company.selectAreAllServiceAreasSelectedByCompany(state, { companyId }));

  // handlers
  const handleServiceAreaAdd = () => {
    routing.go(ServiceAreaRouteDefinitions.SERVICE_AREA_ADD);
  }

  return (
    <Grow in={true}>
      <Box sx={{
        maxWidth: 600,
        width: '100%',
        margin: "0 auto"
      }}>
        <Card sx={{ mb: 4 }}>
          <CardContent>
            <Typography variant="h1">
              Setup Your Service Area
            </Typography>
            <Box
              sx={{
                mt: 3,
                mb: 3
              }}
            >
              <Typography variant="h4" sx={{ mb: 1 }}>
                Select your Service Area*
              </Typography>
              <Typography variant="body2">
                Select the areas your company can service to ensure you receive relevant quotes.
              </Typography>
              <ServiceAreaList />
              {!areAllServiceAreasSelected && <Button
                variant="none"
                onClick={handleServiceAreaAdd}
                sx={{
                  color: theme.palette.primary.main,
                  justifyContent: "flex-start",
                  borderRadius: 0,
                  ml: 2,
                  my: 1,
                }}
              >
                <AddIcon sx={{ mr: 1.25 }} />
                Add a service area
              </Button>}
              <Typography variant="body2">
                Once you have completed setting up a state, you will be given the opportunity to add more states
              </Typography>
            </Box>
            <CoreComponents.Actions
              isLoading={false}
              submitText="Save"
              onSubmitClick={() => {
                dispatch(OnboardingActions.nextStep());
              }}
            />
          </CardContent>
        </Card>
      </Box>
    </Grow>
  )
}

export default SetupServiceAreaContent;