
import { Route } from "react-router";
import CompanyProfileBrochureEdit from "./CompanyProfileBrochureEdit";

const CompanyProfileBrochureRouteDefinitions = {
  COMPANY_PROFILE_BROCHURE_EDIT: {
    path: "/company/:cid/profile/brochure/edit",
    component: CompanyProfileBrochureEdit
  }
}

const CompanyProfileBrochureRoutes = () => {
  return (
    <>
      <Route
        path={CompanyProfileBrochureRouteDefinitions.COMPANY_PROFILE_BROCHURE_EDIT.path}
        component={CompanyProfileBrochureRouteDefinitions.COMPANY_PROFILE_BROCHURE_EDIT.component}
      />
    </>
  );
};

export { CompanyProfileBrochureRouteDefinitions, CompanyProfileBrochureRoutes };
