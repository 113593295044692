import { useFormikContext } from "formik"
import JobViewQuoteFormValues from "../JobViewQuoteFormValues";
import JobViewQuoteFormTaskListItem from "./JobViewQuoteFormTaskListItem";
import { useEffect, useMemo, useState } from "react";
import { Box, Collapse, useTheme } from "@mui/material";

const JobViewQuoteFormTaskList = () => {
  const theme = useTheme();
  const formik = useFormikContext<JobViewQuoteFormValues>();
  const [editIndex, setEditIndex] = useState<string | undefined>();
  const [open, setOpen] = useState<boolean>(false);

  const first = formik.values.tasks?.[0];
  const tasksLength = formik.values.tasks?.length ?? 0;
  const changedIndex = useMemo(() => formik.values.tasks.findIndex(x => x.changed), [formik.values.tasks]);
  const changedAny = Boolean(changedIndex >= 0)

  const handleChange = (values: any, index: number) => {
    formik.setFieldValue(`tasks.${index}.changed`, true);
    formik.setFieldValue(`tasks.${index}.startDate`, values.startDate);
    formik.setFieldValue(`tasks.${index}.endDate`, values.endDate);
    setEditIndex(undefined);
  }

  const handleRevert = () => {
    const foundIndex = formik.values.tasks.findIndex(t => t.changed);
    if (foundIndex < 0) return;
    formik.setFieldValue(`tasks.${foundIndex}.changed`, false);
    formik.setFieldValue(`tasks.${foundIndex}.startDate`, formik.values.tasks[foundIndex].originalStartDate);
    formik.setFieldValue(`tasks.${foundIndex}.endDate`, formik.values.tasks[foundIndex].originalEndDate);
  }

  useEffect(() => {
    if(editIndex) {
      formik.setFieldError('isEditing', 'Can not submit when editing date.')
    } else {
      formik.setFieldError('isEditing', '')
    }
  }, [editIndex])

  if (!formik.values.tasks || !formik.values.tasks.length) return null;

  return (
    <>
      <JobViewQuoteFormTaskListItem
        name={`tasks.${0}`}
        isEdit={Boolean(first.id === editIndex)}
        isChanged={first.changed}
        isForecast={false}
        isReadonly={false}
        currentItem={0}
        isOpen={open}
        onChange={(values) => handleChange(values, 0)}
        onRevert={() => handleRevert()}
        onCancel={() => setEditIndex(undefined)}
        onEdit={() => setEditIndex(first.id)}
        onOpen={tasksLength > 1 ? () => setOpen(!open) : undefined}
      />
      <Collapse
        in={open}
        sx={{
          mx: -3,
          px: 3
        }}
      >
        <Box sx={{ background: theme.palette.grey[100] }}>
          {formik.values.tasks.map((t, idx) => {
            if (idx === 0) return null;
            return (
              <JobViewQuoteFormTaskListItem
                key={idx}
                name={`tasks.${idx}`}
                isEdit={Boolean(t.id === editIndex)}
                isChanged={formik.values.tasks[idx].changed}
                isForecast={changedAny && changedIndex < idx}
                isReadonly={true}
                currentItem={idx}
                onChange={() => { }}
                onRevert={() => { }}
                onCancel={() => { }}
                onEdit={() => { }}
              />
            )
          })}
        </Box>
      </Collapse>
    </>
  )
}

export default JobViewQuoteFormTaskList;