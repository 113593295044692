import { CoreComponents, CoreState } from "@build-buddy/core";
import { Subset } from "@build-buddy/core/dist/state/common";
import { Dialog } from "@mui/material";
import CompanyFormDialogContent from "./CompanyFormDialogContent";

type CompanyFormDialogProps = {
  formErrors: Record<string, string>;
  title: string;
  isSaving?: boolean;
  isLoading?: boolean;
  company?: CoreState.Company.Company;
  address?: CoreState.Address.Address;
  onClose(): void;
  onSubmit(address: Subset<CoreState.Address.Address>, company: Subset<CoreState.Company.Company>): void;
}
const CompanyFormDialog = (props: CompanyFormDialogProps) => {
  const {
    title,
    formErrors,
    isLoading,
    isSaving,
    company,
    address,
    onClose,
    onSubmit
  } = props;
  return (
    <Dialog
      open={true}
      disablePortal={true}
      onClose={() => onClose()}
    >
      <CoreComponents.DialogHeader
        title={title}
        onClose={() => onClose()}
      />
      <CoreComponents.PreContent
        isLoading={Boolean(isLoading)}
        isEmpty={false}
      >
        <CompanyFormDialogContent
          company={company}
          address={address}
          isSaving={Boolean(isSaving)}
          formErrors={formErrors}
          onSubmit={onSubmit}
          onClose={() => onClose()}
        />
      </CoreComponents.PreContent>
    </Dialog>
  )
}
export default CompanyFormDialog;