
import { CoreComponents, CoreState } from "@build-buddy/core";
import { Dialog, DialogActions, DialogContent } from "@mui/material";
import { useSelector } from "common/state/store";
import { Formik, FormikProps } from "formik";
import { useMemo, useState } from "react";
import { useJobViewParams } from "../../_hooks";
import { selectTaskListByParentIdManagedTasks } from "../../_selectors/selectTaskListByParentId";
import JobViewQuoteFormDetails from "./JobViewQuoteFormDetails";
import JobViewQuoteFormInner from "./JobViewQuoteFormInner";
import JobViewQuoteFormValidation from "./JobViewQuoteFormValidation";
import JobViewQuoteFormValues from "./JobViewQuoteFormValues";

interface JobViewQuoteFormProps {
  quote?: CoreState.Quote.Quote;
  files?: Array<CoreState.File.Doc>;
  title: string;
  saveButtonText: string;
  isOpen: boolean;
  isReadonly?: boolean;
  isSaving?: boolean;
  onClose(): void;
  onSave(values: JobViewQuoteFormValues): void;
}
const JobViewQuoteForm = (props: JobViewQuoteFormProps) => {
  // state
  const [showConfirmationModal, setShowConfirmationModal] = useState(false);

  // params
  const { projectId, stageId, taskId, quoteRequestId, companyId } = useJobViewParams();
  const taskListByParentIdParams = { projectId, stageId, taskId };
  const getQualityAssuranceListByQuoteRequestParams = { quoteRequestId, companyId };

  // queries
  const getTaskListByParentQuery = CoreState.Task.getTaskListByParent.useQuery(taskListByParentIdParams)
  const getQualityAssuranceListByQuoteRequestQuery = CoreState.QualityAssurance.getQualityAssuranceListByQuoteRequest.useQuery(getQualityAssuranceListByQuoteRequestParams);

  // selectors
  const manageTaskList = useSelector((s) => selectTaskListByParentIdManagedTasks(s, taskListByParentIdParams));
  const qaListGrouped = useSelector((s) => CoreState.QualityAssurance.selectQualityAssuranceListByQuoteRequestGrouped(s, getQualityAssuranceListByQuoteRequestParams))

  // locals
  const isLoading = getTaskListByParentQuery.isLoading || getQualityAssuranceListByQuoteRequestQuery.isLoading;
  const stages = Object.keys(qaListGrouped);
  const requiredManageTaskList = manageTaskList.filter(taskList => taskList.isRequired)

  const initialValues = useMemo<JobViewQuoteFormValues>(() => {
    // populate default values
    if (!props.quote) {
      return {
        tasks: requiredManageTaskList.map(t => ({
          id: t.id,
          name: t.name,
          startDate: new Date(t.startDate),
          endDate: new Date(t.endDate),
          changed: false,
          originalStartDate: new Date(t.startDate),
          originalEndDate: new Date(t.endDate),
        })),
        totalCost: 0,
        paymentSchedule: stages ?
          stages.map((name: string) => ({ id: "", name, amount: 0, disabled: true, isImmediate: false })) :
          [{ id: "", name: "", amount: 0, disabled: false, isImmediate: true }],
        files: {
          toSave: [],
          toRemove: []
        },
        specialConditions: "",
        tnc: false
      }
    }

    return {
      tasks: props.quote.manageTasks.map(t => ({
        id: t.taskPublicId,
        name: t.name,
        startDate: new Date(t.startDate),
        endDate: new Date(t.endDate),
        changed: t.changed,
        originalStartDate: new Date(t.originalStartDate),
        originalEndDate: new Date(t.originalEndDate),
      })),
      totalCost: props.quote.cost,
      paymentSchedule: props.quote.paymentSchedule.map(ps => ({
        id: ps.id,
        name: ps.description,
        amount: ps.amount,
        isImmediate: Boolean(ps.paymentType === CoreState.Quote.PaymentScheduleType.ImmediatePayment),
        disabled: stages.includes(ps.description)
      })),
      files: {
        toSave: props.files || [],
        toRemove: []
      },
      specialConditions: props.quote.specialConditions || "",
      tnc: false
    };
  }, [props.quote, manageTaskList, stages])

  // handlers
  const handleSubmit = (formik: FormikProps<JobViewQuoteFormValues>) => {
    if (formik.values.totalCost === 0 && Object.values(formik.errors).length === 0) {
      return setShowConfirmationModal(true);
    }
    setShowConfirmationModal(false);
    return formik.handleSubmit();
  }

  if (isLoading) return null;

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={props.onSave}
      validationSchema={JobViewQuoteFormValidation}
      enableReinitialize={true}
    >
      {(formik) => (
        <Dialog open={props.isOpen}>
          <CoreComponents.DialogHeader
            title={props.title}
            onClose={() => props.onClose()}
          />
          <DialogContent sx={{ pb: 0 }}>
            <CoreComponents.Loader show={Boolean(props.isSaving)} />
            <JobViewQuoteFormDetails />
            <JobViewQuoteFormInner />
            <CoreComponents.Confirmation
              open={showConfirmationModal}
              loading={Boolean(props.isSaving)}
              title="Check payment schedule before proceeding"
              text="You are about to submit a quote with $0 payment. Please confirm if you would like to proceed with this quote."
              cancelText="Cancel"
              confirmText="Submit"
              onCancel={() => setShowConfirmationModal(false)}
              onConfirm={() => formik.handleSubmit()}
              onClose={() => setShowConfirmationModal(false)}
            />
          </DialogContent>
          <DialogActions
            sx={{
              pt: 2,
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'flex-start',
              boxShadow: '1px -2px 12px -4px rgba(0,0,0,0.10)'
            }}
          >
            <CoreComponents.TermsAndConditions
              name="tnc"
              onChange={formik.handleChange}
              error={formik.touched.tnc && formik.errors.tnc}
            />
            <CoreComponents.Actions
              isDisabled={Boolean(formik.errors.isEditing)}
              sx={{ width: '100%' }}
              submitText={props.saveButtonText}
              onSubmitClick={() => handleSubmit(formik)}
            />
          </DialogActions>
        </Dialog>
      )}
    </Formik>
  )
}

export default JobViewQuoteForm;