
import { CoreComponents, CoreRouting, CoreState } from "@build-buddy/core";
import { Box } from "@mui/material";
import { useSelector } from "common/state/store";
import { useCompanyParams } from "modules/company/_hooks";
import { useEffect, useState } from "react";
import { CompanyProfileSkillRoutes, CompanyProfileSkillRoutesDefinitions } from "./CompanyProfileSkillRoutes";

const CompanyProfileSkill = () => {
  // hooks 
  const routing = CoreRouting.useRouting();
  const { companyId } = useCompanyParams();

  // queries
  const getSkillListByCompanyQuery = CoreState.Skill.getSkillListByCompany.useQuery({ companyId });

  // mutations
  const [deleteSkillByCompanyAction, deleteSkillByCompanyQuery] = CoreState.Skill.deleteSkillByCompany.useMutation();

  // locals
  const [isDeleting, setIsDeleting] = useState<Set<string>>(new Set());
  const skills = useSelector((state) => CoreState.Skill.selectSkillListByCompany(state, { companyId }));
  const isLoading = getSkillListByCompanyQuery.isLoading;

  // events
  const handleAdd = () => {
    routing.go(CompanyProfileSkillRoutesDefinitions.COMPANY_PROFILE_SKILL_ADD);
  }

  const handleEdit = (code: string) => {
    const params = { id: code };
    routing.go(CompanyProfileSkillRoutesDefinitions.COMPANY_PROFILE_SKILL_EDIT, { params });
  }

  const handleDelete = (code: string) => {
    if (!companyId || !code) return;

    setIsDeleting(isDeleting.add(code));

    deleteSkillByCompanyAction({ companyId, type: code })
  }

  // effects
  useEffect(() => {
    // we need to clean up the isDeleting array here
    if (!(deleteSkillByCompanyQuery.isSuccess || deleteSkillByCompanyQuery.isError)) return;
    const code = deleteSkillByCompanyQuery.originalArgs?.type ?? "";

    setIsDeleting((prevSet) => {
      prevSet.delete(code);
      return new Set(prevSet);
    });
  }, [deleteSkillByCompanyQuery])

  return (
    <>
      <Box sx={{ mb: 2 }}>
        <CoreComponents.SkillAccordion
          skills={skills}
          isLoading={isLoading}
          isDeleting={isDeleting}
          onAdd={() => handleAdd()}
          onEdit={(skill) => handleEdit(skill.type)}
          onDelete={(skill) => handleDelete(skill.type)}
        />
      </Box>
      <CompanyProfileSkillRoutes />
    </>
  );
};
export default CompanyProfileSkill;