import { CoreComponents, CoreRouting, CoreState } from "@build-buddy/core";
import { useSelector } from "common/state/store";
import { selectJobQuoteRequestOwnerId } from "modules/company/job/job-view/_selectors";
import { useEffect } from "react";
import { useJobViewParams } from "../../../_hooks";
import useJobViewQualityAssuranceParams from "../../_hooks/useJobViewQualityAssuranceParams";
import JobViewQualityAssuranceRouteDefinitions from "../../JobViewQualityAssuranceRouteDefinitions";
import addJobViewQualityAssuranceDefectsNoteItem from "../_actions/addJobViewQualityAssuranceDefectsNoteItem";

const JobViewQualityAssuranceDefectsViewNoteAdd = () => {
  const routing = CoreRouting.useRouting();

  // params
  const { projectId, stageId, taskId, quoteRequestId, companyId } = useJobViewParams();
  const { defectId } = useJobViewQualityAssuranceParams();
  const jobQuoteRequestOwnerIdParams = { projectId, stageId, taskId, quoteRequestId, companyId }

  // mutations
  const [addJobViewQualityAssuranceDefectsNoteItemMutation, addJobViewQualityAssuranceDefectsNoteItemQuery] = addJobViewQualityAssuranceDefectsNoteItem.useMutation()

  // selectors
  const jobQuoteRequestOwnerId = useSelector((s) => selectJobQuoteRequestOwnerId(s, jobQuoteRequestOwnerIdParams));

  // locals
  const isSaving = addJobViewQualityAssuranceDefectsNoteItemQuery.isLoading;
  const isSuccess = addJobViewQualityAssuranceDefectsNoteItemQuery.isSuccess;

  // event handlers
  const handleAdd = (values: {
    noteThread: CoreState.Common.Subset<CoreState.NoteThread.NoteThread>,
    docs: Array<CoreState.File.Doc>
  }) => {
    if(!jobQuoteRequestOwnerId) return;
    
    addJobViewQualityAssuranceDefectsNoteItemMutation({
      projectId,
      stageId,
      taskId,
      defectId,
      noteThread: values.noteThread,
      docs: values.docs,
      toUserId: jobQuoteRequestOwnerId,
    })
  }

  const handleClose = () => {
    routing.go(JobViewQualityAssuranceRouteDefinitions.JOB_VIEW_QUALITY_ASSURANCE_DEFECTS_VIEW);
  }

  useEffect(() => {
    if (!isSuccess) return;
    handleClose();
  }, [isSuccess]);

  return (
    <CoreComponents.AddNoteThread
      isOpen={true}
      isSaving={isSaving}
      isSuccess={isSuccess}
      onAdd={handleAdd}
      onClose={handleClose}
    />
  )
}
export default JobViewQualityAssuranceDefectsViewNoteAdd;