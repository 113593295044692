import { createTheme } from "@mui/material";
import { CoreTheme } from "@build-buddy/core";
import palette from "./palette";

const defaultTheme = CoreTheme.coreTheme;

palette(defaultTheme);
CoreTheme.variables(defaultTheme);

defaultTheme.components = {
  ...defaultTheme.components,
};

export default createTheme(defaultTheme);
