import { Box } from "@mui/material";
import { FormikContextType } from "formik";
import ServiceAreaChecklist from "../ServiceAreaChecklist";
import { ServiceAreaFormikValues } from "./ServiceAreaFormDialogContent";

interface SetupServiceAreaFormDialogInnerContentProps {
  formik: FormikContextType<ServiceAreaFormikValues>;
}

const SetupServiceAreaFormDialogInnerContent = (props: SetupServiceAreaFormDialogInnerContentProps) => {
  const { formik } = props;

  if (!formik || !formik.values.serviceAreas) return null;

  return (
    <Box sx={{ mt: 1 }}>
      <ServiceAreaChecklist
        checklist={formik.values.serviceAreas}
        formik={formik}
      />
    </Box>
  )
};

export default SetupServiceAreaFormDialogInnerContent;