import { ChatRoutes, CoreModules } from "@build-buddy/core";
import { CssBaseline } from "@mui/material";
import { useJsApiLoader } from "@react-google-maps/api";
import { CommonState, RouteDefinitions } from "common";
import { useSelector } from "common/state/store";
import { CompanyRoutes } from "modules/company";
import { InvitationRoutes } from "modules/invitation";
import OnboardingModule from "modules/onboarding/OnboardingModule";
import { FC } from "react";
import { Redirect, Route } from "react-router-dom";
import AppInitialise from "./AppInitialise";
import OtherRoutes from "./OtherRoutes";

// TODO: Refactor
import { LegalRoutes } from "@build-buddy/core/dist/modules/legal";
import { getConfig } from "common";
import { PageTemplate } from "common/components";
import { UserProfileRoutes } from "modules/user/profile";
import WalletModule from "modules/user/wallet/WalletModule";
import { HelpRoutes } from "modules/help/HelpRoutes";
// ENDTODO

const authRoutes = Object.values(RouteDefinitions)
  .map((x) => Object.values(x))
  .flat()
  .map((x) => x.path);

const App: FC = () => {
  const currentCompanyId = useSelector(CommonState.App.selectCurrentCompanyId);
  const companyId = currentCompanyId || "x";
  const redirectUrl = `/company/${companyId}/dashboard/new-tasks`;

  const { isLoaded } = useJsApiLoader({
    googleMapsApiKey: getConfig().googleApiKey,
    libraries: ["places"],
  })

  if (!isLoaded) return null;

  return (
    <>
      <Route
        path="/"
        exact={true}
        component={() =>
          <Redirect to={redirectUrl} />
        }
      />
      <CssBaseline />
      <CoreModules.Auth.AuthRoutes />
      <OtherRoutes />

      <CoreModules.Auth.AuthGuard path={authRoutes}>
        <AppInitialise>
          <PageTemplate>
            <OnboardingModule />
            <ChatRoutes />
            <HelpRoutes />
            <CompanyRoutes />
            <InvitationRoutes />
            {/** FIX LEGACY ROUTES */}
            <UserProfileRoutes />
            <LegalRoutes />
            <WalletModule />
            
          </PageTemplate>
        </AppInitialise>
      </CoreModules.Auth.AuthGuard>
    </>
  );
};

export default App;
