import { CoreComponents, CoreModules } from "@build-buddy/core";
import { CommonState } from "common";
import { useSelector } from "common/state/store";
import { JobRouteDefinitions } from "modules/company/job/JobRoutes";
import { useEffect } from "react";
import { Route } from "react-router-dom";
import { useQueryJob } from "../../_hooks";
import { selectJobQuoteCurrent } from "../../_selectors";
import JobViewPaymentScheduleActions from "./JobViewPaymentScheduleActions";
import JobViewPaymentScheduleDotProgress from "./JobViewPaymentScheduleDotProgress";


const JobViewPaymentSchedule = () => {
  const queryJob = useQueryJob();
  const query = CommonState.Job.getJob.useQuery(queryJob.jobParams)

  // selectors
  const jobQuote = useSelector((s) => selectJobQuoteCurrent(s, queryJob.jobParams));

  // TODO: Remove this after signalR is implemented
  useEffect(() => {
    query.refetch()
  }, []);

  if (!jobQuote?.paymentSchedule) return null;

  return (
    <CoreComponents.PreContent
      isLoading={query.isLoading}
      isEmpty={!jobQuote?.paymentSchedule}
      loader={<CoreModules.Task.TaskView.PaymentScheduleSkeleton />}
    >
      <CoreModules.Task.TaskView.TaskViewPaymentSchedule
        paymentSchedule={jobQuote?.paymentSchedule}
        actions={JobViewPaymentScheduleActions}
        dotProgress={JobViewPaymentScheduleDotProgress}
      />
      <Route 
        component={JobRouteDefinitions.JOB_VIEW_PAYMENT_RECEIPT.component}
        path={JobRouteDefinitions.JOB_VIEW_PAYMENT_RECEIPT.path}
      />
    </CoreComponents.PreContent>
  )
}
export default JobViewPaymentSchedule