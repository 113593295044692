import { CoreState } from "@build-buddy/core";
import JobStatus from "../../models/JobStatus";

const transformJobStatusIsAccepted = (
  task: CoreState.Task.Task,
  taskQuoteRequest: CoreState.Quote.QuoteRequest,
  quotes?: Array<CoreState.Quote.Quote>
) => {
  const quote = quotes?.find(q => [CoreState.Quote.QuoteStatus.AwaitingConfirmation, CoreState.Quote.QuoteStatus.Accepted].includes(q.status))

  if(!quote) return;

  // if its in awaiting confirmation in both the task or quote
  if ((
    task.status === CoreState.Task.TaskStatus.AwaitingConfirmation &&
    quote.status === CoreState.Quote.QuoteStatus.AwaitingConfirmation
  ) ||
  // OR if its still in select & accepted
  (
    task.type === CoreState.Task.TaskType.Select &&
    quote.status === CoreState.Quote.QuoteStatus.Accepted
  )) {
    return JobStatus.AwaitingConfirmation
  }
}
export default transformJobStatusIsAccepted