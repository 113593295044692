import {
  CoreComponents,
  CoreState,
  TestUtils
} from "@build-buddy/core";

import { LocationOn as LocationIcon } from "@mui/icons-material";

import {
  DialogActions,
  DialogContent,
  FormHelperText,
  MenuItem,
  Stack,
  useTheme
} from "@mui/material";

import { CommonState } from "common";
import { useSelector } from "common/state/store";
import { useFormik } from "formik";

import { ServiceAreaType, ServicedStatus } from "@build-buddy/core/dist/state/common/dtos/profile-management.generated";
import { useEffect } from "react";

import ServiceAreaFormResetButton from "../ServiceAreaFormResetButton";
import ServiceAreaSelectLabelContainer from "../ServiceAreaSelectLabelContainer";
import ServiceAreaFormDialogContentSkeleton from "./ServiceAreaFormDialogContentSkeleton";
import ServiceAreaFormValidation from "./ServiceAreaFormValidation";
import SetupServiceAreaFormDialogInnerContent from "./SetupServiceAreaFormDialogInnerContent";

interface ServiceAreaFormDialogContentProps {
  isLoading?: boolean;
  isSaving?: boolean;
  onClose(): void;
  onSubmit(selectedServiceAreas: Array<string>, deletedServiceAreas: Array<string>): void;
  serviceArea?: any;
  states?: Array<string>;
}

type ServiceAreaFormValues = Omit<CoreState.Company.ServiceArea, 'children'> & {
  isChecked?: boolean;
  isIndeterminate?: boolean;
  children?: Array<ServiceAreaFormValues>;
};
export interface ServiceAreaFormikValues {
  serviceAreas?: ServiceAreaFormValues;
  selectedState?: string;
}

const selectAll = (s: ServiceAreaFormValues) => {
  const isChecked = s.status === ServicedStatus.FullySelected;
  const isIndeterminate = s.status === ServicedStatus.PartiallySelected;

  return ({
    id: "selectAll",
    name: "Select All",
    code: "NA",
    type: "NA" as any,
    status: s.status,
    isVisible: true,
    isChecked,
    isIndeterminate,
    children: [s]
  })
}

const mapper = (serviceAreas: CoreState.Company.ServiceArea): ServiceAreaFormValues => {
  const isChecked = serviceAreas.status === ServicedStatus.FullySelected;
  const isIndeterminate = serviceAreas.status === ServicedStatus.PartiallySelected;

  return ({
    id: serviceAreas.id,
    code: serviceAreas.code,
    name: serviceAreas.name,
    type: serviceAreas.type,
    status: serviceAreas.status,
    isVisible: serviceAreas.isVisible,
    isChecked,
    isIndeterminate,
    children: serviceAreas.children?.map(mapper)
  })
};

const loadInitialValues = (serviceAreas?: CoreState.Company.ServiceArea): ServiceAreaFormikValues => {
  if (!serviceAreas) return {};

  return {
    selectedState: serviceAreas.name,
    serviceAreas: TestUtils.objectPathMapper<CoreState.Company.ServiceArea>(mapper(selectAll(serviceAreas)), "serviceAreas"),
  };
};

const ServiceAreaFormDialogContent = (props: ServiceAreaFormDialogContentProps) => {
  const {
    serviceArea,
    isLoading,
    isSaving,
    onSubmit,
    states
  } = props;

  // hooks
  const theme = useTheme();

  // locals
  const isEdit = Boolean(serviceArea);

  // selectors
  const companyId = useSelector(CommonState.App.selectCurrentCompanyId);
  const serviceAreas = useSelector((s) => CoreState.Company.selectServiceAreaByCompany(s, { companyId }));
  const serviceAreaCountry = useSelector((s) => CoreState.Company.selectServiceAreaCountryByCompany(s, { companyId }));

  // helper
  const traverseServiceAreas = (serviceAreas: ServiceAreaFormValues | undefined, callback: (element: ServiceAreaFormValues) => void) => {
    const stack = [serviceAreas];
    while (stack.length) {
      const element = stack.pop();
      if (!element) continue;

      callback(element);
      if (element.children) {
        stack.push(...element.children);
      }
    }
  };

  // formik initialization
  const formik = useFormik<ServiceAreaFormikValues>({
    initialValues: loadInitialValues(serviceArea),
    enableReinitialize: true,
    validationSchema: ServiceAreaFormValidation,
    onSubmit: (values) => {
      const { initialValues } = formik;
      const initialSelectedServiceAreas = new Set();

      traverseServiceAreas(initialValues.serviceAreas, (element: ServiceAreaFormValues) => {
        if (element.type === ServiceAreaType.ServiceArea) {
          initialSelectedServiceAreas.add(element?.id);
        }
      });

      let selectedServiceAreas: Array<string> = [];
      let deletedServiceAreas: Array<string> = [];
      traverseServiceAreas(values.serviceAreas, (child: ServiceAreaFormValues) => {
        if (child.id && initialSelectedServiceAreas.has(child.id)) {
          if (child.isChecked) {
            selectedServiceAreas.push(child.id);
          } else {
            deletedServiceAreas.push(child.id);
          }
        } else if (child.id && initialSelectedServiceAreas.size === 0 && child.isChecked && child.type === ServiceAreaType.ServiceArea) {
          selectedServiceAreas.push(child.id);
        }
      });

      onSubmit(selectedServiceAreas, deletedServiceAreas);
    },
  });

  // side effects
  useEffect(() => {
    if (!serviceAreas || !formik.values.selectedState) return;

    const currentServiceAreas = serviceAreas.children?.find((subArea) => subArea.type === ServiceAreaType.State && subArea.name === formik.values.selectedState);

    if (!currentServiceAreas) return;

    const transformedServiceAreas = TestUtils.objectPathMapper<CoreState.Company.ServiceArea>(selectAll(mapper(currentServiceAreas)), "serviceAreas");

    formik.setFieldValue("serviceAreas", transformedServiceAreas);
  }, [formik.values.selectedState]);

  return (
    <CoreComponents.PreContent
      isLoading={Boolean(isLoading)}
      isEmpty={Boolean(!states?.length)}
      loader={<ServiceAreaFormDialogContentSkeleton />}
    >
      <DialogContent>
        <CoreComponents.Loader show={Boolean(isSaving)} />
        <Stack>
          <Stack
            sx={{
              flexDirection: "row",
              alignItems: "center",
              gap: 0.5,
              mb: 1
            }}
          >
            <LocationIcon
              fontSize="small"
              color="primary"
            />
            {serviceAreaCountry}
          </Stack>
          <CoreComponents.FormikSelect
            formik={formik}
            name="selectedState"
            disabled={isEdit}
            fullWidth
          >
            {states?.map((t, i) => (
              <MenuItem key={i} value={t}> {t} </MenuItem>
            ))}
          </CoreComponents.FormikSelect>
          <Stack
            direction="row"
            justifyContent="space-between"
            alignItems="center"
          >
            <ServiceAreaSelectLabelContainer formik={formik} />
            <ServiceAreaFormResetButton formik={formik} />
          </Stack>
          <SetupServiceAreaFormDialogInnerContent formik={formik} />
        </Stack>
        <FormHelperText sx={{ color: `${theme.palette.error.main}` }}>{formik.errors.serviceAreas}</FormHelperText>
      </DialogContent>
      <DialogActions>
        <CoreComponents.Actions
          submitText={"Save"}
          onSubmitClick={() => formik.handleSubmit()}
        />
      </DialogActions>
    </CoreComponents.PreContent>
  );
};

export default ServiceAreaFormDialogContent;