import { CoreComponents, CoreModules, CoreRouting, CoreState } from "@build-buddy/core";
import { ContactEmergency as BrochureIcon, Edit as EditIcon, MoreVert as MoreIcon } from "@mui/icons-material";
import { useCompanyParams } from "modules/company/_hooks";
import { CompanyProfileBrochureRouteDefinitions, CompanyProfileBrochureRoutes } from "./CompanyProfileBrochureRoutes";
import { Box, Link, Stack, Typography } from "@mui/material";
import {
  AddCircle as AddCircleIcon,
} from "@mui/icons-material";
import { useSelector } from "common/state/store";

const CompanyProfileBrochure = () => {
  const routing = CoreRouting.useRouting();

  // params
  const { companyId } = useCompanyParams();
  const getBrochureByCompanyParams = { companyId };

  // selector
  const companyHasBrochure = useSelector((s) => CoreState.Company.selectIsCompanyHaveBrochure(s, getBrochureByCompanyParams));

  // queries
  const getBrochureByCompanyQuery = CoreState.Company.getBrochureByCompany.useQuery(getBrochureByCompanyParams); 

  // locals
  const isLoading = getBrochureByCompanyQuery.isLoading;
  const brochure = useSelector((s) => CoreState.Company.selectBrochureByCompany(s, getBrochureByCompanyParams))
  const hasBrochure = useSelector((s) => CoreState.Company.selectIsCompanyHaveBrochure(s, getBrochureByCompanyParams))

  const handleEdit = () => {
    routing.go(CompanyProfileBrochureRouteDefinitions.COMPANY_PROFILE_BROCHURE_EDIT)
  }

  return (
    <>
      <Box sx={{ mb: 2 }}>
        <CoreComponents.Accordion
          icon={BrochureIcon}
          title="Business Profile"
          description="Your business profile"
          actions={
            hasBrochure &&
            <CoreComponents.MoreMenu
              icon={MoreIcon}
            >
              <CoreComponents.MoreMenuItem
                onClick={() => handleEdit()}
                icon={EditIcon}
                text="Edit"
              />
            </CoreComponents.MoreMenu>
          }
        >
          {!hasBrochure && (
            <Stack direction="row" sx={{ mb: 2 }}>
              <Typography variant="caption" sx={{ flex: 1 }}>
                No Brochure
              </Typography>
              <Link
                sx={{
                  display: "flex",
                  alignItems: "center",
                  textDecoration: "none",
                  cursor: "pointer",
                }}
                onClick={handleEdit}
              >
                <AddCircleIcon fontSize="small" sx={{ mr: 0.5 }} />
                Add Brochure
              </Link>
            </Stack>
          )}
          <CoreComponents.PreContent
            isLoading={isLoading}
            isEmpty={!hasBrochure}
            empty={<CoreComponents.Empty
              isProfile={true}
              title="No Brochure Found"
              description="Add your brochure here"
              sx={{ my: 4 }}
              onClick={handleEdit}
            />}
          >
            <span dangerouslySetInnerHTML={{ __html: brochure?.content || "" }} />
            <Box
              sx={{
                py: 1
              }}
            >
              <CoreModules.Company.CompanyProfileBrochure
                companyId={companyId}
                documents={brochure?.documents}
              />
            </Box>
          </CoreComponents.PreContent>
        </CoreComponents.Accordion>
      </Box>
      <CompanyProfileBrochureRoutes />
    </>
  )
}
export default CompanyProfileBrochure;