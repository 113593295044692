import { CoreComponents, CoreRouting, CoreState } from "@build-buddy/core";
import { Dialog } from "@mui/material";
import { useSelector } from "common/state/store";
import { useCompanyParams } from "modules/company/_hooks";
import { CompanyProfileRouteDefintions } from "../CompanyProfileRoutes";
import CompanyProfileBrochureEditForm from "./CompanyProfileBrochureEditForm";

const CompanyProfileBrochureEdit = () => {
  const routing = CoreRouting.useRouting();

  // params
  const { companyId } = useCompanyParams();
  const getBrochureByCompanyParams = { companyId };
  
  // selector
  const companyHasBrochure = useSelector((s) => CoreState.Company.selectIsCompanyHaveBrochure(s, getBrochureByCompanyParams));

  // queries
  const getBrochureByCompanyQuery = CoreState.Company.getBrochureByCompany.useQuery(getBrochureByCompanyParams);

  // locals
  const isLoading = getBrochureByCompanyQuery.isLoading;
  const brochure = useSelector((s) => CoreState.Company.selectBrochureByCompany(s, getBrochureByCompanyParams))

  const handleClose = () => {
    routing.go(CompanyProfileRouteDefintions.COMPANY_PROFILE);
  }

  return (
    <Dialog open={true} onClose={() => handleClose()}>
      <CoreComponents.DialogHeader onClose={() => handleClose()} title="Edit Business Profile" />
      <CoreComponents.PreContent
        isLoading={isLoading}
        isEmpty={false}
      >
        <CompanyProfileBrochureEditForm 
          brochure={brochure}
        />
      </CoreComponents.PreContent>
    </Dialog>
  )
}
export default CompanyProfileBrochureEdit;