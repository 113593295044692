import { Typography } from "@mui/material";
import DashboardJobListItemTemplate from "./DashboardBrowseJobListTemplate";

const DashboardBrowseJobListHeader = () => {
  return (
    <>
      <DashboardJobListItemTemplate
        sx={{
          pt: 0,
          display: {
            xs: "block",
            md: "grid"
          }
        }}
      >
        <Typography
          sx={{
            gridArea: "action",
            display: {
              xs: "none",
              md: "block"
            }
          }}
          variant='subtitle2'
        />
        <Typography
          sx={{
            gridArea: "suburb",
            display: {
              xs: "none",
              md: "block"
            }
          }}
          variant='subtitle2'
        >
          Suburb/State
        </Typography>
        <Typography
          sx={{
            gridArea: "customer",
            display: {
              xs: "none",
              md: "block"
            }
          }}
          variant='subtitle2'
        >
          Customer
        </Typography>
        <Typography
          sx={{
            gridArea: "task-name"
          }}
          variant='subtitle2'
        >
          Task Name
        </Typography>
        <Typography
          sx={{
            gridArea: "status",
            display: {
              xs: "none",
              md: "block"
            }
          }}
          variant='subtitle2'
        >
          Status
        </Typography>
        <Typography
          sx={{
            gridArea: "start-date",
            display: {
              xs: "none",
              md: "block"
            }
          }}
          variant='subtitle2'
        >
          Proposed Start Date
        </Typography>
        <Typography
          sx={{
            gridArea: "end-date",
            display: {
              xs: "none",
              md: "block"
            }
          }}
          variant='subtitle2'
        >
          Est Completion
        </Typography>
      </DashboardJobListItemTemplate>
    </>
  )
}
export default DashboardBrowseJobListHeader;

