import { CoreRouting, CoreState, CoreUtils } from "@build-buddy/core";
import { useEffect, useState } from "react";
import { CompanyFormDialog } from "../_components";
import { CompanyProfileRouteDefintions } from "../company-profile/CompanyProfileRoutes";

const CompanyCreate = () => {
  const routing = CoreRouting.useRouting();

  // mutations
  const [addCompanyAction, addCompanyQuery] = CoreState.Company.addCompany.useMutation();
  const [addAddressByCompanyAction, addAddressByCompanyQuery] = CoreState.Address.addAddressByCompany.useMutation();

  // locals
  const [errors, setErrors] = useState<any>();
  const isSaving = Boolean(addCompanyQuery.isLoading && !addCompanyQuery.isUninitialized);
  const isSuccess = Boolean(addCompanyQuery.isSuccess && !addCompanyQuery.isUninitialized);
  const addCompanyQueryError = CoreUtils.ServiceResponseHelper.getServiceError(addCompanyQuery);

  // handlers
  const handleSubmit = async (
    address: CoreState.Common.Subset<CoreState.Address.Address>, 
    company: CoreState.Common.Subset<CoreState.Company.Company>
  ) => {
    const resp = await addCompanyAction({ company });
    if (!resp.data || !resp.data.result.id) return;
    const companyId = resp.data.result.id;
    await addAddressByCompanyAction({
      address,
      companyId
    })
    if (companyId) routing.go(CompanyProfileRouteDefintions.COMPANY_PROFILE, { params: { cid: companyId } });
  }

  const handleClose = () => routing.back();
  
  useEffect(() => {
    const errors = CoreUtils.Converter.toFormikErrors([addCompanyQueryError]);
    setErrors(errors);
  }, [addCompanyQueryError])

  return (
    <CompanyFormDialog
      title="Create Company"
      formErrors={errors}
      isSaving={isSaving}
      onClose={handleClose}
      onSubmit={handleSubmit}
    />
  )
}

export default CompanyCreate;