import JobViewVariationAdd from "./JobViewVariationAdd";
import JobViewVariationEdit from "./JobViewVariationEdit";
import JobViewVariations from "./JobViewVariations";

const JobViewVariationsRouteDefinitions = {
  JOB_VIEW_VARIATIONS: {
    label: "Variations",
    path: "/*/job/:pid/stage/:sid/task/:tid/quote/:qid/variations",
    component: JobViewVariations
  },
  JOB_VIEW_VARIATIONS_ADD: {
    label: "Defects and Variations",
    path: "/*/job/:pid/stage/:sid/task/:tid/quote/:qid/variations/add",
    component: JobViewVariationAdd
  },
  JOB_VIEW_VARIATIONS_EDIT: {
    label: "Defects and Variations",
    path: "/*/job/:pid/stage/:sid/task/:tid/quote/:qid/variations/:variationId/edit",
    component: JobViewVariationEdit
  },
}

export default JobViewVariationsRouteDefinitions;