import { CoreComponents, CoreRouting, CoreState, CoreUtils } from "@build-buddy/core";
import { Bookmark as BookmarkIcon, CalendarToday as CalendarIcon, LocationOn as LocationIcon } from "@mui/icons-material";
import { Avatar, Box, IconButton, Typography, useTheme } from "@mui/material";
import { CommonComponents, CommonState } from "common";
import { useSelector } from "common/state/store";
import { JobRouteDefinitions } from "modules/company/job/JobRoutes";
import DashboardJobListItemTemplate from "./DashboardBrowseJobListTemplate";

type DashboardJobListItemProps = {
  job: CommonState.Job.Job
}
const DashboardJobListItem = (props: DashboardJobListItemProps) => {
  const theme = useTheme();
  const routing = CoreRouting.useRouting();

  const dpText = props.job.task?.bbProDisplayText ?? props.job.task.name;
  const displayText = props.job.status === CommonState.Job.JobStatus.Completed ? `[Completed] ${dpText}` : dpText;
  const jobAddress = props.job.quoteRequest.address;
  const jobSuburbAndState = `${jobAddress?.suburb}, ${jobAddress?.state}`

  //Remove legacy selectors
  const user = useSelector(CoreState.User.selectCurrentUser);

  const [bookMarkAction, updateBookMarkQuery] = CoreState.Task.updateTask.useMutation();
  const isBookmarkedForCurrentUser = props.job.task.extra?.bookmarks?.includes(user?.id ?? "");

  const params = {
    projectId: props.job.quoteRequest.projectId,
    stageId: props.job.task.stageId,
    taskId: props.job.task.parentId,
  }

  const handleClick = () => {
    const params = {
      pid: props.job.quoteRequest.projectId,
      sid: props.job.task.stageId,
      tid: props.job.task.parentId,
      qid: props.job.quoteRequest.id
    };
    routing.go(JobRouteDefinitions.JOB_VIEW, { params })
  }

  const handleBookMarkAdd = () => {
    if (user?.id) {
      const bookmarks = props.job.task.extra?.bookmarks ? [...props.job.task.extra.bookmarks, user.id] : [user.id]

      bookMarkAction({
        ...params,
        task: {
          extra: {
            bookmarks
          }
        }
      })
    }
  }

  const handleBookMarkRemove = () => {
    if (user?.id) {
      bookMarkAction({
        ...params,
        task: {
          extra: {
            bookmarks: props.job.task.extra?.bookmarks?.filter((userId) => userId !== user?.id) ?? []
          }
        }
      })
    }
  }

  return (
    <DashboardJobListItemTemplate
      hasHoverStyles={true}
      hasLastTypeStyles={true}
      onClick={() => handleClick()}
    >
      <CoreComponents.Loader show={updateBookMarkQuery.isLoading} fullscreen={false} />
      <Box sx={{ gridArea: "action" }} onClick={(e) => {
        e.stopPropagation();
        isBookmarkedForCurrentUser ? handleBookMarkRemove() : handleBookMarkAdd();
      }}>
        <IconButton>
          <BookmarkIcon sx={{ fontSize: "24px" }} color={isBookmarkedForCurrentUser ? "success" : "inherit"} />
        </IconButton>
      </Box>
      <Box
        sx={{
          gridArea: "suburb",
          minWidth: 0,
          display: {
            xs: "inline-flex",
            md: "auto"
          },
          fontSize: {
            xs: "13px",
            md: "inherit"
          },
          color: {
            xs: theme.palette.grey[500],
            md: "inherit"
          }
        }}
      >
        <LocationIcon
          sx={{
            display: {
              xs: "block",
              md: "none"
            },
            fontSize: "16px",
            position: "relative",
            top: 2,
            mr: 0.5
          }}
        />
        <CoreComponents.Truncate
          text={jobSuburbAndState}
          lines={1}
          sx={{
            mr: 1
          }}
        />
      </Box>
      <Box
        sx={{
          gridArea: "customer",
          display: {
            xs: "none",
            md: "block"
          }
        }}
      >
        <Avatar
          sx={{
            bgcolor: `${theme.palette.primary.main}`,
            color: `${theme.palette.primary.contrastText}`
          }}
        >
          {CoreUtils.Formatter.getInitials(props.job.quoteRequest.owner)}
        </Avatar>
      </Box>
      <Box sx={{ gridArea: "task-name", minWidth: 0 }}>
        <CoreComponents.Truncate
          text={displayText}
          lines={1}
          sx={{ mr: 3 }}
        />
      </Box>
      <Box
        sx={{
          gridArea: "status",
          position: "relative",
          right: {
            xs: 2,
            md: 0
          },
          mr: {
            xs: 0,
            md: 1.5
          }
        }}
      >
        <CommonComponents.JobStatus status={props.job.status} />
      </Box>
      <Typography
        sx={{
          gridArea: "start-date",
          fontSize: {
            xs: "13px",
            md: "inherit"
          },
          color: {
            xs: theme.palette.grey[500],
            md: "inherit"
          }
        }}
        variant='body1'
      >
        <CalendarIcon
          sx={{
            display: {
              xs: "inline-block",
              md: "none"
            },
            fontSize: "16px",
            position: "relative",
            top: 2,
            mr: 0.5
          }}
        />
        {CoreUtils.Formatter.date(props.job.task.startDate, "dd MMM yy")}
      </Typography>
      <Typography
        sx={{
          gridArea: "end-date",
          display: {
            xs: "none",
            md: "block"
          }
        }}
        variant='body1'
      >
        {CoreUtils.Formatter.date(props.job.task.endDate, "dd MMM yy")}
      </Typography>
    </DashboardJobListItemTemplate>
  )
}
export default DashboardJobListItem;