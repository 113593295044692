import { CoreModules, CoreUtils } from "@build-buddy/core";
import { CalendarToday as CalendarIcon } from "@mui/icons-material";
import { Stack } from "@mui/material";
import { useSelector } from "common/state/store";
import { useQueryJob } from "../_hooks";
import { selectJobQuoteRequest, selectJobTask } from "../_selectors";

const useVm = () => {
  // queries
  const queryJob = useQueryJob();

  // selectors
  const jobTask = useSelector((s) => selectJobTask(s, queryJob.jobParams));
  const jobQuoteRequest = useSelector((s) => selectJobQuoteRequest(s, queryJob.jobParams));

  return {
    task: jobTask,
    quoteRequest: jobQuoteRequest
  }
}

const JobViewContentDetailsStartEndDate = () => {
  const vm = useVm();

  if (!vm.task || !vm.quoteRequest) return null;

  return (
    <Stack 
      direction="row"
      sx={{ 
        mb: {
          xs: 2,
          md: 0
        }
      }}
    >
      <CoreModules.Task.TaskView.TaskViewOutlineItem
        title="Start"
        value={CoreUtils.Formatter.date(vm.task.startDate, "d MMM yy")}
        icon={CalendarIcon}
      />
      <CoreModules.Task.TaskView.TaskViewOutlineItem
        title="Est completion"
        value={CoreUtils.Formatter.date(vm.task.endDate, "d MMM yy")}
      />
    </Stack>
  )
}
export default JobViewContentDetailsStartEndDate;