import { CoreState } from "@build-buddy/core";
import { createSelector } from "@reduxjs/toolkit";

type SelectTaskListByParentIdParams = {
  projectId: string;
  stageId: string;
  taskId: string;
}

const selectRoot = createSelector(
  [(state: CoreState.RootState, params: SelectTaskListByParentIdParams) => CoreState.Task.getTaskListByParent.select(params)(state)],
  (taskListQuery) => taskListQuery
)

const selectTaskListByParentId = createSelector(
  selectRoot,
  (taskListQuery) => taskListQuery.data
)

const selectTaskListByParentIdManagedTasks = createSelector(
  selectTaskListByParentId,
  (taskList) => {
    if(!taskList) return [];
    return taskList.filter(x => x.type.includes(CoreState.Task.TaskType.Manage));    
  }
)
export {
    selectTaskListByParentId,
    selectTaskListByParentIdManagedTasks
};
