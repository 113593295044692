import { CoreComponents, CoreState } from "@build-buddy/core";
import { useSelector } from "common/state/store";
import { useFormikContext } from "formik";
import { useJobViewParams } from "../../_hooks";
import { selectJob } from "../../_selectors";
import JobViewQuoteFormValues from "./JobViewQuoteFormValues";
import JobViewQuoteFormPaymentSchedule from "./job-view-quote-form-payment-schedule/JobViewQuoteFormPaymentSchedule";
import JobViewQuoteFormSpecialConditions from "./job-view-quote-form-special-conditions/JobViewQuoteFormSpecialConditions";
import JobViewQuoteFormTaskList from "./job-view-quote-form-task-list/JobViewQuoteFormTaskList";

const JobViewQuoteFormInner = () => {
  const { download } = CoreState.File.useDownloadQueue();
  const formik = useFormikContext<JobViewQuoteFormValues>();

  // params
  const { projectId, stageId, taskId, quoteRequestId, companyId } = useJobViewParams();
  const jobParams = { projectId, stageId, taskId, quoteRequestId, companyId };

  // selectors
  const job = useSelector((s) => selectJob(s, jobParams));

  const handleRetrieve = (file: any) => {
    download(file);
  };

  return (
    <>
      <JobViewQuoteFormTaskList />
      <JobViewQuoteFormPaymentSchedule />
      <CoreComponents.FormikUploadFile
        formik={formik}
        label="Upload supporting documents"
        name="files"
        maxFiles={5}
        handleDownload={handleRetrieve}
        projectNumber={job?.quoteRequest.projectNumber}
      />
      <JobViewQuoteFormSpecialConditions />
    </>
  )
}

export default JobViewQuoteFormInner;