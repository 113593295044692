import { CoreComponents, CoreModules, CoreState, CoreUtils } from "@build-buddy/core";
import { Box, Typography } from "@mui/material";
import JobViewQuoteListItemActions from "./JobViewQuoteListItemActions";
import JobViewQuotesListTemplate from "./JobViewQuoteListTemplate";

type JobViewQuoteListItemProps = {
  quote: CoreState.Quote.Quote
}
const JobViewQuoteListItem = (props: JobViewQuoteListItemProps) => {
  const { quote } = props;
  return (
    <JobViewQuotesListTemplate
      sx={{
        py: 2,
        px: 3,
      }}
    >
      <CoreComponents.QuoteListCompany
        company={quote.company}
        onClick={() => { }}
        sx={{
          gridArea: "company",
          alignItems: {
            xs: "center"
          }
        }}
      />
      <Typography variant="caption" sx={{ gridArea: "date", ml: 0.25 }}>
        {CoreUtils.Formatter.date(quote.createdDate, "dd MMM yy")}
      </Typography>
      <CoreComponents.QuoteListCost
        cost={quote.cost}
        sx={{ gridArea: "quote" }}
        TypographyProps={{
          sx: {
            fontSize: { xs: "inherit" },
            fontWeight: { xs: "normal" }
          }
        }}
      >
        <Box sx={{ p: 2 }}>
          <CoreModules.Task.TaskView.TaskViewTimelinePaymentSchedule
            paymentSchedule={quote.paymentSchedule}
          />
        </Box>
      </CoreComponents.QuoteListCost>
      <Box sx={{ gridArea: 'actions' }}>
        <JobViewQuoteListItemActions quote={quote} />
      </Box>

    </JobViewQuotesListTemplate>
  )
}
export default JobViewQuoteListItem;