import { CoreModules, CoreState } from "@build-buddy/core";
import { useDispatch, useSelector } from "common/state/store";
import { FormikContextType, getIn } from "formik";
import OnboardingRoutesDefinitions from "modules/onboarding/OnboardingRouteDefinitions";
import { useEffect, useState } from "react";
import InitialDetailsCompanyCreate from "./InitialDetailsCompanyCreate";
import InitialDetailsCompanyInvite from "./InitialDetailsCompanyInvite";
import InitialDetailsCompanySuccess from "./InitialDetailsCompanySuccess";

type InitialDetailsCompanyProps = {
  formik: FormikContextType<any>;
  isLoading: boolean;
}
const InitialDetailsCompany = (props: InitialDetailsCompanyProps) => {
  const { formik, isLoading } = props;
  const dispatch = useDispatch();

  // params
  const userId = useSelector(CoreState.User.selectCurrentUserId);
  const getInviteListByUserParams = { userId };
  const getCompanyListByUserParams = { userId };

  // queries
  const getInviteListByUserQuery = CoreState.Membership.getInviteListByUser.useQuery(getInviteListByUserParams);
  const getCompanyListByUserQuery = CoreState.Company.getCompanyListByUser.useQuery(getCompanyListByUserParams);

  // locals
  const [showCreate, setShowCreate] = useState(false);
  const invites = useSelector((s) => CoreState.Membership.selectInviteListByUser(s, getInviteListByUserParams));
  const companies = useSelector((s) => CoreState.Company.selectCompanyListByUser(s, getCompanyListByUserParams));
  const company = companies[0];
  const error = getIn(formik.touched, "company.name") && getIn(formik.errors, "company.name");
  const isRefetching = Boolean(getInviteListByUserQuery.isFetching || getCompanyListByUserQuery.isFetching);

  const handleCreate = () => {
    setShowCreate(true);
    addSteps();
  }

  const addSteps = () => {
    const steps = [
      OnboardingRoutesDefinitions.AWAITING_VERIFICATION,
      OnboardingRoutesDefinitions.INITIAL_DETAILS,
      OnboardingRoutesDefinitions.MORE_COMPANY_DETAILS,
      OnboardingRoutesDefinitions.SERVICE_AREA_SETUP,
      OnboardingRoutesDefinitions.ONBOARDING_COMPLETE,
    ].map(s => ({ name: s.name, path: s.path }));

    dispatch(CoreModules.Onboarding.setSteps(steps));
  }

  useEffect(() => {
    // if its refetching dont bother checking
    if(isRefetching) return;

    // if there are any invites or the user has a company, then we dont need to add steps
    if(Boolean(invites && invites.length) || Boolean(company)) return;

    addSteps();
  }, [isRefetching])


  if (company) {
    return (
      <InitialDetailsCompanySuccess
        company={company}
      />
    )
  }

  if (invites.length && !showCreate) {
    return (
      <InitialDetailsCompanyInvite
        error={error}
        onCreateCompany={() => handleCreate()}
      />
    )
  }

  return (
    <InitialDetailsCompanyCreate
      formik={formik}
      isLoading={isLoading}
    />
  )
}
export default InitialDetailsCompany;