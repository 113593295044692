import { CoreState } from "@build-buddy/core";
import { useDispatch } from "common/state/store";
import { useEffect, useMemo } from "react";
import useJobViewParams from "./useJobViewParams";

const useQueryJobSpecs = () => {
  const { quoteRequestId, companyId } = useJobViewParams();
  const dispatch = useDispatch();

  const quoteRequestSpecificationListParams = useMemo(() => ({
    quoteRequestId,
    companyId
  }), [quoteRequestId, companyId]);

  useEffect(() => {
    dispatch(CoreState.Specification.getQuoteRequestSpecificationList.initiate(quoteRequestSpecificationListParams))
  }, [quoteRequestSpecificationListParams])

  return {
    quoteRequestSpecificationListParams
  }
}
export default useQueryJobSpecs;