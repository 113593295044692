import * as Yup from "yup";

const contactNumberRegex = /^((\+61)\d{10}|(\+61)\d{9})$/;
const websiteRegex = /^((https):\/\/)?(www\.)?(?!.*(https|www\.))[a-zA-Z0-9_-]+(\.[a-zA-Z]+)+((\/)[\w#]+)*(\/\w+\?[a-zA-Z0-9_]+=\w+(&[a-zA-Z0-9_]+=\w+)*)?\/?$/gm
const publicContactNumberRegex = /^((\+61)\d{10}|(\+61)\d{9})$/;
const abnRegex = /^(\d *?){11}$/

const CompanyFormDialogContentValidation = Yup.object().shape({
  name: Yup.mixed().required("Enter your first name"),
  address: Yup.object().shape({
    search: Yup.mixed().required("Please enter an address"),
  }),
  type: Yup.mixed().required("Please select a business type"),
  abn: Yup
    .string()
    .required("Please enter an ABN")
    .matches(abnRegex, "Please enter a valid ABN"),
  gstStatus: Yup.mixed().required("Please select your GST status"),
  contactNumber: Yup
    .string()
    .matches(contactNumberRegex, "Please enter mobile number in format of : 412345678")
    .required("Enter mobile number"),
  email: Yup.string().required("Please enter an email").email("Please enter a valid email"),
  website: Yup.string().matches(websiteRegex, "Invalid url format"),
  publicPhone: Yup
    .string()
    .test("public phone check", "Please enter a valid number", (value: string | undefined, context: any) => {
      if(!value || value === "+61") return true;
      return publicContactNumberRegex.test(value);
    }),
  publicEmail: Yup
    .string()
    .email("Please enter a valid email"),
  isPersonalPhone: Yup
    .mixed()
    .test("contact mobile check", "You have entered the same number from your user profile. To continue you must select the checkbox above", (value: boolean, context: any) => {
      if (value || context.parent.isPersonalPhone) return true;
      if (!Boolean(context.parent.contactNumber)) return true;
      return (context.parent.contactNumber !== context.parent.current.mobile);
    }),
  isPersonalEmail: Yup
    .mixed()
    .test("contact email check", "You have entered the same email from your user profile. To continue you must select the checkbox above", (value: boolean, context: any) => {
      if (value || context.parent.isPersonalEmail) return true;
      if (!Boolean(context.parent.email)) return true;
      return (context.parent.email !== context.parent.current.email);
    }),
});

export default CompanyFormDialogContentValidation;