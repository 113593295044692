import { CoreComponents, CoreState } from "@build-buddy/core";
import { Button } from "@mui/material";
import { useSelector } from "common/state/store";
import { useJobViewParams, useQueryJob } from "../../_hooks";
import { selectJobTask } from "../../_selectors";

const useVm = () => {
  const { projectId } = useJobViewParams();

  // queries
  const queryJob = useQueryJob();

  // selectors
  const jobTask = useSelector((s) => selectJobTask(s, queryJob.jobParams));

  // mutatations
  const [reviewTaskAction, reviewTaskQuery] = CoreState.Task.reviewTask.useMutation();

  const reviewJob = () => {
    if(!jobTask) return;
    reviewTaskAction({
      projectId: projectId,
      taskId: jobTask.parentId,
      subTaskId: jobTask.id
    })
  }

  return {
    isSaving: reviewTaskQuery.isLoading,
    reviewJob
  }
}

const JobViewActionsReview = () => {
  const vm = useVm();
  return (
    <>
      <CoreComponents.Loader show={vm.isSaving} />
      <Button
        variant="contained"
        color="primary"
        onClick={() => vm.reviewJob()}
        sx={{
          flex: {
            xs: 1,
            md: "inherit"
          }
        }}
      >
        Request Review
      </Button>
    </>
  )
}

export default JobViewActionsReview;