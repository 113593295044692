import {
  CoreComponents,
  CoreModules,
  CoreRouting,
  CoreState
} from '@build-buddy/core';
import { Dialog } from '@mui/material';
import { CommonState } from 'common';
import { selectIncompleteProfileItems, selectIsCompanyHaveWallet } from 'common/state/app';
import { useSelector } from 'common/state/store';
import { CompanyProfileRouteDefintions } from 'modules/company/company-profile/CompanyProfileRoutes';
import { CompanyProfileBrochureRouteDefinitions } from 'modules/company/company-profile/company-profile-brochure/CompanyProfileBrochureRoutes';

type JobViewActionsQuoteValidationProps = {
  show: boolean;
  onClose: () => void;
}

const JobViewActionsQuoteValidation = (props: JobViewActionsQuoteValidationProps) => {
  const routing = CoreRouting.useRouting()

  // Selector
  const currentCompanyId = useSelector(CommonState.App.selectCurrentCompanyId);
  const currentUserId = useSelector(CoreState.User.selectCurrentUserId);
  const isCurrentuserOwner = useSelector(CommonState.App.selectIsCurrentUserOwner)
  const validations = useSelector((s) => selectIncompleteProfileItems(s, { companyId: currentCompanyId, userId: currentUserId }));
  const hasCompanyWallet = useSelector((s) => selectIsCompanyHaveWallet(s, { userId: currentUserId, companyId: currentCompanyId }));

  if (!props.show) return null

  if (!hasCompanyWallet && !isCurrentuserOwner) {
    return (
      <CoreModules.Wallet.WalletValidationDialog
        show={props.show}
        onClose={() => props.onClose()}
        onConfirm={() => props.onClose()}
        title="Setup Wallet To Request Quote."
        content="You can not request quote if you don&apos;t have Wallet. Please contact owner to setup Wallet and Bank Account so you can continue with the task."
        actionLabel='Got it.'
      />
    )
  }

  if (!validations.hasAbn) {
    return (
      <Dialog open={true} sx={{ maxWidth: 300, mx: 'auto' }} onClose={() => props.onClose()}>
        <CoreComponents.PopupBar
          title="Complete Company Details"
          description="Please complete your company details to request quote."
          icon={<CoreComponents.CompanyProfileIcon />}
          actionLabel='Complete Details'
          handleActionClick={() => routing.go(CompanyProfileRouteDefintions.COMPANY_PROFILE_EDIT, { params: { cid: currentCompanyId } })}
          show={true}
        />
      </Dialog>
    )
  }

  if (!validations.hasBrochure) {
    return (
      <Dialog open={true} sx={{ maxWidth: 300, mx: 'auto' }} onClose={() => props.onClose()}>
        <CoreComponents.PopupBar
          title="Add Your Brochure"
          description="Please add your company brochure to request quote."
          icon={<CoreComponents.CompanyProfileIcon />}
          actionLabel='Add Brochure'
          handleActionClick={() => routing.go(CompanyProfileBrochureRouteDefinitions.COMPANY_PROFILE_BROCHURE_EDIT, { params: { cid: currentCompanyId } })}
          show={true}
        />
      </Dialog>
    )
  }

  return null
}

export default JobViewActionsQuoteValidation