import { CoreComponents, CoreRouting, CoreState } from "@build-buddy/core";
import { DownloadIcon } from '@build-buddy/core/dist/components';
import { Cancel as CancelIcon, Payment as PaymentIcon } from "@mui/icons-material";
import { IconButton } from "@mui/material";
import { JobRouteDefinitions } from "modules/company/job/JobRoutes";
import { useJobViewParams } from "../../_hooks";


type JobViewPaymentScheduleActionsProps = {
  paymentSchedule: CoreState.Quote.PaymentSchedule;
}
const JobViewPaymentScheduleActions = (props: JobViewPaymentScheduleActionsProps) => {
  const routing = CoreRouting.useRouting();
  const { projectId, taskId, quoteRequestId } = useJobViewParams();

  // Mutations
  const [requestPaymentScheduleAction, requestPaymentScheduleQuery] = CoreState.Quote.requestPaymentShedule.useMutation()
  const [updatePaymentScheduleMutation, updatePaymentScheduleQuery] = CoreState.Quote.updatePaymentSchedule.useMutation()

  const requestPayment = (paymentSchedule: CoreState.Quote.PaymentSchedule) => {
    requestPaymentScheduleAction({
      projectId: projectId,
      taskId: taskId,
      paymentScheduleId: paymentSchedule.id,
    })
  }

  const updatePaymentSchedule = (
    { paymentScheduleId, actionType, status }:
      { paymentScheduleId: string; actionType: string | null; status: string; }) => {
    updatePaymentScheduleMutation({
      projectId,
      paymentScheduleId,
      actionType,
      status,
      taskId,
    })
  }

  const handleDownloadButtonClick = () => {
    const params = {
      pid: projectId,
      tid: taskId,
      qid: quoteRequestId,
      paymentScheduleId: props.paymentSchedule.id,
    }
    routing.go(JobRouteDefinitions.JOB_VIEW_PAYMENT_RECEIPT, {
      params
    })
  }
  const kvp = {
    [CoreState.Quote.PaymentScheduleStatus.NA]:
      <CoreComponents.TableButton
        buttons={[
          {
            variant: 'contained',
            icon: PaymentIcon,
            onClick: () => requestPayment(props.paymentSchedule),
            label: 'Request Payment'
          }
        ]}
      />,
    [CoreState.Quote.PaymentScheduleStatus.Pending]: <></>,
    [CoreState.Quote.PaymentScheduleStatus.Requested]:
      <CoreComponents.TableButton
        buttons={[
          {
            variant: 'outlined',
            icon: CancelIcon,
            onClick: () => updatePaymentSchedule({
              paymentScheduleId: props.paymentSchedule.id,
              actionType: 'Cancel',
              status: CoreState.Quote.PaymentScheduleStatus.Cancelled
            }),
            label: 'Cancel'
          }
        ]}
      />,
    [CoreState.Quote.PaymentScheduleStatus.AuthorizationRequired]: <></>,
    [CoreState.Quote.PaymentScheduleStatus.Allocated]:
      <CoreComponents.TableButton
        buttons={[
          {
            variant: 'contained',
            icon: PaymentIcon,
            onClick: () => updatePaymentSchedule({
              paymentScheduleId: props.paymentSchedule.id,
              actionType: null,
              status: CoreState.Quote.PaymentScheduleStatus.ReleaseRequested
            }),
            label: 'Request Release'
          }
        ]}
      />,
    [CoreState.Quote.PaymentScheduleStatus.ReleaseRequested]: <></>,
    [CoreState.Quote.PaymentScheduleStatus.Failed]:
      <CoreComponents.TableButton
        buttons={[
          {
            variant: 'contained',
            icon: PaymentIcon,
            onClick: () => requestPayment(props.paymentSchedule),
            label: 'Request Payment'
          }
        ]}
      />,
    [CoreState.Quote.PaymentScheduleStatus.Cancelled]:
      <CoreComponents.TableButton
        buttons={[
          {
            variant: 'contained',
            icon: PaymentIcon,
            onClick: () => requestPayment(props.paymentSchedule),
            label: 'Request Payment'
          }
        ]}
      />,
    [CoreState.Quote.PaymentScheduleStatus.Paid]:
      <IconButton
        size='small'
        onClick={handleDownloadButtonClick}
        sx={{
          maxHeight: '40px',
        }}
      >
        <DownloadIcon />
      </IconButton>,
    [CoreState.Quote.PaymentScheduleStatus.CreditCardPaymentPending]: <></>,
    [CoreState.Quote.PaymentScheduleStatus.NotRequired]: <></>,
    [CoreState.Quote.PaymentScheduleStatus.Released]: <></>,
  }
  const isLoading = requestPaymentScheduleQuery.isLoading || updatePaymentScheduleQuery.isLoading
  return (
    <>
      <CoreComponents.Loader show={isLoading} />
      {kvp[props.paymentSchedule.status]}
    </>
  )
}

export default JobViewPaymentScheduleActions;
