import { CoreState } from "@build-buddy/core";
import JobStatus from "../../models/JobStatus";

const transformJobStatusIsFinal = (
  task: CoreState.Task.Task,
  taskQuoteRequest: CoreState.Quote.QuoteRequest,
  quotes?: Array<CoreState.Quote.Quote>
) => {
  if(task.type !== CoreState.Task.TaskType.Complete) return;

  const kvp: any = {
    [CoreState.Task.TaskStatus.AwaitingReview]: JobStatus.AwaitingSignOff,
    [CoreState.Task.TaskStatus.Completed]: JobStatus.Completed,
  }
  return kvp[task.status];
}
export default transformJobStatusIsFinal