import { CoreComponents, CoreRouting, CoreState } from "@build-buddy/core";
import { useSelector } from "common/state/store";
import { useCompanyParams } from "modules/company/_hooks";
import { useEffect } from "react";
import { CompanyProfileRouteDefintions } from "../CompanyProfileRoutes";

const CompanyProfileInsuranceAdd = () => {
  const { download } = CoreState.File.useDownloadQueue();
  const routing = CoreRouting.useRouting();

  // params
  const { companyId } = useCompanyParams();
  const getInsuranceListByCompanyParams = { companyId };

  // queries
  const getInsuranceListByCompanyQuery = CoreState.Insurance.getInsuranceListByCompany.useQuery(getInsuranceListByCompanyParams);
  CoreState.Insurance.getInsuranceDefinitions.useQuery();

  // mutations
  const [addInsuranceByCompanyAction, addInsuranceByCompanyQuery] = CoreState.Insurance.addInsuranceByCompany.useMutation();
  const [uploadDocListByCompanyInsuranceAction, uploadDocListByCompanyQuery] = CoreState.File.uploadDocListByCompanyInsurance.useMutation();

  // locals
  const insurances = useSelector((state) => CoreState.Insurance.selectInsuranceListByCompany(state, getInsuranceListByCompanyParams));
  const insuranceDefinitions = useSelector(CoreState.Insurance.selectInsuranceDefinitions);
  const existingDefTypes = insurances.map(x => x.type);
  const remainingDefs = insuranceDefinitions.filter((i) => !existingDefTypes.includes(i.type));
  const isLoading = Boolean(getInsuranceListByCompanyQuery.isLoading);
  const isSaving = Boolean(addInsuranceByCompanyQuery.isLoading || uploadDocListByCompanyQuery.isLoading);
  const isSuccess =
    // addInsurance success
    Boolean(addInsuranceByCompanyQuery.isSuccess) &&
    // if we initiated the uploadDoc, then we check its success, otherwise its always true
    Boolean(uploadDocListByCompanyQuery.isUninitialized || uploadDocListByCompanyQuery.isSuccess);

  // events
  const handleClose = () => {
    routing.go(CompanyProfileRouteDefintions.COMPANY_PROFILE)
  }

  const handleSubmit = async (values: any) => {
    if (!companyId) return;

    const promises = [];

    const insurance: CoreState.Common.Subset<CoreState.Insurance.Insurance> = {
      type: values.type,
      reference: values.reference,
      expiry: values.expiry,
    }
    promises.push(
      addInsuranceByCompanyAction({
        companyId,
        insurance
      })
    )

    const docsToSave: Array<CoreState.Common.Subset<CoreState.File.Doc>> = values.files.toSave;
    if (docsToSave.length) {
      promises.push(
        uploadDocListByCompanyInsuranceAction({
          companyId,
          type: values.type,
          docs: docsToSave
        })
      )
    }

    await Promise.all(promises);
  }

  const handleDownload = (doc: CoreState.File.Doc) => {
    download(doc);
  }

  useEffect(() => {
    if (!isSuccess) return;
    handleClose();
  }, [isSuccess])

  return (
    <CoreComponents.InsuranceFormDialog
      title="Add Insurance"
      definitions={remainingDefs}
      isLoading={isLoading}
      isSaving={isSaving}
      onClose={() => handleClose()}
      onSubmit={(values) => handleSubmit(values)}
      onDownload={(_, doc) => handleDownload(doc)}
    />
  )
}
export default CompanyProfileInsuranceAdd;