
import * as yup from "yup";

const JobViewQuoteFormValidation = yup.object().shape({
  totalCost: yup
    .number()
    .required("Payment is required")
    .min(0, "Payment can't be negative"),
  paymentSchedule: yup
    .array()
    .of(yup
      .object()
      .shape({
        name: yup
          .string()
          .required("Please enter a payment stage description"),
        amount: yup
          .number()
          .required("Amount is required")
          .min(0, "Amount can't be negative")
          .typeError("Amount should be a number")
      })
    ),
  tnc: yup
    .bool()
    .oneOf([true], "Please accept the terms and conditions"),      
});

export default JobViewQuoteFormValidation;
