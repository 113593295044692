import { Skeleton, Grid, Box } from '@mui/material';

const CompanyProfileDetailSkeleton = () => {
  return (
    <Box sx={{ padding: 2 }}>
      <Box sx={{ display: 'flex', alignItems: 'center', mb: 2 }}>
        <Skeleton variant="circular" width={80} height={80} />
        <Box sx={{ ml: 2 }}>
          <Skeleton variant="text" width={200} height={30} />
          <Skeleton variant="text" width={150} height={20} />
        </Box>
      </Box>
      <Grid container spacing={1}>
        {Array.from({ length: 7 }).map((_, i) => (
          <Grid key={i} item xs={12} sm={6} sx={{ p: 1 }}>
            <Box sx={{ display: 'flex', alignItems: 'center', mb: 1 }}>
              <Skeleton variant="circular" width={30} height={30} />
              <Box sx={{ ml: 2, flexGrow: 1 }}>
                <Skeleton variant="text" width="80%" height={20} />
                <Skeleton variant="text" width="60%" height={25} />
              </Box>
            </Box>
          </Grid>
        ))}
      </Grid>
    </Box>
  );
};

export default CompanyProfileDetailSkeleton;
