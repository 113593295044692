import { useMemo } from "react";
import { useRouteMatch } from "react-router-dom";
import { CompanyProfileTeamsRoutesDefinitions } from "../CompanyProfileTeamsRoutes";

const useCompanyProfileTeamParams = () => {
  const match = useRouteMatch<any>([
    CompanyProfileTeamsRoutesDefinitions.COMPANY_PROFILE_TEAMS_MEMBER_EDIT.path
  ]);

  const params = useMemo(() => ({
    memberId: match?.params.mid,
  }), [match?.params]);

  return params;
}
export default useCompanyProfileTeamParams;