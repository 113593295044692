import { CoreHooks } from "@build-buddy/core";
import { Typography, useTheme } from "@mui/material";
import JobViewQuotesListTemplate from "./JobViewQuoteListTemplate";

const JobViewQuoteListHeader = () => {
  const mode = CoreHooks.useMode()
  const theme = useTheme();

  if (!mode.sm) return null;
  return (
    <JobViewQuotesListTemplate
      sx={{
        px: 3,
        pb: 1,
        borderBottom: `solid 1px ${theme.palette.grey[300]}`,
      }}
    >
      <Typography variant="subtitle2" sx={{ gridArea: "company" }}> Company </Typography>
      <Typography variant="subtitle2" sx={{ gridArea: "date" }}> Date Submitted </Typography>
      <Typography variant="subtitle2" sx={{ gridArea: "quote" }}> Quote </Typography>
      <Typography variant="subtitle2" sx={{ gridArea: "actions" }}> &nbsp; </Typography>
    </JobViewQuotesListTemplate>
  )
}

export default JobViewQuoteListHeader