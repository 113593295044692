import JobViewQualityAssurance from "./JobViewQualityAssurance";
import JobViewQualityAssuranceDefectsViewNoteAdd from "./job-view-quality-assurance-defects/job-view-quality-assurance-defects-view/JobViewQualityAssuranceDefectsViewNoteAdd";
import JobViewQualityAssuranceDefectsView from "./job-view-quality-assurance-defects/job-view-quality-assurance-defects-view/JobViewQualityAssuranceDefectsView";

const JobViewQualityAssuranceRouteDefinitions = {
  JOB_VIEW_QUALITY_ASSURANCE: {
    label: "Quality Assurance",
    path: "/*/job/:pid/stage/:sid/task/:tid/quote/:qid/quality-assurance",
    component: JobViewQualityAssurance
  },
  // subviews
  JOB_VIEW_QUALITY_ASSURANCE_DEFECTS_VIEW: {
    path: "/*/job/:pid/stage/:sid/task/:tid/quote/:qid/quality-assurance/defect/:did/view",
    component: JobViewQualityAssuranceDefectsView
  },
  JOB_VIEW_QUALITY_ASSURANCE_DEFECTS_VIEW_NOTE_ADD: {
    path: "/*/job/:pid/stage/:sid/task/:tid/quote/:qid/quality-assurance/defect/:did/view/note/add",
    component: JobViewQualityAssuranceDefectsViewNoteAdd,
  },
}

export default JobViewQualityAssuranceRouteDefinitions;