import * as CoreComponents from "@build-buddy/core/dist/components";
import * as CoreState from "@build-buddy/core/dist/state";
import { Edit as EditIcon } from "@mui/icons-material";
import { useSelector } from "common/state/store";
import { useCompanyParams } from "modules/company/_hooks";

const CompanyProfileDetailViewAvatar = () => {
  const { companyId } = useCompanyParams();

  // params
  const getAvatarByCompanyParams = { companyId }
  const currentUserId = useSelector(CoreState.User.selectCurrentUserId);
  const companyHasAvatar = useSelector((s) => CoreState.Company.selectCompanyHasAvatar(s, { companyId }));

  // queries
  const getAvatarByCompanyQuery = CoreState.File.getAvatarByCompany.useQuery(getAvatarByCompanyParams, { skip: (!companyId || !companyHasAvatar) });

  // mutations
  const [uploadAvatarByCompanyAction, uploadAvatarByCompanyQuery] = CoreState.File.uploadAvatarByCompany.useMutation();

  // locals
  const companyAvatar = useSelector((s) => CoreState.File.selectAvatarByCompany(s, getAvatarByCompanyParams))
  const isSaving = Boolean(uploadAvatarByCompanyQuery.isLoading && !uploadAvatarByCompanyQuery.isUninitialized);

  const handleSaveAvatar = (url: string, file: any) => {
    if (!companyId) return;
    const logo: CoreState.Common.Subset<CoreState.File.Doc> = { file };
    uploadAvatarByCompanyAction({ logo, companyId })
  }

  return (
    <CoreComponents.UploadAvatar
      src={companyAvatar}
      icon={EditIcon}
      saving={isSaving}
      onSave={handleSaveAvatar}
      sx={{
        mb: {
          xs: 2,
          md: 0
        },
        mr: {
          xs: 0,
          md: 3
        }
      }}
    />
  )
}
export default CompanyProfileDetailViewAvatar;