import JobViewQualityAssuranceChecklist from "./job-view-quality-assurance-checklist/JobViewQualityAssuranceChecklist";
import JobViewQualityAssuranceDefects from "./job-view-quality-assurance-defects/JobViewQualityAssuranceDefects";

const JobViewQualityAssurance = () => {
  return (
    <>
      <JobViewQualityAssuranceChecklist />
      <JobViewQualityAssuranceDefects />
    </>    
  )
}

export default JobViewQualityAssurance;