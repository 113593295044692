import { CoreState } from "@build-buddy/core";
import { Box } from "@mui/material";
import { CommonState } from "common";
import { useSelector } from "common/state/store";
import ServiceAreaListItem from "./ServiceAreaListItem";

const ServiceAreaList = () => {
  // selectors
  const companyId = useSelector(CommonState.App.selectCurrentCompanyId);
  const serviceAreas = useSelector((state) => CoreState.Company.selectServiceAreaSelectedByCompany(state, { companyId }));
  
  // queries
  const getServiceAreaByCompanyQuery = CoreState.Company.getServiceAreaByCompany.useQuery({ companyId });

  if (!serviceAreas) return null;

  return (
    <>
      {serviceAreas.map((item) => (
        <Box
          key={item.id}
          sx={{
            mb: 2,
            mt: 2
          }}
        >
          <ServiceAreaListItem
            serviceAreaTrimmed={item}
          />
        </Box>
      ))}
    </>
  )
}
export default ServiceAreaList;