import { createAction } from "@reduxjs/toolkit";

interface SetCurrentCompanyParams {
  companyId?: string;
}

const setCurrentCompany = createAction(
  "app/setCurrentCompany",
  (params: SetCurrentCompanyParams) => {
    return {
      payload: {
        id: params.companyId
      }
    }
  }
)

export default setCurrentCompany;