import { CoreComponents, CoreState } from "@build-buddy/core";
import { CommonState } from "common";
import { useDispatch, useSelector } from "common/state/store";
import { useEffect } from "react";

const AppInitialise = (props: any) => {
  const dispatch = useDispatch();

  // selectors
  const isLoading = useSelector(CommonState.App.selectIsLoading);
  const userId = useSelector(CoreState.User.selectAuthUserId);

  // queries
  const query = CoreState.User.getUser.useQuery({ userId }, { skip: !userId })

  useEffect(() => {
    if (!userId) return;
    dispatch(CommonState.App.initialise({ userId }));
  }, [userId]);

  if (isLoading) return <CoreComponents.InitializeLoader text="Getting things ready" />;

  return props.children;
};

export default AppInitialise;