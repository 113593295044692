
import { CoreComponents, CoreRouting, CoreState } from "@build-buddy/core";
import { Box } from "@mui/material";
import { useSelector } from "common/state/store";
import { useCompanyParams } from "modules/company/_hooks";
import { useEffect, useState } from "react";
import { CompanyProfileInsuranceRoutes, CompanyProfileInsuranceRoutesDefinitions } from "./CompanyProfileInsuranceRoutes";

interface CompanyProfileInsuranceProps {
}
const CompanyProfileInsurance = (props: CompanyProfileInsuranceProps) => {
  // hooks 
  const routing = CoreRouting.useRouting();
  const { companyId } = useCompanyParams();

  // queries
  const getInsuranceListByCompanyQuery = CoreState.Insurance.getInsuranceListByCompany.useQuery({ companyId });

  // mutations
  const [deleteInsuranceByCompanyAction, deleteInsuranceByCompanyQuery] = CoreState.Insurance.deleteInsuranceByCompany.useMutation();

  // locals
  const [isDeleting, setIsDeleting] = useState<Set<string>>(new Set());
  const insurances = useSelector((state) => CoreState.Insurance.selectInsuranceListByCompany(state, { companyId }));
  const isLoading = getInsuranceListByCompanyQuery.isLoading;

  // events
  const handleAdd = () => {
    routing.go(CompanyProfileInsuranceRoutesDefinitions.COMPANY_PROFILE_INSURANCE_ADD);
  }

  const handleEdit = (code: string) => {
    const params = { iid: code };
    routing.go(CompanyProfileInsuranceRoutesDefinitions.COMPANY_PROFILE_INSURANCE_EDIT, { params });
  }

  const handleDelete = (code: string) => {
    if (!companyId || !code) return;
    
    setIsDeleting(isDeleting.add(code));

    deleteInsuranceByCompanyAction({ companyId, code })
  }

  // effects
  useEffect(() => {
    // we need to clean up the isDeleting array here
    if (!(deleteInsuranceByCompanyQuery.isSuccess || deleteInsuranceByCompanyQuery.isError)) return;
    const code = deleteInsuranceByCompanyQuery.originalArgs?.code ?? "";

    setIsDeleting((prevSet) => {
      prevSet.delete(code);
      return new Set(prevSet);
    });
  }, [deleteInsuranceByCompanyQuery])

  return (
    <>
      <Box sx={{ mb: 2 }}>
        <CoreComponents.InsuranceAccordion
          insurances={insurances}
          isLoading={isLoading}
          isDeleting={isDeleting}
          onAdd={() => handleAdd()}
          onEdit={(insurance) => handleEdit(insurance.type)}
          onDelete={(insurance) => handleDelete(insurance.type)}
        />
      </Box>
      <CompanyProfileInsuranceRoutes />
    </>
  );
};
export default CompanyProfileInsurance;