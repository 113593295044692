import { CoreComponents } from "@build-buddy/core";
import { useTheme } from "@mui/material";

interface ServiceAreaTagProps {
  numSelected: number;
}

const ServiceAreaTag = (props: ServiceAreaTagProps) => {
  const { numSelected } = props;
  const theme = useTheme();

  const label = `${numSelected === 1 ? '1 Area Selected' : `${numSelected ?? 0} Areas Selected`}`;

  return (
    <CoreComponents.Tag
      label={label}
      isFullWidth={false}
      sx={{
        background: numSelected > 0 ? theme.palette.orange[100] : theme.palette.grey[100],
        color: numSelected > 0 ? theme.palette.orange[600] : theme.palette.grey[600],
        borderRadius: theme.spacing(1),
        m:0
      }}
    />
  )
}

export default ServiceAreaTag;