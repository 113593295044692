import { CommonState } from "common";
import { useDispatch } from "common/state/store";
import { useEffect, useMemo } from "react";
import useJobViewParams from "./useJobViewParams";

const useQueryJob = () => {
  const { projectId, stageId, taskId, quoteRequestId, companyId } = useJobViewParams();
  const dispatch = useDispatch();

  const jobParams = useMemo(() => ({
    projectId,
    stageId,
    taskId,
    quoteRequestId,
    companyId
  }), [projectId, stageId, taskId, quoteRequestId, companyId]);

  const messageParams = useMemo(() => ({
    projectId,
    stageId,
    taskId,
    quoteRequestId
  }), [projectId, stageId, taskId, quoteRequestId]);

  useEffect(() => {
    dispatch(CommonState.Job.getJob.initiate(jobParams));
  }, [jobParams])

  return {
    jobParams,
    messageParams
  }
}
export default useQueryJob;