import { CoreComponents, CoreModules, CoreState } from "@build-buddy/core";
import { Box } from "@mui/material";
import { useSelector } from "common/state/store";
import { useJobViewParams } from "../../_hooks";
import { selectJobStage } from "../../_selectors";
import { selectJobQualityAssuranceListByQuoteRequestGrouped } from "../../_selectors/selectJobQualityAssurance";
import JobViewQualityAssuranceChecklistActionsCheckbox from "./JobViewQualityAssuranceChecklistActionsCheckbox";
import JobViewQualityAssuranceChecklistDotProgress from "./JobViewQualityAssuranceChecklistDotProgress";

const JobViewQualityAssuranceChecklist = () => {
  const { quoteRequestId, companyId, projectId, stageId, taskId } = useJobViewParams();

  // params
  const qualityAssuranceParams = { companyId, quoteRequestId };
  const jobParams = { projectId, stageId, taskId, companyId, quoteRequestId };

  // queries
  const jobViewQualityAssuranceChecklistQuery = CoreState.QualityAssurance.getQualityAssuranceListByQuoteRequest.useQuery(qualityAssuranceParams);

  // selectors
  const checklistGrouped = useSelector((s) => selectJobQualityAssuranceListByQuoteRequestGrouped(s, qualityAssuranceParams));
  const currentStage = useSelector((s) => selectJobStage(s, jobParams));

  return (
    <Box sx={{ mb: 1.5 }}>
      <CoreComponents.CollapseHeader
        title="Quality Assurance Checklist"
        open={true}
        sx={{ mb: 1 }}
      >
        <CoreModules.Task.TaskView.TaskViewQualityAssuranceChecklist
          isEditable={false}
          isLoading={jobViewQualityAssuranceChecklistQuery.isLoading}
          checklist={checklistGrouped}
          dotProgress={JobViewQualityAssuranceChecklistDotProgress}
          currentStage={currentStage}
          checkbox={JobViewQualityAssuranceChecklistActionsCheckbox}
        />
      </CoreComponents.CollapseHeader>
    </Box>
  )
}

export default JobViewQualityAssuranceChecklist;