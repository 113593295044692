import { CoreState } from "@build-buddy/core";
import { Checkbox } from "@mui/material";

interface JobViewQualityAssuranceChecklistActionsCheckboxProps {
  isEditable?: boolean;
  qualityAssuranceItem: CoreState.QualityAssurance.QualityAssurance;
}
const JobViewQualityAssuranceChecklistActionsCheckbox = (props: JobViewQualityAssuranceChecklistActionsCheckboxProps) => {
  const { isEditable, qualityAssuranceItem } = props;

  // vars
  const isChecked = qualityAssuranceItem.status === CoreState.QualityAssurance.QualityAssuranceStatus.Completed;
  return <>
    <Checkbox
      edge="start"
      sx={{ mr: 1 }}
      disabled={!isEditable}
      checked={isChecked}
    />
  </>
}

export default JobViewQualityAssuranceChecklistActionsCheckbox;