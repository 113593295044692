import { CoreRouting, CoreState } from "@build-buddy/core";
import { useSelector } from "common/state/store";
import { JobRouteDefinitions } from "modules/company/job/JobRoutes";
import { useEffect } from "react";
import { useJobViewParams } from "../_hooks";
import { selectJobQuoteCurrent } from "../_selectors";
import JobViewQuoteForm from "./job-view-quote-form/JobViewQuoteForm";

const JobViewQuoteEdit = () => {
  // hooks
  const routing = CoreRouting.useRouting();

  // params
  const { projectId, stageId, taskId, quoteRequestId, quoteId, companyId } = useJobViewParams();
  const jobParams = { projectId, stageId, taskId, quoteRequestId, companyId };
  const getDocListByQuoteParams = { projectId, quoteId, companyId };

  // queries
  const quoteDocListQuery = CoreState.File.getDocListByQuote.useQuery(getDocListByQuoteParams);

  // mutations
  const [updateQuoteAction, updateQuoteQuery] = CoreState.Quote.updateQuote.useMutation();
  const [uploadDocListByQuoteAction, uploadDocListByQuoteQuery] = CoreState.File.uploadDocListByQuote.useMutation();
  const [deleteDocListByQuoteAction, deleteDocListByQuoteQuery] = CoreState.File.deleteDocListByQuote.useMutation();

  // locals
  const isSaving = updateQuoteQuery.isLoading || uploadDocListByQuoteQuery.isLoading || deleteDocListByQuoteQuery.isLoading;
  const isSuccess = updateQuoteQuery.isSuccess || uploadDocListByQuoteQuery.isSuccess || deleteDocListByQuoteQuery.isSuccess;

  // selectors
  const quote = useSelector((s) => selectJobQuoteCurrent(s, jobParams));
  const jobQuoteDocList = useSelector((s) => CoreState.File.selectDocListByQuote(s, getDocListByQuoteParams));

  const handleClose = () => {
    routing.go(JobRouteDefinitions.JOB_VIEW_QUOTES)
  }

  const handleSave = async (values: any) => {
    const updateQuoteActionResp = await updateQuoteAction({
      projectId: projectId,
      quoteRequestId: quoteRequestId,
      quoteId: quoteId,
      quote: {
        company: {
          id: companyId
        },
        cost: values.totalCost,
        status: CoreState.Quote.QuoteStatus.Submitted,
        paymentSchedule: values.paymentSchedule.map((ps: any) => ({
          id: ps.id,
          amount: ps.amount,
          description: ps.name,
          paymentType: ps.isImmediate ? CoreState.Quote.PaymentScheduleType.ImmediatePayment : CoreState.Quote.PaymentScheduleType.StagePayment,
        })),
        manageTasks: values.tasks.map((t: any) => ({
          taskPublicId: t.id,
          name: t.name,
          startDate: t.startDate,
          endDate: t.endDate,
          changed: t.changed,
          originalStartDate: t.originalStartDate,
          originalEndDate: t.originalEndDate,
        })),
        specialConditions: values.specialConditions
      }
    })

    // Throw error
    if ('error' in updateQuoteActionResp) return;

    if (!values.files) return;

    if (values.files.toSave && values.files.toSave.length) {
      await uploadDocListByQuoteAction({
        projectId: projectId,
        quoteRequestId: quoteRequestId,
        workOrderId: quoteId,
        docs: values.files.toSave,
        companyId: companyId
      })
    }

    if (values.files.toRemove && values.files.toRemove.length) {
      await deleteDocListByQuoteAction({
        projectId: projectId,
        quoteRequestId: quoteRequestId,
        quoteId: quoteId,
        docs: values.files.toRemove,
        companyId: companyId
      })
    }
  }

  // side effects
  useEffect(() => {
    if (!isSuccess) return;
    handleClose();
  }, [isSuccess])

  if (!quote) return null;

  return (
    <JobViewQuoteForm
      title="Edit Quote"
      saveButtonText="Save"
      quote={quote}
      files={jobQuoteDocList}
      isOpen={true}
      isSaving={isSaving}
      isReadonly={true}
      onClose={() => handleClose()}
      onSave={handleSave}
    />
  )
}
export default JobViewQuoteEdit;