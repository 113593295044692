import { CoreRouting, CoreState } from "@build-buddy/core";
import { CommonState } from "common";
import { useSelector } from "common/state/store";
import { useEffect } from "react";
import { useParams } from "react-router-dom";
import ServiceAreaFormDialog from "./setup-service-area-form-dialog/ServiceAreaFormDialog";

const ServiceAreaEdit = () => {
  // hooks
  const routing = CoreRouting.useRouting();
  const params = useParams<{ said: string }>();

  // selectors
  const companyId = useSelector(CommonState.App.selectCurrentCompanyId);
  const states = useSelector((s) => CoreState.Company.selectAllServiceAreaStatesByCompany(s, { companyId }));
  const serviceArea = useSelector((state) => CoreState.Company.selectServiceAreaById(state, {
    companyId,
    id: params.said
  }));

  // queries
  const getCompanyQuery = CoreState.Company.getCompany.useQuery({ companyId }, { skip: !companyId });
  const getServiceAreaByCompanyQuery = CoreState.Company.getServiceAreaByCompany.useQuery({ companyId });

  // mutations
  const [addServiceAreaAction, addServiceAreaQuery] = CoreState.Company.updateServiceAreaByCompany.useMutation();

  // locals
  const isLoading = getServiceAreaByCompanyQuery.isLoading || getCompanyQuery.isLoading;
  const isSaving = addServiceAreaQuery.isLoading;

  //handlers
  const handleSubmit = async (selectedServiceAreas: Array<string>, deletedServiceAreas: Array<string>) => {
    if (!companyId) return;
    addServiceAreaAction({ companyId, selectedServiceAreas, deletedServiceAreas });
  }

  const handleClose = () => routing.back();

  useEffect(() => {
    if (!addServiceAreaQuery.isSuccess) return;
    handleClose();
  }, [addServiceAreaQuery.isSuccess]);

  return (
    <ServiceAreaFormDialog
      title="Edit Service Area"
      serviceArea={serviceArea}
      isLoading={isLoading}
      isSaving={isSaving}
      onClose={handleClose}
      onSubmit={handleSubmit}
      states={states}
    />
  );
};

export default ServiceAreaEdit;