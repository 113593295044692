enum JobStatus {
  NA = "NA",
  New = "New",
  QuoteSubmitted = "QuoteSubmitted",
  QuoteDeclined = "QuoteDeclined",
  AwaitingConfirmation = "AwaitingConfirmation", 
  AwaitingOwner = "AwaitingOwner",
  AwaitingToStart = "AwaitingToStart",
  InProgress = "InProgress",
  InQA = "InQA",
  AwaitingSignOff = "AwaitingSignOff",
  AwaitingConstruction = "AwaitingConstruction",
  Completed = "Completed",
  Closed = "Closed"
}
export default JobStatus;