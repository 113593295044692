import { CoreState } from "@build-buddy/core";
import JobStatus from "../../models/JobStatus";

const transformJobStatusChain = (
  task: CoreState.Task.Task,
  taskQuoteRequest: CoreState.Quote.QuoteRequest,
  quotes?: Array<CoreState.Quote.Quote>
) => {
  const chain: Array<Function> = [];
  const internal = {
    add(transformer: Function) {
      chain.push(transformer);
      return internal;
    },
    execute(): JobStatus | undefined {
      for (let i = 0; i < chain.length; i++) {
        const rtn = chain[i](task, taskQuoteRequest, quotes);
        if (rtn) return rtn;
      }
      return;
    },
  };
  return internal;
};

export default transformJobStatusChain