import { CoreComponents, useMode } from "@build-buddy/core";
import { Delete as DeleteIcon } from "@mui/icons-material";
import { Box, IconButton, Stack } from "@mui/material";
import { getIn, useFormikContext } from "formik";
import JobViewQuoteFormValues from "../JobViewQuoteFormValues";
import JobViewQuoteFormPaymentScheduleTemplate from "./JobViewQuoteFormPaymentScheduleTemplate";

interface JobViewQuoteFormPaymentScheduleFormItemProps {
  name: string;
  onDelete(): void;
}
const JobViewQuoteFormPaymentScheduleFormItem = (props: JobViewQuoteFormPaymentScheduleFormItemProps) => {
  const { name, onDelete } = props;
  const formik = useFormikContext<JobViewQuoteFormValues>();
  const mode = useMode()

  const disabled = Boolean(getIn(formik.values, `${name}.disabled`));

  return (
    <JobViewQuoteFormPaymentScheduleTemplate>
      <CoreComponents.FormikTextbox
        size="small"
        name={`${name}.name`}
        disabled={disabled}
        sx={{ gridArea: "stage" }}
      />
      <Box
        sx={{
          gridArea: "paymentType",
          display: 'flex',
        }}
      >
        {mode.isMobile &&
          <CoreComponents.Label label="PAYMENT TYPE" sx={{ minWidth: 100 }} />
        }
        <CoreComponents.FormikSwitch
          name={`${name}.isImmediate`}
          formik={formik}
          innerlabels={['Wallet', 'Direct']}
        />
      </Box>
      <Stack
        direction="row"
        alignItems="flex-start"
        sx={{ gridArea: "amount", minWidth: '90px' }}
      >
        {mode.isMobile &&
          <CoreComponents.Label label="AMOUNT" sx={{ mt: 1.5, minWidth: 100 }} />
        }
        <CoreComponents.FormikTextbox
          size="small"
          name={`${name}.amount`}
          sx={{ minWidth: '90px' }}
          inputComponent={CoreComponents.Money as any}
          InputComponentProps={{
            sx: { textAlign: "right" }
          }}
        />
      </Stack>
      {Boolean(formik.values.paymentSchedule.length > 1 && !disabled) &&
        <Box sx={{ gridArea: "actions", textAlign: "right" }}>
          <IconButton
            onClick={() => onDelete()}
            sx={{ fontSize: "16px", mt: 0.75 }}
            edge="start"
          >
            <DeleteIcon />
          </IconButton>
        </Box>
      }
    </JobViewQuoteFormPaymentScheduleTemplate>
  )
}
export default JobViewQuoteFormPaymentScheduleFormItem;