import { CoreRouting, CoreState } from "@build-buddy/core";
import { Button, Skeleton } from "@mui/material";
import { CommonState } from "common";
import { selectIncompleteProfileItems, selectIsCompanyHaveWallet } from "common/state/app";
import { useSelector } from "common/state/store";
import { JobRouteDefinitions } from "modules/company/job/JobRoutes";
import { UserWalletRouteDefinitions } from "modules/user/wallet/WalletModule";
import { useState } from "react";
import { useJobViewParams } from "../../_hooks";
import JobViewActionsQuoteValidation from "./JobViewActionsQuoteValidation";

interface JobViewActionsQuoteOrios {
  onClick(): void;
}
const JobViewActionsQuote = (props: JobViewActionsQuoteOrios) => {
  const routing = CoreRouting.useRouting();

  // Params
  const { projectId, stageId, taskId, quoteRequestId } = useJobViewParams();
  // State
  const [validationError, setValidationError] = useState(false)

  // Selectors
  const userId = useSelector(CoreState.User.selectCurrentUserId);
  const currentCompanyId = useSelector(CommonState.App.selectCurrentCompanyId)
  const validations = useSelector((s) => selectIncompleteProfileItems(s, { companyId: currentCompanyId, userId }));
  const isCurrentuserOwner = useSelector(CommonState.App.selectIsCurrentUserOwner)
  const hasCompanyWallet = useSelector((s) => selectIsCompanyHaveWallet(s, { userId, companyId: currentCompanyId }));

  // Query
  const financialsQuery = CoreState.Wallet.getFinancialDetails.useQuery({ userId, companyId: currentCompanyId })

  const handleClick = () => {
    const params = {
      pid: projectId,
      sid: stageId,
      tid: taskId,
      qid: quoteRequestId,
    }
    if(!hasCompanyWallet && !isCurrentuserOwner) {
      setValidationError(true)
      return
    }
    if (!hasCompanyWallet) {
      routing.go(UserWalletRouteDefinitions.CREATE_WALLET)
      return
    }
    if (!validations.hasAbn || !validations.hasBrochure) {
      setValidationError(true)
      return
    }
    routing.go(
      JobRouteDefinitions.JOB_VIEW_QUOTE_ADD, { params }
    )
  }

  if (financialsQuery.isLoading) {
    return (
      <Skeleton variant="rounded" sx={{ borderRadius: '2rem' }} height="40px" width="130px" />
    )
  }

  return (
    <>
      <Button
        variant="contained"
        color="primary"
        onClick={() => handleClick()}
        sx={{
          flex: {
            xs: 1,
            md: "inherit"
          }
        }}
      >
        Submit Quote
      </Button>
      <JobViewActionsQuoteValidation
        show={validationError}
        onClose={() => setValidationError(false)}
      />
    </>
  )
}

export default JobViewActionsQuote;