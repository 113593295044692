import { Button } from "@mui/material";
import { useSelector } from "common/state/store";
import { useState } from "react";
import { useQueryJob } from "../../_hooks";
import { selectJobQuoteCurrent } from "../../_selectors";
import JobViewAcceptQuoteConfirmSign from "./JobViewAcceptQuoteConfirmSign";
import JobViewAcceptQuoteConfirmStandard from "./JobViewAcceptQuoteConfirmStandard";

const JobViewActionsConfirm = () => {
  const [showConfirmDialog, setShowConfirmDialog] = useState(false);
  const queryJob = useQueryJob();

  // selectors
  const jobQuote = useSelector((s) => selectJobQuoteCurrent(s, queryJob.jobParams));

  // locals
  const signatureRequired = Boolean(jobQuote?.isContractSignatureRequired);

  return (
    <>
      <Button
        variant="contained"
        color="primary"
        onClick={() => setShowConfirmDialog(true)}
        sx={{
          flex: {
            xs: 1,
            md: "inherit"
          }
        }}
      >
        Confirm
      </Button>
      {showConfirmDialog &&
        <>
          {signatureRequired &&
            <JobViewAcceptQuoteConfirmSign
              onClose={() => setShowConfirmDialog(false)}
            />
          }
          {!signatureRequired &&
            <JobViewAcceptQuoteConfirmStandard
              onClose={() => setShowConfirmDialog(false)}
            />
          }
        </>
      }
    </>
  )
}

export default JobViewActionsConfirm;