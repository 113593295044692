import { FormikContextType, getIn, setIn } from "formik";

import { CoreComponents } from "@build-buddy/core";
import { Box, Stack, SxProps, Theme, useTheme } from "@mui/material";
import { ServiceAreaFormikValues } from "./setup-service-area-form-dialog/ServiceAreaFormDialogContent";

interface Checklist {
  id: string;
  name: string;
  isChecked?: boolean;
  isVisible: boolean;
  children?: Array<Checklist>;
  pathToCurrentNode?: string;
  isIndeterminate?: boolean;
}

interface ChecklistItemProps {
  checklist: Checklist;
  formik: FormikContextType<ServiceAreaFormikValues>;
  indentLevel?: number;
  checkboxSx?: SxProps<Theme>;
  containerSx?: SxProps<Theme>;
}

type LocalFormik = { values: Record<string, any>; setValues: (values: Record<string, any>) => void };

// potentially pass this function from outside to make this component reusable if we need it somewhere else
const pathReplacer = (path?: string) => {
  if (!path) return "";
  return path.replace(/\.isChecked$/, "").replace(/serviceAreas?./, "")
};

const handleParentChange = (formik: LocalFormik, name: string) => {
  let localValues = { ...formik };
  const nodePath = pathReplacer(name);

  const nodeValues: Checklist | undefined = getIn(localValues, nodePath);

  if (!nodeValues) return localValues;

  const newCheckedState = Boolean(!nodeValues.isChecked);

  // Update children nodes
  let children = nodeValues.children ? [...nodeValues.children] : [];
  for (let child of children) {
    if (child.children) {
      children.push(...child.children);
    }

    const childPath = pathReplacer(child.pathToCurrentNode);

    const childCheckedPath = childPath ? `${childPath}.isChecked` : "isChecked";
    const childIndeterminatePath = childPath ? `${childPath}.isIndeterminate` : "isIndeterminate";

    localValues = setIn(localValues, childCheckedPath, newCheckedState);
    localValues = setIn(localValues, childIndeterminatePath, false);
  }

  // Update pathToCurrentNode node if it exists
  if (nodeValues.pathToCurrentNode) {
    localValues = handleChildChange(localValues, name);
  } else {
    localValues = setIn(localValues, "isIndeterminate", false);
  }

  return localValues;
};

const handleChildChange = (formik: LocalFormik, name: string) => {
  let localValues = { ...formik };
  const nodePath = pathReplacer(name);
  const nodeValues: Checklist | undefined = getIn(localValues, nodePath);
  if (!nodeValues) return localValues;

  const newCheckedState = Boolean(!nodeValues.isChecked);
  const pathToCurrentNode = pathReplacer(nodeValues.pathToCurrentNode);

  // Update the current node
  localValues = setIn(localValues, pathToCurrentNode ? `${pathToCurrentNode}.isChecked` : "isChecked", newCheckedState);
  localValues = setIn(localValues, pathToCurrentNode ? `${pathToCurrentNode}.isIndeterminate` : "isIndeterminate", false);

  // Update parent nodes
  let parentPathArr = pathToCurrentNode ? pathToCurrentNode.split(".") : [];

  while (parentPathArr.length > 0) {
    parentPathArr.pop();

    const currentParentPath = parentPathArr.join(".");
    const currentCheckedPath = currentParentPath ? `${currentParentPath}.isChecked` : "isChecked";
    const currentIndeterminatePath = currentParentPath ? `${currentParentPath}.isIndeterminate` : "isIndeterminate";

    localValues = setIn(localValues, currentCheckedPath, newCheckedState);

    const currentParentValue = getIn(localValues, currentParentPath);
    if (currentParentValue && currentParentValue.children) {
      const allChecked = currentParentValue.children.every((child: Checklist) => child.isChecked);
      const someChecked = currentParentValue.children.some((child: Checklist) => child.isChecked);
      const someIndeterminate = currentParentValue.children.some((child: Checklist) => child.isIndeterminate);

      localValues = setIn(localValues, currentCheckedPath, allChecked);
      localValues = setIn(localValues, currentIndeterminatePath, Boolean(!allChecked && (someChecked || someIndeterminate)));
    } else {
      localValues = setIn(localValues, currentCheckedPath, newCheckedState);
    }
  }

  return localValues;
};

const handleClick = async (hasChildren: boolean, formik: FormikContextType<any>, name: string) => {
  const values = formik.values.serviceAreas;
  let localValues: any;

  if (hasChildren) {
    localValues = handleParentChange(values, name);
  } else {
    localValues = handleChildChange(values, name);
  }

  await formik.setFieldValue("serviceAreas", localValues);
};

const hasChildren = (items: Array<Checklist> | undefined): items is Array<Checklist> => {
  return Boolean(items && items.length > 0);
}

const ServiceAreaChecklist = (props: ChecklistItemProps) => {
  const {
    checklist,
    indentLevel = 0,
    formik,
  } = props;
  const theme = useTheme();

  const name = checklist?.pathToCurrentNode
    ? `${checklist.pathToCurrentNode}.isChecked`
    : 'isChecked'

  const newIndentLevel = checklist.isVisible ? indentLevel : indentLevel - 1;

  return (
    <Box
      sx={{
        ml: newIndentLevel <= 1 ? 0 : 1.5 * newIndentLevel,
        ...props.containerSx,
      }}
    >
      {
        checklist.isVisible &&
        <Stack
          sx={{
            borderBottom: newIndentLevel === 0 ? `1px solid ${theme.palette.grey[300]}` : "none",
            mb: 1,
          }}
          flexDirection="column"
          justifyContent="center"
        >
          <CoreComponents.FormikCheckbox
            name={name}
            formik={formik}
            label={checklist.name}
            indeterminate={Boolean(checklist.isIndeterminate)}
            onChange={(e) => {
              handleClick(hasChildren(checklist.children), formik, name);
            }}
          />
        </Stack>
      }
      {hasChildren(checklist.children) && checklist.children.map((child) => (
        <ServiceAreaChecklist
          key={child.id}
          checklist={child}
          formik={formik}
          indentLevel={newIndentLevel + 1}
        />
      ))}
    </Box>
  )
};

export default ServiceAreaChecklist;