import {
    CoreRouting,
    CoreState,
    Truncate
} from "@build-buddy/core";

import {
    Home as HomeIcon,
    KeyboardArrowDown as KeyboardArrowDownIcon,
} from "@mui/icons-material";

import {
    Avatar,
    Menu,
    MenuItem,
    Stack,
    Typography,
    useTheme
} from "@mui/material";

import { useRef, useState } from "react";

import { useSelector } from "common/state/store";

import { useHistory, useLocation } from "react-router-dom";
import { CompanyRouteDefinitions } from "../CompanyRoutes";
import { CompanySelectList } from "./CompanySelectList";

type CompanySelectProps = {
  companyId?: string;
  userId?: string;
  onChange(companyId: string): void;
}

export const CompanySelect = (props: CompanySelectProps) => {
  const {
    companyId,
    userId,
    onChange
  } = props;

  const theme = useTheme();
  const routing = CoreRouting.useRouting();
  const location = useLocation();
  const history = useHistory();

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  const ref = useRef<any>(null);

  const query = CoreState.Company.getCompanyListByUser.useQuery({ userId: userId }, { skip: !userId })

  const companyList = useSelector((s) => CoreState.Company.selectCompanyListByUser(s, { userId }));
  const company = companyList.find((company) => company.id === companyId);

  const handleClick = (e: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(e.currentTarget);
  };

  const handleAddClick = () => {
    setAnchorEl(null);
    routing.go(CompanyRouteDefinitions.COMPANY_ADD);
  };

  const handleSwitchClick = (id: string) => {
    setAnchorEl(null);

    // replace the id in url    
    const arr = location.pathname.split('/');
    const oldCompanyId = arr[2];
    const isValidCompany = companyList.find((company) => company.id === oldCompanyId);
    if (isValidCompany) {
      arr[2] = id;
      history.push(arr.join("/"))
    }
    
    onChange(id)
  };

  return (
    <>
      <Stack
        onClick={handleClick}
        direction="row"
        alignItems="center"
        sx={{
          cursor: "pointer",
          width: {
            xs: "100%",
            md: "500px",
          },
        }}
        ref={ref}
      >
        <Avatar sx={{ bgcolor: theme.palette.primary.main, mr: 1.5, height: 32, width: 32 }}>
          <HomeIcon sx={{
            fontSize: 20,
            bottom: 2,
            color: theme.palette.common.white,
          }} />
        </Avatar>
        <Truncate
          lines={1}
          text={company?.name || "No Organisation"}
          TypographyProps={{
            variant: "h3",
            fontWeight: 500,
            flexGrow: {
              xs: 1,
              md: 0,
            },
          }}
        />
        <KeyboardArrowDownIcon sx={{ ml: 1 }} fontSize="small" />
      </Stack>
      <Menu
        open={Boolean(anchorEl)}
        anchorEl={anchorEl}
        onClose={() => setAnchorEl(null)}
        BackdropProps={{
          style: {
            backgroundColor: "transparent",
          },
        }}
        MenuListProps={{
          style: {
            padding: 0,
            width: ref?.current?.clientWidth,
          },
        }}
        transformOrigin={{ horizontal: "right", vertical: "top" }}
        anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
      >
        <CompanySelectList
          onClick={handleSwitchClick}
          organisations={companyList}
        />
        <MenuItem
          sx={{
            py: 1.5,
            background: theme.palette.primary.main,
            color: theme.palette.primary.contrastText,
            "&:hover": {
              background: theme.palette.primary.dark,
            },
          }}
          onClick={handleAddClick}
        >
          <Stack alignItems="center" direction="row">
            <Typography variant="body1" sx={{ fontWeight: 500 }}>
              Add New Organisation
            </Typography>
          </Stack>
        </MenuItem>
      </Menu>
    </>
  );
};
