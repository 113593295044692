import { CoreComponents, CoreRouting, CoreState } from "@build-buddy/core";
import { useSelector } from "common/state/store";
import { useCompanyParams } from "modules/company/_hooks";
import { useEffect } from "react";
import { CompanyProfileRouteDefintions } from "../CompanyProfileRoutes";
import { useCompanyProfileInsuranceParams } from "./_hooks";

const CompanyProfileInsuranceAdd = () => {
  const { download } = CoreState.File.useDownloadQueue();
  const routing = CoreRouting.useRouting();

  // params
  const { companyId } = useCompanyParams();
  const { code } = useCompanyProfileInsuranceParams();
  const getInsuranceListByCompanyParams = { companyId, type: code };

  // queries
  const getInsuranceByCompanyQuery = CoreState.Insurance.getInsuranceByCompany.useQuery(getInsuranceListByCompanyParams)

  // mutations
  const [updateInsuranceByCompanyAction, updateInsuranceByCompanyQuery] = CoreState.Insurance.updateInsuranceByCompany.useMutation();
  const [uploadDocListByCompanyInsuranceAction, uploadDocListByCompanyQuery] = CoreState.File.uploadDocListByCompanyInsurance.useMutation();
  const [deleteDocListByCompanyInsuranceAction, deleteDocListByCompanyInsuranceQuery] = CoreState.File.deleteDocListByCompanyInsurance.useMutation();

  // locals
  const insurance = useSelector((s) => CoreState.Insurance.selectInsuranceByCompany(s, getInsuranceListByCompanyParams));
  const definition: CoreState.Insurance.InsuranceDefinition = { name: insurance?.name || "", type: insurance?.type || "", shortDescription: "", tags: [] };
  const isLoading = Boolean(getInsuranceByCompanyQuery.isLoading);
  const isSaving = Boolean(updateInsuranceByCompanyQuery.isLoading || uploadDocListByCompanyQuery.isLoading);
  const isSuccess =
    // addInsurance success
    Boolean(updateInsuranceByCompanyQuery.isSuccess) &&
    // if we initiated the uploadDoc, then we check its success, otherwise its always true
    Boolean(uploadDocListByCompanyQuery.isUninitialized || uploadDocListByCompanyQuery.isSuccess) &&
    Boolean(deleteDocListByCompanyInsuranceQuery.isUninitialized || deleteDocListByCompanyInsuranceQuery.isSuccess)

  // events
  const handleClose = () => {
    routing.go(CompanyProfileRouteDefintions.COMPANY_PROFILE)
  }

  const handleSubmit = async (values: any) => {
    if (!companyId) return;

    const promises = [];

    const insuranceValues: CoreState.Common.Subset<CoreState.Insurance.Insurance> = {
      type: values.type,
      reference: values.reference,
      expiry: values.expiry,
      version: insurance?.version
    }
    promises.push(
      updateInsuranceByCompanyAction({
        companyId,
        insurance: insuranceValues
      })
    )

    const docsToSave: Array<CoreState.Common.Subset<CoreState.File.Doc>> = values.files.toSave;
    if (docsToSave.length) {
      promises.push(
        uploadDocListByCompanyInsuranceAction({
          companyId,
          type: values.type,
          docs: docsToSave,
        })
      )
    }

    const docsToRemove: Array<CoreState.Common.Subset<CoreState.File.Doc>> = values.files.toRemove;
    if (docsToRemove.length) {
      promises.push(
        deleteDocListByCompanyInsuranceAction({
          companyId,
          type: values.type,
          docs: docsToRemove,
        })
      )
    }

    await Promise.all(promises);
  }

  const handleDownload = (doc: CoreState.File.Doc) => {
    download(doc);
  }

  useEffect(() => {
    if (!isSuccess) return;
    handleClose();
  }, [isSuccess])

  return (
    <CoreComponents.InsuranceFormDialog
      title="Edit Insurance"
      insurance={insurance}
      definitions={[definition]}
      isLoading={isLoading}
      isSaving={isSaving}
      onClose={() => handleClose()}
      onSubmit={(values) => handleSubmit(values)}
      onDownload={(_, doc) => handleDownload(doc)}
    />
  )
}
export default CompanyProfileInsuranceAdd;