import { CoreState } from "@build-buddy/core"
import { createSelector } from "@reduxjs/toolkit"
import { selectJob, selectJobQuoteCurrent } from "./selectJob"

export const selectShowVariationsTab = createSelector(
  [selectJob, selectJobQuoteCurrent],
  (job, jobQuote) => {
    const showVariationsTab = Boolean(![
      CoreState.Task.TaskType.Request,
      CoreState.Task.TaskType.Select,
    ].includes(job?.task?.type as CoreState.Task.TaskType) && jobQuote)
    return showVariationsTab
  }
)