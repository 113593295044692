import { CoreComponents, CoreModules } from "@build-buddy/core";
import { Person as PersonIcon, Email as EmailIcon, LocalPhone as PhoneIcon } from "@mui/icons-material";
import { Box, Stack, Typography, useTheme } from "@mui/material";
import { useSelector } from "common/state/store";
import { useQueryJob } from "../_hooks";
import { selectJobQuoteRequest } from "../_selectors";
import JobViewContentDetailsStartEndDate from "./JobViewContentDetailsStartEndDate";

const TaskViewDates = () => {
  const theme = useTheme();
  const queryJob = useQueryJob();

  // selectors
  const quoteRequest = useSelector((s) => selectJobQuoteRequest(s, queryJob.jobParams));

  if (!quoteRequest) return null;

  return (
    <CoreModules.Task.TaskView.TaskViewOutline>
      <JobViewContentDetailsStartEndDate />
      <Box sx={{ display: 'flex', alignItems: 'center' }}>
        <CoreModules.Task.TaskView.TaskViewOutlineItem
          title="Owner"
          value={quoteRequest.owner}
          icon={PersonIcon}
          iconStyle={{
            background: theme.palette.primary.main,
            color: theme.palette.common.white,
            fontSize: "28px"
          }}
        />
        <Stack direction="column" sx={{ mt: 1 }} gap={0.5}>
          <Stack direction="row" alignItems="center" gap={1}>
            <EmailIcon sx={{ fontSize: 16 }} />
            <Typography variant="caption">{quoteRequest.ownerEmail}</Typography>
          </Stack>
          <Stack direction="row" alignItems="center" gap={1}>
            <PhoneIcon sx={{ fontSize: 16 }} />
            <Typography variant="caption">{quoteRequest.ownerMobile}</Typography>
          </Stack>
        </Stack>
      </Box>
    </CoreModules.Task.TaskView.TaskViewOutline>
  )
}
export default TaskViewDates;