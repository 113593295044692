import { Box, DialogContent, DialogActions, Skeleton } from "@mui/material";

const ServiceAreaFormDialogContentSkeleton = () => {
  return (
    <>
      <DialogContent sx={{ textAlign: "left", pt: 5 }}>
        <Skeleton height={56} />
        <Box sx={{ display: "flex" }}>
          <Skeleton height={56} sx={{ flex: 1, mr: 2 }} />
          <Skeleton height={56} sx={{ flex: 1 }} />
        </Box>
        <Skeleton height={100} />
      </DialogContent>
      <DialogActions>
        <Box
          sx={{
            display: "flex",
            flexDirection: {
              xs: "column",
              md: "row"
            },
            alignItems: "center",
            justifyContent: "center",
            flex: 1
          }}
        >
          <Skeleton
            height={75}
            sx={{
              width: { xs: "100%", md: 250 }
            }}
          />
        </Box>
      </DialogActions>
    </>
  )
}
export default ServiceAreaFormDialogContentSkeleton;