import { CoreState } from "@build-buddy/core";
import JobStatus from "../../models/JobStatus";

const transformJobStatusIsWithOwner = (
  task: CoreState.Task.Task,
  taskQuoteRequest: CoreState.Quote.QuoteRequest,
  quotes?: Array<CoreState.Quote.Quote>
) => {
  // if the task status is Pending and weve gotten this far, the task is on standby and the owner needs to action something
  if(task.status === CoreState.Task.TaskStatus.Pending) return JobStatus.AwaitingOwner;

  const exclude = [
    CoreState.Task.TaskType.Manage,
    CoreState.Task.TaskType.ManageOnsite,
    CoreState.Task.TaskType.ManageOffsite,
    CoreState.Task.TaskType.Request,
    CoreState.Task.TaskType.Select,
    CoreState.Task.TaskType.QualityCheck,
    CoreState.Task.TaskType.Complete,
    CoreState.Task.TaskType.AwaitingDetailST,
  ]
  if (exclude.some(x => x === task.type)) return

  return JobStatus.AwaitingOwner;
}
export default transformJobStatusIsWithOwner