import { CoreState } from "@build-buddy/core";
import { CommonState } from "common";
import { useSelector } from "common/state/store";
import CompanyProfileSettingsForm from "./CompanyProfileSettingsForm";

const CompanyProfileSettingsEdit = () => {
  // params
  const companyId = useSelector(CommonState.App.selectCurrentCompanyId);
  const getNotificationSettingListByCompanyParams = { companyId };
  const getCompanyProfileParams = { companyId };

  // queries
  const getCompanyQuery = CoreState.Company.getCompany.useQuery(getCompanyProfileParams, { skip: !companyId });
  const getNotificationSettingListByCompanyQuery = CoreState.Settings.getNotificationSettingByCompany.useQuery(getNotificationSettingListByCompanyParams, { skip: !companyId });

  // locals
  const isLoading =
    getNotificationSettingListByCompanyQuery.isLoading ||
    getCompanyQuery.isLoading ||
    getCompanyQuery.isFetching;

  return (
    <CompanyProfileSettingsForm isLoading={isLoading} />
  )
}
export default CompanyProfileSettingsEdit;