import { CoreComponents, CoreModules } from "@build-buddy/core";
import { Box } from "@mui/material";
import { useSelector } from "common/state/store";
import { useQueryJobMessageList } from "../_hooks";
import { selectJobMessageList, selectJobMessageRequest } from "../_selectors";
import JobViewMessagesEmpty from "./JobViewMessagesEmpty";

const JobViewMessages = () => {
  // hooks
  const queryJobMessageList = useQueryJobMessageList();

  // selectors
  const messages = useSelector((s) => selectJobMessageList(s, queryJobMessageList.quoteNoteThreadListParams))
  const messagesRequest = useSelector((s) => selectJobMessageRequest(s, queryJobMessageList.quoteNoteThreadListParams))
  
  // local
  const isEmpty = !messages || !messages.length;
  const isLoading = messagesRequest.isLoading && messagesRequest.isUninitialized;

  return (
    <Box>
      <CoreComponents.PreContent
        isLoading={isLoading}
        isEmpty={isEmpty}
        empty={<JobViewMessagesEmpty />}
      >
        <CoreModules.MessageList 
          messages={messages} 
          sx={{ m: -3 }}
        />
      </CoreComponents.PreContent>
    </Box>
  )
};
export default JobViewMessages;
