import { DialogHeader } from "@build-buddy/core";
import { Dialog } from "@mui/material";

import ServiceAreaFormDialogContent from "./ServiceAreaFormDialogContent";

interface ServiceAreaFormDialogProps {
  serviceArea?: any;
  states?: Array<string>;
  title: string;
  isLoading?: boolean;
  isSaving?: boolean;
  onClose: () => void;
  onSubmit: (selectedServiceAreas: Array<string>, deletedServiceAreas: Array<string>) => void;
}

const ServiceAreaFormDialog = (props: ServiceAreaFormDialogProps) => {
  const {
    title,
    isLoading,
    isSaving,
    onClose,
    onSubmit,
    serviceArea,
    states
  } = props;

  return (
    <Dialog open={true}>
      <DialogHeader
        title={title}
        onClose={onClose}
      />
      <ServiceAreaFormDialogContent
        serviceArea={serviceArea}
        states={states}
        isLoading={isLoading}
        isSaving={isSaving}
        onClose={onClose}
        onSubmit={onSubmit}
      />
    </Dialog>
  )
}

export default ServiceAreaFormDialog;