import { CoreState, CoreComponents } from "@build-buddy/core";
import { Box, Button } from "@mui/material";
import { useJobViewParams } from "modules/company/job/job-view/_hooks";

type JobViewQualityAssuranceDefectsActionsRejectedProps = {
  defect: CoreState.Defect.Defect;
}
// this is declared due to the rule of hooks, since its a chain, if any hooks gets rendered and the number changes, it causes a violation
const JobViewQualityAssuranceDefectsActionsRejected = (props: JobViewQualityAssuranceDefectsActionsRejectedProps) => {
  const { defect } = props;
  if (!defect) return null;
  if (defect.status !== CoreState.Defect.DefectStatus.Rejected) return null;
  return <InnerViewQualityAssuranceDefectsActionsRejected defect={defect} />
}
const InnerViewQualityAssuranceDefectsActionsRejected = (props: JobViewQualityAssuranceDefectsActionsRejectedProps) => {
  const { defect } = props;

  // params
  const { projectId, stageId, taskId } = useJobViewParams();

  // mutation
  const [updateTaskDefectAction, updateTaskDefectQuery] = CoreState.Defect.updateDefect.useMutation();

  // locals
  const isSaving = updateTaskDefectQuery.isLoading;

  // event handlers
  const handleAccept = (e: any) => {
    e.stopPropagation();
    updateTaskDefectAction({
      projectId,
      stageId,
      taskId,
      defectId: defect.id,
      defect: {
        status: CoreState.Defect.DefectStatus.InReview,
      },
    })
  }

  return (
    <>
      <CoreComponents.Loader show={isSaving} />
      <Box sx={{ textAlign: "right" }} >
        <Button variant="contained" onClick={handleAccept}> Request Review </Button>
      </Box>
    </>
  )
}
export default JobViewQualityAssuranceDefectsActionsRejected;