import { CoreComponents, CoreState } from "@build-buddy/core";
import {
  AddCircle as AddCircleIcon
} from "@mui/icons-material";
import { Link, Stack, Typography } from "@mui/material";
import ServiceAreaAccordionList from "./ServiceAreaAccordionList";

interface ServiceAreaAccordionProps {
  serviceAreas?: Array<CoreState.Company.ServiceAreaTrimmed>;
  isLoading?: boolean;
  showAdd?: boolean;
  onAdd(): void;
}

const ServiceAreaAccordion = (props: ServiceAreaAccordionProps) => {
  // props
  const { serviceAreas, isLoading, showAdd, onAdd } = props;

  // locals
  const totalCount = serviceAreas?.reduce((acc, val) => acc += val.areasSelected, 0);

  return (
    <CoreComponents.Accordion
      icon={CoreComponents.ServiceAreaIcon}
      title="Service Areas"
      description="List the Service Areas you are available in, to ensure you receive relevant quotes."
    >
      {Boolean(serviceAreas) && (
        <Stack direction="row" sx={{ mb: 2 }}>
          <Typography variant="caption" sx={{ flex: 1 }}>
            {totalCount} service areas listed
          </Typography>
          {showAdd && (
            <Link
              sx={{
                display: "flex",
                alignItems: "center",
                textDecoration: "none",
                cursor: "pointer",
              }}
              onClick={() => onAdd()}
            >
              <AddCircleIcon fontSize="small" sx={{ mr: 0.5 }} />
              Add Service Area
            </Link>
          )}
        </Stack>
      )}
      <ServiceAreaAccordionList
        serviceAreas={serviceAreas}
        isLoading={isLoading}
        onAdd={onAdd}
      />
    </CoreComponents.Accordion>
  )
}

export default ServiceAreaAccordion;