import { CoreState } from "@build-buddy/core";
import { createSelector } from "@reduxjs/toolkit";
import { BuildBuddyProStateType } from "common/state/store";

interface SelectCompanyListByUserParams {
  userId: string;
}

const selectCurrentCompanyRequesterSecurityContext = createSelector(
  [
    (state: BuildBuddyProStateType, params: SelectCompanyListByUserParams) => CoreState.Company.getCompanyListByUser.select({ userId: params.userId })(state)
  ],
  (companies) => {
    if (!companies) return;
    return companies.data?.[0]?.requesterSecurityContext;
  }
)

export const selectIsBuildBuddySupport = createSelector(
  selectCurrentCompanyRequesterSecurityContext,
  (requesterSecurityContext) => {
    return (requesterSecurityContext?.isBuildBuddyAdmin || requesterSecurityContext?.isBuildBuddyHelpDesk)
  }
)