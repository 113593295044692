import { CoreComponents, CoreRouting, CoreState } from "@build-buddy/core";
import { Dialog, DialogContent } from "@mui/material";
import { useSelector } from "common/state/store";
import { useEffect } from "react";
import { useQueryJob } from "../_hooks";
import { selectJobQuoteCurrent } from "../_selectors";
import useJobView from "../useJobView";
import JobViewVariationForm from "./JobViewVariationForm";
import JobViewVariationsRouteDefinitions from "./JobViewVariationsRouteDefinitions";

const useVm = () => {
  const taskView = useJobView();
  const params = useQueryJob();
  const [addJobVariationAction, addJobVariationQuery] = CoreState.Variation.addTaskVariation.useMutation();
  const jobQuote = useSelector((s) => selectJobQuoteCurrent(s, params.jobParams));

  const addJobVariation = (values: any) => {
    if (!jobQuote?.id) return;

    addJobVariationAction({
      projectId: taskView.params.projectId,
      stageId: taskView.params.stageId,
      taskId: taskView.params.taskId,
      quoteId: jobQuote.id,
      taskVariation: {
        name: values.title,
        description: values.description,
        status: CoreState.Variation.TaskVariationStatus.Initial,
        cost: values.additionalCost,
        additionalDays: values.extraDays,
        specificationType: 'Variation'
      }
    })
  }

  return {
    params: taskView.params,
    addJobVariation,
    isSaving: addJobVariationQuery.isLoading,
    isSaved: addJobVariationQuery.isSuccess,
    error: (addJobVariationQuery?.error as { message: string })?.message
  }
}

const JobViewVariationAdd = () => {
  const vm = useVm();
  const routing = CoreRouting.useRouting();

  const handleClose = () => {
    const params = {
      pid: vm.params.projectId,
      sid: vm.params.stageId,
      qid: vm.params.quoteRequestId,
      tid: vm.params.taskId
    };
    routing.go(JobViewVariationsRouteDefinitions.JOB_VIEW_VARIATIONS, { params });
  }

  const handleSubmit = (values: any) => {
    vm.addJobVariation(values)
  }

  useEffect(() => {
    if (!vm.isSaved) return;
    handleClose();
  }, [vm.isSaved]);

  return (
    <>
      <Dialog open={true}>
        <CoreComponents.Loader show={vm.isSaving} />
        <CoreComponents.DialogHeader title={`Add Variation`} onClose={() => handleClose()} />
        <DialogContent>
          <JobViewVariationForm
            onSubmit={(values) => handleSubmit(values)}
            onClose={() => handleClose()}
            type={'Variation'}
          />
        </DialogContent>
      </Dialog>
    </>
  )
}
export default JobViewVariationAdd;