import { CoreState } from "@build-buddy/core";
import { createSelector } from "@reduxjs/toolkit";
import { CommonState } from "common";

type SelectJobParams = {
  projectId: string;
  stageId: string;
  taskId: string;
  quoteRequestId: string;
  companyId: string;
}
const selectRoot = createSelector(
  [(state: CoreState.RootState, params: SelectJobParams) => CommonState.Job.getJob.select(params)(state)],
  (jobQuery) => jobQuery
)

export const selectJobRequest = createSelector(
  selectRoot,
  (jobQuery) => CoreState.extractRequest(jobQuery)
);

export const selectJob = createSelector(
  selectRoot,
  (jobQuery): CommonState.Job.Job | undefined | null => jobQuery.data
)

export const selectJobStatus = createSelector(
  selectJob,
  (job) => job?.status
)

export const selectJobTask = createSelector(
  selectJob,
  (job) => job?.task
)

interface SelectJobQuoteParams {
  quoteId: string;
}
export const selectJobQuote = createSelector(
  [
    selectJob,
    (state, params, filter: SelectJobQuoteParams) => filter,
  ],
  (job, filter) => {
    return job?.quotes?.find(x => x.id === filter.quoteId)
  }
)

export const selectJobQuoteCurrent = createSelector(
  selectJob,
  (job) => {
    return job?.quotes?.find(x => x.status !== CoreState.Quote.QuoteStatus.Declined)
  }
)

export const selectTotalPaymentScheduleAmount = createSelector(
  selectJobQuoteCurrent,
  (job) => {
    return job?.paymentSchedule?.reduce((acc, ps) => acc + ps.amount, 0)
  }
)

export const selectJobQuoteOwnerBuilderName = createSelector(
  selectJob,
  (job) => job?.quoteRequest.owner
)

export const selectJobQuotePaymentSchedule = createSelector(
  selectJobQuoteCurrent,
  (jobQuote) => jobQuote?.paymentSchedule
)

const selectJobQuoteCompany = createSelector(
  selectJobQuoteCurrent,
  (jobQuote) => jobQuote?.company
)

export const selectJobQuoteCompanyId = createSelector(
  selectJobQuoteCompany,
  (jobQuoteCompany) => jobQuoteCompany?.id
)

export const selectJobQuoteRequest = createSelector(
  selectJob,
  (job) => job?.quoteRequest
)

export const selectJobQuoteRequestId = createSelector(
  selectJobQuoteRequest,
  (jobQuoteRequest) => jobQuoteRequest?.id,
)

export const selectJobQuoteRequestOwnerId = createSelector(
  selectJobQuoteRequest,
  (jobQuoteRequest) => jobQuoteRequest?.ownerId,
)

export const selectJobQuoteManageTaskChanged = createSelector(
  selectJobQuoteCurrent,
  (jobQuote) => {
    if (!jobQuote) return;
    return jobQuote.manageTasks.find(x => x.changed)
  }
)

export const selectJobStage = createSelector(
  selectJob,
  (job) => job?.task?.stage,
)