import { CoreState } from "@build-buddy/core";
import JobStatus from "../../models/JobStatus";

const transformJobStatusIsWip = (
  task: CoreState.Task.Task,
  taskQuoteRequest: CoreState.Quote.QuoteRequest,
  quotes?: Array<CoreState.Quote.Quote>
) => {
  if(!task.type?.includes(CoreState.Task.TaskType.Manage)) return;


  const kvp: any = {
    [CoreState.Task.TaskStatus.AwaitingToStart]: JobStatus.AwaitingToStart,
    [CoreState.Task.TaskStatus.InProgress]: JobStatus.InProgress,
    [CoreState.Task.TaskStatus.AwaitingReview]: JobStatus.InQA
  }
  return kvp[task.status];
}
export default transformJobStatusIsWip