import { Box, Skeleton } from "@mui/material";
import DashboardJobListItemTemplate from "./DashboardBrowseJobListTemplate";

const DashboardBrowseJobListSkeleton = () => {
  const arr = [...Array(5).keys()]

  return (
    <>
      {arr.map(x => (
        <DashboardJobListItemTemplate key={x}>
          <Box sx={{ gridArea: "action" }}>
            <Skeleton variant="rectangular" sx={{ ml: 1.25 }} width={20} />
          </Box>
          <Box sx={{ gridArea: "suburb" }}>
            <Skeleton variant="rectangular" width={100} />
          </Box>
          <Box sx={{ gridArea: "customer" }}>
            <Skeleton variant="circular" height={40} width={40} />
          </Box>
          <Box sx={{ gridArea: "task-name" }}>
            <Skeleton variant="rectangular" sx={{ mr: 3 }} />
          </Box>
          <Box sx={{ gridArea: "status" }}>
            <Skeleton variant="rectangular" width={100} />
          </Box>
          <Box sx={{ gridArea: "start-date" }}>
            <Skeleton variant="rectangular" width={75} />
          </Box>
          <Box sx={{ gridArea: "end-date" }}>
            <Skeleton variant="rectangular" width={75} />
          </Box>
        </DashboardJobListItemTemplate>
      ))}
    </>
  )
}
export default DashboardBrowseJobListSkeleton;