import { CoreComponents, CoreRouting, CoreState } from "@build-buddy/core";
import { Edit as EditIcon, MoreVert as MoreIcon, EditNote as ParagraphIcon } from "@mui/icons-material";
import { Box, Link, Stack, Typography } from "@mui/material";
import { useCompanyParams } from "modules/company/_hooks";
import { CompanyProfileTNCRouteDefinitions, CompanyProfileTNCRoutes } from "./CompanyProfileTNCRoutes";
import {
  AddCircle as AddCircleIcon,
} from "@mui/icons-material";
import { useSelector } from "common/state/store";

const CompanyProfileTNC = () => {
  const routing = CoreRouting.useRouting();

  // params
  const { companyId } = useCompanyParams();
  const getTermsAndConditionsByCompanyParams = { companyId };

  // queries
  const getTermsAndConditionsByCompanyQuery = CoreState.Company.getTermsAndConditionsByCompany.useQuery(getTermsAndConditionsByCompanyParams)

  const isLoading = getTermsAndConditionsByCompanyQuery.isLoading;
  const tnc = useSelector((s) => CoreState.Company.selectTermsAndConditionsByCompany(s, getTermsAndConditionsByCompanyParams));
  const hasTnc = useSelector((s) => CoreState.Company.selectIsCompanyHaveTermsAndConditions(s, getTermsAndConditionsByCompanyParams));

  const handleEdit = () => {
    routing.go(CompanyProfileTNCRouteDefinitions.COMPANY_PROFILE_TNC_EDIT);
  }

  const handleAdd = () => {
    routing.go(CompanyProfileTNCRouteDefinitions.COMPANY_PROFILE_TNC_ADD);
  }

  return (
    <Box sx={{ mb: 2 }}>
      <CoreComponents.Accordion
        icon={ParagraphIcon}
        title="Terms and Conditions"
        description="The terms and conditions for your services"
        actions={
          hasTnc && 
          <CoreComponents.MoreMenu
            icon={MoreIcon}
          >
            <CoreComponents.MoreMenuItem
              onClick={handleEdit}
              icon={EditIcon}
              text="Edit"
            />
          </CoreComponents.MoreMenu>
        }
      >
        {!hasTnc && (
          <Stack direction="row" sx={{ mb: 2 }}>
            <Typography variant="caption" sx={{ flex: 1 }}>
              No Terms and Conditions.
            </Typography>
            <Link
              sx={{
                display: "flex",
                alignItems: "center",
                textDecoration: "none",
                cursor: "pointer",
              }}
              onClick={handleAdd}
            >
              <AddCircleIcon fontSize="small" sx={{ mr: 0.5 }} />
              Add Terms and Conditions
            </Link>
          </Stack>
        )}
        <CoreComponents.PreContent
          isLoading={isLoading}
          isEmpty={!hasTnc}
          empty={<CoreComponents.Empty
            isProfile={true}
            title="No Terms and Conditions found."
            description="Add your Terms and Conditions here"
            sx={{ my: 4 }}
            onClick={handleAdd}
          />}
        >
          <span dangerouslySetInnerHTML={{ __html: tnc?.content || "" }} />
        </CoreComponents.PreContent>
      </CoreComponents.Accordion>
      <CompanyProfileTNCRoutes />
    </Box>
  )
}
export default CompanyProfileTNC;
