import { CoreComponents, CoreRouting, CoreState } from "@build-buddy/core";
import { Dialog } from "@mui/material";
import { useSelector } from "common/state/store";
import { useCompanyParams } from "modules/company/_hooks";
import { useEffect } from "react";
import { CompanyProfileRouteDefintions } from "../CompanyProfileRoutes";
import CompanyProfileTNCEditForm from "./CompanyProfileTNCForm";

function isEmptyContent(content: string) {
  return content.replace(/<\/?p>/g, '').trim() === '';
}

const CompanyProfileTNCEdit = () => {
  const routing = CoreRouting.useRouting();

  // params
  const { companyId } = useCompanyParams();
  const getTermsAndConditionsByCompanyParams = { companyId };
  
  // queries
  const getTermsAndConditionsByCompanyQuery = CoreState.Company.getTermsAndConditionsByCompany.useQuery(getTermsAndConditionsByCompanyParams)

  const isLoading = getTermsAndConditionsByCompanyQuery.isLoading;
  const tnc = useSelector((s) => CoreState.Company.selectTermsAndConditionsByCompany(s, getTermsAndConditionsByCompanyParams));

  const handleClose = () => {
    routing.go(CompanyProfileRouteDefintions.COMPANY_PROFILE);
  }

  // mutations
  const [updateTermsAndConditionsByCompanyAction, updateTermsAndConditionsByCompanyQuery] = CoreState.Company.updateTermsAndConditionsByCompany.useMutation();

  // locals
  const isSaving = Boolean(updateTermsAndConditionsByCompanyQuery.isLoading)
  const isSuccess = Boolean(updateTermsAndConditionsByCompanyQuery.isSuccess)

  const onSubmit= async(values: any) => {
    const tncValues: CoreState.Common.Subset<CoreState.Company.TermsAndConditions> = {
      id: values.termsAndConditions?.id,
      content: isEmptyContent(values.content) ? "" : values.content,
      version: tnc?.version
    }
    updateTermsAndConditionsByCompanyAction({ companyId, tnc: tncValues });
  }

  useEffect(() => {
    if (!isSuccess) return;
    routing.go(CompanyProfileRouteDefintions.COMPANY_PROFILE);
  }, [isSuccess]);

  return (
    <Dialog open={true} onClose={() => handleClose()}>
      <CoreComponents.Loader show={isSaving} />
      <CoreComponents.DialogHeader onClose={() => handleClose()} title="Edit terms and conditions" />
      <CoreComponents.PreContent
        isLoading={isLoading}
        isEmpty={false}
      >
        <CompanyProfileTNCEditForm 
          termsAndConditions={tnc}
          onSubmit={onSubmit}
        />
      </CoreComponents.PreContent>
    </Dialog>
  )
}
export default CompanyProfileTNCEdit;