import { useFormikContext, getIn } from "formik";
import { Box, Stack, useTheme } from "@mui/material";
import { DragIndicator as DragIndicatorIcon } from "@mui/icons-material";
import { DragDropContext, Droppable, Draggable } from "@hello-pangea/dnd";
import JobViewQuoteFormValues from "../JobViewQuoteFormValues";
import JobViewQuoteFormPaymentScheduleFormItem from "./JobViewQuoteFormPaymentScheduleFormItem";

const JobViewQuoteFormPaymentScheduleFormList = () => {
  const theme = useTheme();
  const formik = useFormikContext<JobViewQuoteFormValues>();

  const handleDelete = (index: number) => {
    // cant delete the last payment
    if (formik.values.paymentSchedule.length === 1) return;
    // duplicate array & remove at the index
    const ps = [...formik.values.paymentSchedule];
    ps.splice(index, 1);
    formik.setFieldValue("paymentSchedule", ps);
    // reset touched & error states
    const key = `paymentSchedule.[${index}].name`;
    formik.setFieldTouched(key, false);
    formik.setFieldError(key, "");
  }

  const handleDragEnd = (result: any) => {
    const items = reorder(
      formik.values.paymentSchedule,
      result.source.index,
      result.destination.index
    );

    const keys = ["name", "amount"];

    keys.forEach(k => {
      const err1 = getIn(formik.errors, `paymentSchedule.[${result.source.index}].${k}`);
      const err2 = getIn(formik.errors, `paymentSchedule.[${result.destination.index}].${k}`);
      const touched1 = getIn(formik.touched, `paymentSchedule.[${result.source.index}].${k}`);
      const touched2 = getIn(formik.touched, `paymentSchedule.[${result.destination.index}].${k}`);
      formik.setFieldError(`paymentSchedule.[${result.source.index}].${k}`, err2);
      formik.setFieldError(`paymentSchedule.[${result.destination.index}].${k}`, err1);
      formik.setFieldTouched(`paymentSchedule.[${result.source.index}].${k}`, Boolean(touched2));
      formik.setFieldTouched(`paymentSchedule.[${result.destination.index}].${k}`, Boolean(touched1));
    })
    formik.setFieldValue("paymentSchedule", items, false);
  }

  const reorder = (list: any, startIndex: any, endIndex: any) => {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);
    return result;
  };

  return (
    <>
      <DragDropContext onDragEnd={handleDragEnd}>
        <Droppable droppableId="droppable">
          {(provided) => (
            <Box
              {...provided.droppableProps}
              ref={provided.innerRef}
            >
              {formik.values.paymentSchedule.map((ps, idx: number) => (
                <Draggable key={idx} draggableId={`${idx}`} index={idx}>
                  {(provided) => (
                    <Box
                      ref={provided.innerRef}
                      {...provided.draggableProps}
                    >
                      <Stack
                        direction="row"
                        sx={{
                          mx: -3,
                          px: { xs: 0, md: 3 },
                          borderBottom: `dotted 1px ${theme.palette.grey[300]}`,
                          pb: { xs: 2, md: 0 },
                          pt: 2
                        }}
                      >
                        {
                          <Box
                            sx={{
                              flexBasis: 30,
                              visibility: ps.disabled ? "hidden" : "visible"
                            }}
                            {...provided.dragHandleProps}
                          >
                            <DragIndicatorIcon sx={{ position: "relative", top: 14 }} />
                          </Box>
                        }
                        <Box sx={{ flex: 1 }}>
                          <JobViewQuoteFormPaymentScheduleFormItem
                            key={idx}
                            name={`paymentSchedule.${idx}`}
                            onDelete={() => handleDelete(idx)}
                          />
                        </Box>
                      </Stack>
                    </Box>
                  )}
                </Draggable>
              ))}
              {provided.placeholder}
            </Box>
          )}
        </Droppable>
      </DragDropContext>
    </>
  )
}
export default JobViewQuoteFormPaymentScheduleFormList;