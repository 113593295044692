import { CoreState } from "@build-buddy/core";
import { createSelector } from "@reduxjs/toolkit";

type SelectJobSpecsParams = {
  quoteRequestId: string;
  companyId: string;
}

const selectRoot = createSelector(
  [(state: CoreState.RootState, params: SelectJobSpecsParams) => CoreState.Specification.getQuoteRequestSpecificationList.select(params)(state)],
  (jobSpecsQuery) => jobSpecsQuery
)

const selectJobSpecsRequest = createSelector(
  selectRoot,
  (jobSpecsQuery) => CoreState.extractRequest(jobSpecsQuery)
);

const selectJobSpecs = createSelector(
  selectRoot,
  (jobSpecsQuery) => jobSpecsQuery.data
)

const selectJobSpecsStandard = createSelector(
  selectJobSpecs,
  (jobSpecs) => jobSpecs?.filter((item) => item.isStandard)
);

const selectJobSpecsUser = createSelector(
  selectJobSpecs,
  (jobSpecs) => jobSpecs?.filter((item) => !item.isStandard)
)

const selectActiveJobSpecs = createSelector(
  selectJobSpecs,
  (jobSpecs) => {
    return jobSpecs?.filter((item) => item.specificationSubType === CoreState.Specification.TaskSpecificationSubTypes.Scope && !item.isDisabled)
  }
)

const selectIsJobSpecsModified = createSelector(
  [
    selectJobSpecsStandard,
    selectJobSpecsUser
  ],
  (jobSpecsStandard, jobSpecsUser) => {
    const isSOWModified = Boolean(jobSpecsStandard?.find(specs => specs.isDIY || specs.isDisabled) || jobSpecsUser?.length)
    return isSOWModified
  }
)

export {
  selectActiveJobSpecs, selectJobSpecs, selectJobSpecsRequest, selectJobSpecsStandard,
  selectJobSpecsUser, selectIsJobSpecsModified
};
