import { useRouteMatch } from "react-router";
import RouteDefinitions from "../../RouteDefinitions";
import Minimal from "./minimal/Minimal";
import Standard from "./standard/Standard";

interface PageTemplateProps {
  children: any;
  routeData?: any;
}
const PageTemplate = (props: PageTemplateProps) => {
  const minimalTemplateRoutes: Array<string> = [];

  Object.keys(RouteDefinitions.Onboarding).forEach((route) => {
    minimalTemplateRoutes.push(RouteDefinitions.Onboarding[route].path);
  });

  const isMinimal = useRouteMatch(minimalTemplateRoutes);

  // // use the minimal template
  if (isMinimal) return <Minimal>{props.children}</Minimal>;

  // otherwise use the standard template
  return (
    <Standard>
      {props.children}
    </Standard>
  );
};
export default PageTemplate;
