import { CoreHooks, CoreState } from "@build-buddy/core";
import { Box, Typography, useTheme } from "@mui/material";
import { useSelector } from "common/state/store";
import { useJobViewParams, useQueryJob } from "../_hooks";
import { selectJobQuoteCurrent } from "../_selectors";
import { selectJobViewQuoteListByStatus } from "./_selectors/selectJobViewQuoteList";
import JobViewQuoteListItem from "./JobViewQuoteListItem";
import JobViewQuoteListHeader from "./JobViewQuoteListHeader";

const JobViewQuotesList = () => {
  // hooks
  const { projectId, quoteRequestId, companyId } = useJobViewParams();
  const theme = useTheme();

  // params
  const quoteListByCompanyParams = { projectId, quoteRequestId, companyId };

  // queries
  const queryJob = useQueryJob();
  const quoteListByCompanyQuery = CoreState.Quote.getQuoteListByCompany.useQuery(quoteListByCompanyParams)

  // selectors
  const jobQuote = useSelector((s) => selectJobQuoteCurrent(s, queryJob.jobParams));
  const declinedQuotes = useSelector((s) => selectJobViewQuoteListByStatus(s, {
    ...quoteListByCompanyParams,
    status: CoreState.Quote.QuoteStatus.Declined
  }))

  // locals
  const isLoading = quoteListByCompanyQuery.isLoading;

  if (isLoading) return <> Loading </>;

  return (
    <Box
      sx={{
        overflowX: { xs: 'auto', md: 'hidden' },
        mx: -3,
      }}
    >
      <JobViewQuoteListHeader />

      {jobQuote &&
        <JobViewQuoteListItem quote={jobQuote} />
      }

      <Box
        sx={{
          borderBottom: `solid 1px ${theme.palette.grey[300]}`,
          mt: 3,
          mb: 1,
          px: 3,
          pb: 1
        }}
      >
        <Typography variant="subtitle2"> Quote History </Typography>
      </Box>
      {declinedQuotes?.map((quote, idx) =>
        <JobViewQuoteListItem
          key={idx}
          quote={quote}
        />
      )}
    </Box>
  )
}
export default JobViewQuotesList;