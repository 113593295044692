
import { CoreRouting, CoreState } from "@build-buddy/core";
import { Box } from "@mui/material";
import { useSelector } from "common/state/store";
import { useCompanyParams } from "modules/company/_hooks";
import ServiceAreaRouteDefinitions from "modules/onboarding/components/setup-service-area/ServiceAreaRouteDefinitions";
import CompanyProfileServiceAreasRoutes from "./CompanyProfileServiceAreasRoutes";
import ServiceAreaAccordion from "./service-area-accordion/ServiceAreaAccordion";

const CompanyProfileServiceAreas = () => {
  // hooks 
  const routing = CoreRouting.useRouting();
  const { companyId } = useCompanyParams();

  // queries
  const getServiceAreaByCompany = CoreState.Company.getServiceAreaByCompany.useQuery({ companyId });

  // selectors
  const selectedServiceAreas = useSelector((state) => CoreState.Company.selectServiceAreaSelectedByCompany(state, { companyId }));
  const areAllServiceAreasSelected = useSelector((state) => CoreState.Company.selectAreAllServiceAreasSelectedByCompany(state, { companyId }));
  
  // locals
  const isLoading = getServiceAreaByCompany.isLoading;

  // events
  const handleAdd = () => {
    routing.go(ServiceAreaRouteDefinitions.SERVICE_AREA_ADD);
  }

  return (
    <>
      <Box sx={{ mb: 2 }}>
        <ServiceAreaAccordion
          isLoading={isLoading}
          serviceAreas={selectedServiceAreas}
          onAdd={handleAdd}
          showAdd={!areAllServiceAreasSelected}
        />
      </Box>
      <CompanyProfileServiceAreasRoutes />
    </>
  );
};
export default CompanyProfileServiceAreas;