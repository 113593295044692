import { createReducer, isAnyOf } from "@reduxjs/toolkit";
import { CommonState } from "common";
import * as AppActions from "../app/actions";
import { CoreState } from "@build-buddy/core";

export interface AppContext {
  company: {
    id?: string;
  };
  isLoading: boolean;
}

const initialState: AppContext = {
  company: {},
  isLoading: true
};

const AppReducer = createReducer(initialState,
  (builder) =>
    builder
      .addCase(CommonState.App.setCurrentCompany, (state, action) => {
        const companyId = action.payload?.id;
        if (!companyId) return state;
        state.company.id = companyId;
      })
      .addMatcher(isAnyOf(AppActions.initialise.pending), (state, _) => {
        state.isLoading = true;
      })
      .addMatcher(isAnyOf(AppActions.initialise.fulfilled), (state, action: any) => {
        const companyId = action.payload?.companyId;
        state.company.id = companyId;
        state.isLoading = false;
      })
      .addMatcher(isAnyOf(CoreState.Company.addCompany.matchFulfilled), (state, action) => {
        const companyId = action?.payload?.result.id;
        if (!companyId) return state;
        state.company.id = companyId;
      })
)

export default AppReducer;
