import { CoreState } from "@build-buddy/core";

interface addJobViewQualityAssuranceDefectsNoteItemParams {
  projectId: string;
  stageId: string;
  taskId: string;
  defectId: string;
  noteThread: CoreState.Common.Subset<CoreState.NoteThread.NoteThread>;
  docs: Array<CoreState.Common.Subset<CoreState.File.Doc>>;
  toUserId: string;
}
const addJobViewQualityAssuranceDefectsNoteItem = CoreState.ApiSlice.injectEndpoints({
  endpoints: (builder) => ({
    addJobViewQualityAssuranceDefectsNoteItem: builder.mutation<any | undefined, addJobViewQualityAssuranceDefectsNoteItemParams>({
      queryFn: async (params, _queryApi, _extraOptions, fetchWithBaseQuery) => {
        const { projectId, stageId, taskId, defectId, noteThread, docs, toUserId } = params;
        // add note thread
        const noteThreadItemPayload = {
          projectId,
          stageId,
          taskId,
          defectId: defectId,
          noteThread,
          toUserId
        }

        const addNoteThreadItemByDefectResponse = await _queryApi.dispatch(CoreState.NoteThread.addNoteThreadItemByDefect.initiate(noteThreadItemPayload));
        if ('error' in addNoteThreadItemByDefectResponse) return CoreState.Common.transformErrorResponse();
        if (!addNoteThreadItemByDefectResponse.data?.id) return CoreState.Common.transformErrorResponse();

        if (docs.length) {

          // add note thread documents
          const uploadDocListPayload = {
            noteThreadId: addNoteThreadItemByDefectResponse.data.id,
            docs
          }

          const uploadDocListByNoteThreadResponse = await _queryApi.dispatch(CoreState.File.uploadDocListByNoteThread.initiate(uploadDocListPayload));
        }
        return CoreState.Common.generateSuccessResponse();
      },
    }),
  }),
});

export default addJobViewQualityAssuranceDefectsNoteItem.endpoints.addJobViewQualityAssuranceDefectsNoteItem;
