import { CoreState } from "@build-buddy/core";
import Job from "../models/Job";
import transformJobStatus from "./transformJobStatus/transformJobStatus";

const toModel = (
  task: CoreState.Task.Task | undefined,
  quoteRequest: CoreState.Quote.QuoteRequest | undefined,
  quotes: Array<CoreState.Quote.Quote> | undefined
): Job | undefined => {
  if (!task || !quoteRequest) return;

  const status = transformJobStatus.toModel(task, quoteRequest, quotes);

  const rtn = {
    task,
    quoteRequest,
    quotes,
    status
  }
  return rtn;
}

const transformJob = { toModel };

export default transformJob;