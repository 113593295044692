import { CoreRouting, CoreState } from "@build-buddy/core";
import { CommonState } from "common";
import { useSelector } from "common/state/store";
import { useEffect } from "react";

import ServiceAreaFormDialog from "./setup-service-area-form-dialog/ServiceAreaFormDialog";

const ServiceAreaAdd = () => {
  // hooks
  const routing = CoreRouting.useRouting();

  // selectors
  const companyId = useSelector(CommonState.App.selectCurrentCompanyId);
  const unSelectedStates = useSelector((s) => CoreState.Company.selectUnselectedServiceAreaStatesByCompany(s, { companyId }));
  const company = useSelector((s) => CoreState.Company.selectCompany(s, { companyId }));

  //params
  const params = { companyId };

  // queries
  const getCompanyQuery = CoreState.Company.getCompany.useQuery(params, { skip: !companyId });
  const getServiceAreaByCompanyQuery = CoreState.Company.getServiceAreaByCompany.useQuery(params);

  // mutations
  const [addServiceAreaAction, addServiceAreaQuery] = CoreState.Company.addServiceAreaByCompany.useMutation();
  const [updateCompanyAction, updateCompanyQuery] = CoreState.Company.updateCompany.useMutation();

  // locals
  const isSaving = addServiceAreaQuery.isLoading;
  const isLoading = getServiceAreaByCompanyQuery.isLoading || getCompanyQuery.isLoading;

  //handlers
  const handleSubmit = async (selectedServiceAreas: Array<string>, deletedServiceAreas: Array<string>) => {
    if (!companyId) return;
    addServiceAreaAction({ companyId, selectedServiceAreas });

    if (company?.flags?.serviceAreaReviewed) return;

    updateCompanyAction({
      companyId,
      company: {
        ...company,
        flags: {
          ...company?.flags,
          serviceAreaReviewed: true,
        }
      }
    });
  }

  const handleClose = () => routing.back();

  useEffect(() => {
    if (!addServiceAreaQuery.isSuccess) return;
    handleClose();
  }, [addServiceAreaQuery.isSuccess]);

  return (
    <ServiceAreaFormDialog
      title="Add Service Area"
      isLoading={isLoading}
      isSaving={isSaving}
      onClose={handleClose}
      onSubmit={handleSubmit}
      states={unSelectedStates}
    />
  );
};

export default ServiceAreaAdd;