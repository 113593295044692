import { CoreComponents } from "@build-buddy/core";
import { Add as AddIcon, Delete as DeleteIcon } from "@mui/icons-material";
import { Box, Button, Fade, IconButton, Stack, Typography, useTheme } from "@mui/material";
import { getIn, useFormikContext } from "formik";
import { useEffect, useState } from "react";
import JobViewQuoteFormValues from "../JobViewQuoteFormValues";

const JobViewQuoteFormSpecialConditions = () => {
  const theme = useTheme();
  const formik = useFormikContext<JobViewQuoteFormValues>();
  const [isEdit, setIsEdit] = useState(false);

  const onDeleteClick = () => {
    formik.setFieldValue("specialConditions", "<p></p>");
    setIsEdit(false)
  }

  useEffect(() => {
    const pElement = new DOMParser().parseFromString(getIn(formik.values, "specialConditions") as string, 'text/html').querySelector('p');
    const isTandCContentExist = Boolean(pElement && (pElement.textContent?.trim() || pElement.children.length > 0));
    setIsEdit(isTandCContentExist)
  }, [])

  return (
    <Box sx={{ mb: 2 }} >
      <Stack direction="row" alignItems="center" sx={{ height: "43px" }}>
        <Typography sx={{ fontWeight: "bold", fontSize: "12px", flex: 1, position: "relative", top: 2 }}>
          Quote specific conditions and exclusions
        </Typography>
        {isEdit &&
          <IconButton
            onClick={onDeleteClick}
            sx={{ fontSize: "16px", mt: 0.75 }}
            edge="end"
          >
            <DeleteIcon />
          </IconButton>
        }
      </Stack>
      {!isEdit &&
        <Button
          variant="none"
          onClick={() => setIsEdit(true)}
          sx={{
            mx: -3,
            px: 3,
            py: 2,
            width: `calc(100% + ${theme.spacing(6)})`,
            color: theme.palette.primary.main,
            justifyContent: "flex-start",
            borderRadius: 0,
          }}
        >
          <AddIcon sx={{ mr: 1.25 }} />
          Add special conditions
        </Button>
      }
      {isEdit &&
        <Fade in={true}>
          <Box sx={{ mt: 1 }}>
            <CoreComponents.FormikHtmlEditor
              name="specialConditions"
            />
          </Box>
        </Fade>
      }
    </Box>
  )
}
export default JobViewQuoteFormSpecialConditions;