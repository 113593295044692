import { CoreModules } from "@build-buddy/core";
import { Box, Grid, Stack } from "@mui/material";
import { CommonState } from "common";
import { useSelector } from "common/state/store";
import DashboardBrowse from "./dashboard-browse/DashboardBrowse";
import DashboardCompany from "./dashboard-company/DashboardCompany";
import DashboardProfileCheck from "./dashboard-profile-check/DashboardProfileCheck";

const Dashboard = () => {
  const currentCompanyId = useSelector(CommonState.App.selectCurrentCompanyId);
  const isCurrentUserOwner = useSelector(CommonState.App.selectIsCurrentUserOwner);

  return (
    <Stack direction="column" gap={2}>
      <DashboardProfileCheck />
      <Grid container spacing={2}>
        <Grid item xs={12} md={12} lg={9}>
          <Box>
            <DashboardBrowse />
          </Box>
        </Grid>
        <Grid item xs={12} md={12} lg={3}>
          <Box sx={{ mb: 2 }}>
            <DashboardCompany />
          </Box>
          {isCurrentUserOwner &&
            <Box sx={{ mb: 2 }}>
              <CoreModules.Wallet.WalletDetails
                companyId={currentCompanyId}
              />
            </Box>
          }
          <Box sx={{ mt: 2, mb: 2 }}>
            <CoreModules.MessagesSummary />
          </Box>
        </Grid>
      </Grid>
    </Stack>
  );
};
export default Dashboard;
