import { CoreState } from "@build-buddy/core";
import JobStatus from "../../models/JobStatus";
import transformJobStatusChain from "./transformJobStatusChain";
import transformJobStatusIsAccepted from "./transformJobStatusIsAccepted";
import transformJobStatusIsClosed from "./transformJobStatusIsClosed";
import transformJobStatusIsFinal from "./transformJobStatusIsFinal";
import transformJobStatusIsNew from "./transformJobStatusIsNew";
import transformJobStatusIsQA from "./transformJobStatusIsQA";
import transformJobStatusIsQuoting from "./transformJobStatusIsQuoting";
import transformJobStatusIsWip from "./transformJobStatusIsWip";
import transformJobStatusIsWithOwner from "./transformJobStatusIsWithOwner";
import transformJobStatusIsAwaitingConstruction from "./trnsformJobStatusIsAwaitingConstruction";

const toModel = (
  task: CoreState.Task.Task,
  taskQuoteRequest: CoreState.Quote.QuoteRequest,
  quotes?: Array<CoreState.Quote.Quote>
) => {
  const status = transformJobStatusChain(task, taskQuoteRequest, quotes)
    .add(transformJobStatusIsClosed)
    .add(transformJobStatusIsNew)
    .add(transformJobStatusIsQuoting)
    .add(transformJobStatusIsAccepted)
    .add(transformJobStatusIsWip)
    .add(transformJobStatusIsQA)
    .add(transformJobStatusIsWithOwner)
    .add(transformJobStatusIsFinal)
    .add(transformJobStatusIsAwaitingConstruction)
    .execute();

  return status || JobStatus.NA;
}

const transformJobStatus = { toModel };

export default transformJobStatus;