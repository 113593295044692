import { CoreComponents, CoreModules, CoreRouting, CoreState } from "@build-buddy/core";
import { useSelector } from "common/state/store";
import { useJobViewParams } from "../../_hooks";
import JobViewQualityAssuranceDefectsDotProgress from "../_common/job-view-quality-assurance-defects-dot-progress/JobViewQualityAssuranceDefectsDotProgress";
import JobViewQualityAssuranceRouteDefinitions from "../JobViewQualityAssuranceRouteDefinitions";
import JobViewQualityAssuranceDefectsActions from "./_common/job-view-quality-assurance-defects-actions/JobViewQualityAssuranceDefectsActions";

const JobViewQualityAssuranceDefects = () => {
  const routing = CoreRouting.useRouting();
  const { projectId, stageId, taskId } = useJobViewParams();
  const getDefectListByTaskParams = { projectId, stageId, taskId };
  const taskParams = { projectId, stageId, taskId };
 
  // queries
  const getDefectListByTaskQuery = CoreState.Defect.getDefectListByTask.useQuery(getDefectListByTaskParams);
  const getTaskListByParentQuery = CoreState.Task.getTaskListByParent.useQuery(taskParams)

  // locals
  const isLoading = getDefectListByTaskQuery.isLoading;

  // selectors 
  const defects = useSelector((s) => CoreState.Defect.selectDefectListByTask(s, getDefectListByTaskParams));
  const isTaskWorkInProgress = useSelector((s)=> CoreState.Task.selectTaskListByParentIsWorkInProgress(s, taskParams ))

  const handleClick = (defect: CoreState.Defect.Defect) => {
    const params = { did: defect.id }
    routing.go(JobViewQualityAssuranceRouteDefinitions.JOB_VIEW_QUALITY_ASSURANCE_DEFECTS_VIEW, { params });
  }
  
  if(!isTaskWorkInProgress) return null;

  return (
    <CoreComponents.CollapseHeader title="Defects" open={true} sx={{ mb: 2 }}>
      <CoreModules.Task.TaskView.TaskViewDefects
        defects={defects}
        isLoading={isLoading}
        dotProgress={JobViewQualityAssuranceDefectsDotProgress}
        actions={JobViewQualityAssuranceDefectsActions}
        onDefectClick={(d) => handleClick(d)}
      />
    </CoreComponents.CollapseHeader>
  )
}
export default JobViewQualityAssuranceDefects;