import { useFormik } from "formik";
import { format, add } from 'date-fns';
import { FormHelperText, Grid, InputAdornment, InputLabel, OutlinedInput, useTheme } from "@mui/material";
import { CoreComponents, CoreState } from "@build-buddy/core";
import JobViewVariationFormValidation from "./JobViewVariationFormValidation";


const customInputStyles = {
  '& input[type=number]': {
    MozAppearance: 'textfield'
  },
  '& input[type=number]::-webkit-outer-spin-button': {
    WebkitAppearance: 'none',
    margin: 0
  },
  '& input[type=number]::-webkit-inner-spin-button': {
    WebkitAppearance: 'none',
    margin: 0
  },
  '.MuiOutlinedInput-notchedOutline': {
    borderTopRightRadius: 0,
    borderBottomRightRadius: 0
  }
}

type JobViewVariationFormProps = {
  values?: CoreState.Variation.TaskVariation;
  onSubmit(values: any): void;
  onClose(): void;
  type?: string;
}
const JobViewVariationForm = (props: JobViewVariationFormProps) => {
  const theme = useTheme();
  const formik = useFormik({
    initialValues: {
      title: props?.values?.name || '',
      extraDays: props?.values?.additionalDays || 0,
      additionalCost: props?.values?.cost || 0,
      description: props?.values?.description || '',
    },
    validationSchema: JobViewVariationFormValidation,
    enableReinitialize: true,
    validateOnBlur: false,
    onSubmit: (values) => {
      props.onSubmit(values)
    }
  })


  return (
    <>
      <Grid container rowSpacing={1}   >
        <CoreComponents.Textbox
          fullWidth
          label="Title*"
          name="title"
          value={formik.values.title}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          error={Boolean(formik.touched.title && formik.errors.title) && formik.errors.title}
        />
        <Grid
          item
          xs={12}
          sx={{
            display: 'flex',
            flexDirection: { xs: 'column', md: 'row' },
            alignItems: { md: 'center' },
            gap: 2
          }}
        >
          <Grid item xs={12} md={6} >
            <InputLabel
              shrink
              sx={{
                fontWeight: "bold",
                textTransform: 'capitalize',
                color: `${Boolean(formik.touched.extraDays && formik.errors.extraDays) && theme.palette.error.main}`
              }}
            >
              Extra Days Needed
            </InputLabel>
            <OutlinedInput
              type="number"
              fullWidth
              name="extraDays"
              value={formik.values.extraDays}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              endAdornment={
                <InputAdornment position="end" >
                  Est. End Date: {format(add(Date.now(), { days: formik.values.extraDays }), "dd MMM yyyy")}
                </InputAdornment>
              }
              sx={customInputStyles}
              error={Boolean(formik.touched.extraDays && formik.errors.extraDays)}
            />
            {Boolean(formik.touched.extraDays && formik.errors.extraDays) &&
              <FormHelperText
                sx={{ color: `${theme.palette.error.main}` }}
              >
                {formik.errors.extraDays}
              </FormHelperText>
            }
          </Grid>
          <Grid item xs={12} md={6}>
            <InputLabel
              shrink
              sx={{
                fontWeight: "bold",
                textTransform: 'capitalize',
                color: `${Boolean(formik.touched.additionalCost && formik.errors.additionalCost) && theme.palette.error.main}`
              }}
            >
              Additional Cost
            </InputLabel>
            <OutlinedInput
              type="number"
              fullWidth
              name="additionalCost"
              value={formik.values.additionalCost}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              startAdornment={<InputAdornment position="start">$</InputAdornment>}
              sx={customInputStyles}
              error={Boolean(formik.touched.additionalCost && formik.errors.additionalCost)}
            />
            {Boolean(formik.touched.additionalCost && formik.errors.additionalCost) &&
              <FormHelperText
                sx={{ color: `${theme.palette.error.main}` }}
              >
                {formik.errors.additionalCost}
              </FormHelperText>
            }
          </Grid>
        </Grid>
        <CoreComponents.Textbox
          fullWidth
          multiline
          label="Description (Optional)"
          name="description"
          value={formik.values.description}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          error={Boolean(formik.touched.description && formik.errors.description) && formik.errors.description}
          sx={{ mt: 2 }}
        />
        <CoreComponents.Actions
          sx={{ mt: 2 }}
          submitText="Save"
          onCancelClick={() => props.onClose()}
          onSubmitClick={() => formik.handleSubmit()}
        />
      </Grid>
    </>
  )

}
export default JobViewVariationForm;