import { CoreUtils } from "@build-buddy/core";
import { LocationOn as LocationOnIcon } from "@mui/icons-material";
import { Avatar, Box, Stack, Typography, useTheme } from "@mui/material";
import { useSelector } from "common/state/store";
import { useJobViewParams } from "../../_hooks";
import { selectJob } from "../../_selectors";

const JobViewQuoteFormDetails = () => {
  // hooks
  const theme = useTheme();

  // params
  const { projectId, stageId, taskId, quoteRequestId, companyId } = useJobViewParams();
  const jobParams = { projectId, stageId, taskId, quoteRequestId, companyId };

  // selectors
  const job = useSelector((s) => selectJob(s, jobParams));

  return (
    <Stack direction="row" sx={{ mb: 3 }}>
      <Stack direction="row" sx={{ flex: 1 }}>
        <Avatar
          sx={{
            bgcolor: `${theme.palette.primary.main}`,
            color: `${theme.palette.primary.contrastText}`,
            mr: 2,
            textTransform: "uppercase"
          }}
        >
          {CoreUtils.Formatter.getInitials(job?.quoteRequest?.owner || "")}
        </Avatar>
        <Box>
          <Typography variant="subtitle2"> Owner </Typography>
          <Typography variant="body1">{job?.quoteRequest.owner} </Typography>
        </Box>
      </Stack>
      <Stack direction="row" alignItems="center" sx={{ flex: 1 }}>
        <LocationOnIcon sx={{ fontSize: "32px" }} />
        <Box>
          <Typography variant="subtitle2"> Location </Typography>
          <Typography variant="body1"> {job?.quoteRequest.address?.suburb} </Typography>
        </Box>
      </Stack>
    </Stack>
  )
}
export default JobViewQuoteFormDetails;