import { CommonState } from "common";
import { useSelector } from "common/state/store";
import { useParams } from "react-router";

const useJobView = () => {
  const qs = useParams<any>();
  const params = {
    projectId: qs.pid,
    stageId: qs.sid,
    taskId: qs.tid,
    quoteRequestId: qs.qid,
    variationId: qs.variationId
  }
  const currentCompanyId = useSelector(CommonState.App.selectCurrentCompanyId);

  const jobQuery = CommonState.Job.getJob.useQuery({
    companyId: currentCompanyId as string,
    projectId: qs.pid,
    stageId: qs.sid,
    taskId: qs.tid,
    quoteRequestId: qs.qid
  }, { refetchOnFocus: true, skip:!currentCompanyId });

  return {
    params,
    isLoading: jobQuery.isLoading,
    isFetching: jobQuery.isFetching,
    job: jobQuery?.data
  }

}
export default useJobView;