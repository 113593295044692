import { Box, Typography, useTheme } from "@mui/material";
import JobViewQuoteFormPaymentScheduleForm from "./JobViewQuoteFormPaymentScheduleForm";
import JobViewQuoteFormPaymentScheduleHeader from "./JobViewQuoteFormPaymentScheduleHeader";

const JobViewQuoteFormPaymentSchedule = () => {
  const theme = useTheme();
  return (
    <Box
      sx={{
        mx: -3,
        px: {
          xs: 1,
          sm: 2
        },
        py: 2,
        background: theme.palette.grey[100],
        mb: 2,
        overflowX: {
          xs: 'hidden',
        },
      }}
    >
      <Box
        sx={{
          background: theme.palette.common.white,
          p: 3,
          pb: 0,
          borderRadius: 5,
        }}
      >
        <Typography
          variant="h3"
          sx={{
            mb: { xs: 1, md: 3 },
            borderBottom: { xs: `1px solid ${theme.palette.grey[300]}`, md: 'none' },
            mx: -3,
            px: 2,
            pb: 2
          }}
        >
          Payment Schedule
        </Typography>
        <JobViewQuoteFormPaymentScheduleHeader />
        <JobViewQuoteFormPaymentScheduleForm />
      </Box>
    </Box>
  )
}

export default JobViewQuoteFormPaymentSchedule;