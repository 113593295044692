import { CoreRouting } from "@build-buddy/core";
import * as CoreComponents from "@build-buddy/core/dist/components";
import * as CoreState from "@build-buddy/core/dist/state";
import { ServiceAreaType } from "@build-buddy/core/dist/state/common/dtos/profile-management.generated";
import {
  Delete as DeleteIcon,
  Edit as EditIcon,
  MoreVert as MoreVertIcon,
} from "@mui/icons-material";
import { Box, Stack, Typography } from "@mui/material";
import { CommonState } from "common";
import { useSelector } from "common/state/store";
import ServiceAreaRouteDefinitions from "modules/onboarding/components/setup-service-area/ServiceAreaRouteDefinitions";
import ServiceAreaTag from "modules/onboarding/components/setup-service-area/ServiceAreaTag";
import { useState } from "react";

interface ServiceAreaAccordionListItemProps {
  serviceAreaTrimmed: CoreState.Company.ServiceAreaTrimmed;
}

const ServiceAreaAccordionListItem = (props: ServiceAreaAccordionListItemProps) => {
  const { serviceAreaTrimmed } = props;

  const routing = CoreRouting.useRouting();

  const [remove, setRemove] = useState(false);

  // selectors
  const companyId = useSelector(CommonState.App.selectCurrentCompanyId);
  const serviceArea = useSelector((state) => CoreState.Company.selectServiceAreaByCompany(state, { companyId }));

  // mutations
  const [deleteServiceAreaByCompanyAction, deleteServiceAreaByCompanyQuery] = CoreState.Company.deleteServiceAreaByCompany.useMutation();

  // handlers
  const handleDelete = (id: string) => {
    if (!serviceArea || !companyId) return;

    let deletedServiceAreas: Array<string> = [];
    let stack = [...serviceArea.children ?? []];

    while (stack.length) {
      const child = stack.pop();

      if (child?.type === ServiceAreaType.ServiceArea) {
        deletedServiceAreas.push(child.id);
      }
      if ((child?.id === id || child?.type === ServiceAreaType.ServiceAreaRegion) && child?.children) {
        stack.push(...child.children);
      }
    }
    deleteServiceAreaByCompanyAction({ companyId, deletedServiceAreas });
  }

  const handleEdit = (id: string) => {
    routing.go(ServiceAreaRouteDefinitions.SERVICE_AREA_EDIT, { params: { said: id } })
  };


  const handleConfirmDelete = (id: string) => {
    handleDelete(id);
    setRemove(false);
  };

  const handleConfirmCancel = () => {
    setRemove(false);
  };

  return (
    <>
      <CoreComponents.Confirmation
        open={remove}
        title="Confirm delete service area"
        text="Are you sure you want to delete this service area?"
        onConfirm={() => handleConfirmDelete(serviceAreaTrimmed.id)}
        onCancel={handleConfirmCancel}
        onClose={handleConfirmCancel}
      />
      <Stack
        direction="row"
        alignItems="center"
        sx={{
          position: "relative",
          py: 2,
          borderBottom: (theme) => `dashed 1px ${theme.palette.divider}`,
          "&:last-of-type": {
            borderBottom: "none",
            pb: 0,
          },
        }}
      >
        <CoreComponents.Loader show={deleteServiceAreaByCompanyQuery.isLoading} />
        <Box sx={{ flex: 1 }}>
          <Stack direction="row" alignItems="center" spacing={2}>
            <Typography variant="h5">
              {serviceAreaTrimmed.name}
            </Typography>
          </Stack>
          <ServiceAreaTag numSelected={serviceAreaTrimmed.areasSelected} />
        </Box>
        <CoreComponents.MoreMenu icon={MoreVertIcon}>
          <CoreComponents.MoreMenuItem
            text="Edit"
            icon={EditIcon}
            onClick={() => handleEdit(serviceAreaTrimmed.id)}
            color="neutral"
          />
          <CoreComponents.MoreMenuItem
            text="Delete"
            icon={DeleteIcon}
            onClick={() => handleDelete(serviceAreaTrimmed.id)}
            color="neutral"
          />
        </CoreComponents.MoreMenu>
      </Stack>
    </>
  );
};
export default ServiceAreaAccordionListItem;
