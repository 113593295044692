import { CoreModules, CoreRouting } from "@build-buddy/core";
import { Route, useRouteMatch } from "react-router";
import { JobRouteDefinitions } from "../JobRoutes";
import JobViewQualityAssuranceRouteDefinitions from "./job-view-quality-assurance/JobViewQualityAssuranceRouteDefinitions";

const JobSubView = () => {
  const routing = CoreRouting.useRouting();

  const match = useRouteMatch([
    JobViewQualityAssuranceRouteDefinitions.JOB_VIEW_QUALITY_ASSURANCE_DEFECTS_VIEW.path
  ])

  const open = Boolean(match);

  if (!open) return null;

  return (
    <CoreModules.Task.TaskView.TaskViewSubView
      open={open}
      onClose={() => {
        routing.go(JobRouteDefinitions.JOB_VIEW_QUALITY_ASSURANCE);
      }}
    >
      <Route 
        exact
        component={JobViewQualityAssuranceRouteDefinitions.JOB_VIEW_QUALITY_ASSURANCE_DEFECTS_VIEW.component}
        path={JobViewQualityAssuranceRouteDefinitions.JOB_VIEW_QUALITY_ASSURANCE_DEFECTS_VIEW.path}
      />
    </CoreModules.Task.TaskView.TaskViewSubView>
  )
}
export default JobSubView