import { CoreModules } from "@build-buddy/core";
import InitialDetails from "./components/initial-details/InitialDetails";
import MoreCompanyDetails from "./components/more-company-details/MoreCompanyDetails";
import SetupServiceArea from "./components/setup-service-area/SetupServiceArea";

const OnboardingRoutesDefinitions = {
  ...CoreModules.Onboarding.OnboardingRoutes,
  INITIAL_DETAILS: {
    name: "Your Details",
    path: "/onboarding/initial-details",
    component: InitialDetails,
  },
  MORE_COMPANY_DETAILS: {
    name: "Setup your business",
    path: "/onboarding/more-company-details",
    component: MoreCompanyDetails,
  },
  SERVICE_AREA_SETUP: {
    name: "Setup service area",
    path: "/onboarding/service-area-setup",
    component: SetupServiceArea,
  },
};
export default OnboardingRoutesDefinitions;