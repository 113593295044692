import { CoreModules, CoreRouting, CoreState } from '@build-buddy/core';
import { JobRouteDefinitions } from 'modules/company/job/JobRoutes';
import { useSelector } from 'react-redux';
import { useJobViewParams, useQueryJob } from '../../_hooks';

const JobViewExpenseView = () => {
  const routing = CoreRouting.useRouting();
  const jobViewParams = useJobViewParams();
  const queryJob = useQueryJob();

  // Params
  const params = {
    projectId: queryJob.jobParams.projectId,
    quoteRequestId: queryJob.jobParams.quoteRequestId,
    companyId: queryJob.jobParams.companyId
  }

  // Selectors
  const expense = useSelector((state) => CoreState.Financials.selectQuoteRequestExpenseById(state, params, { expenseId: jobViewParams.paymentScheduleId }))

  // Event handlers
  const handleClose = () => {
    const params = {
      sid: jobViewParams.stageId,
      tid: jobViewParams.taskId
    };
    routing.go(JobRouteDefinitions.JOB_VIEW_PAYMENT_SCHEDULE, { params })
  }
  return (
    <CoreModules.Financials.Expenses.ExpenseItemView
      onClose={handleClose}
      expense={expense}
    />
  )
}

export default JobViewExpenseView