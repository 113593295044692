import { CoreComponents, CoreRouting, CoreState } from "@build-buddy/core";
import { useSelector } from "common/state/store";
import { useCompanyParams } from "modules/company/_hooks";
import { useEffect } from "react";
import { CompanyProfileRouteDefintions } from "../CompanyProfileRoutes";

const CompanyProfileSkillAdd = () => {
  const { download } = CoreState.File.useDownloadQueue();
  const routing = CoreRouting.useRouting();

  // params
  const { companyId } = useCompanyParams();
  const getSkillListByCompanyParams = { companyId };

  // queries
  const getSkillListByCompanyQuery = CoreState.Skill.getSkillListByCompany.useQuery(getSkillListByCompanyParams);
  CoreState.Skill.getSkillDefinitions.useQuery();

  // mutations
  const [addSkillByCompanyAction, addSkillByCompanyQuery] = CoreState.Skill.addSkillByCompany.useMutation();
  const [uploadDocListByCompanySkillAction, uploadDocListByCompanySkillQuery] = CoreState.File.uploadDocListByCompanySkill.useMutation();

  // locals
  const skills = useSelector((state) => CoreState.Skill.selectSkillListByCompany(state, getSkillListByCompanyParams));
  const skillDefinitions = useSelector(CoreState.Skill.selectSkillDefinitions);
  const existingDefTypes = skills.map(x => x.type);
  const remainingDefs = skillDefinitions.filter((i) => !existingDefTypes.includes(i.type));
  const isLoading = Boolean(getSkillListByCompanyQuery.isLoading);
  const isSaving = Boolean(addSkillByCompanyQuery.isLoading || uploadDocListByCompanySkillQuery.isLoading);
  const isSuccess =
    // addSkill success
    Boolean(addSkillByCompanyQuery.isSuccess) &&
    // if we initiated the uploadDoc, then we check its success, otherwise its always true
    Boolean(uploadDocListByCompanySkillQuery.isUninitialized || uploadDocListByCompanySkillQuery.isSuccess);

  // events
  const handleClose = () => {
    routing.go(CompanyProfileRouteDefintions.COMPANY_PROFILE)
  }

  const handleSubmit = async (values: any) => {
    if (!companyId) return;

    const promises = [];

    const skill: CoreState.Common.Subset<CoreState.Skill.Skill> = {
      type: values.type,
      reference: values.number,
      expiry: values.expiry,
      subSkills: values.subSkills.map((s: string) => ({ type: s })),
    }
    promises.push(
      addSkillByCompanyAction({
        companyId,
        skill
      })
    )

    const docsToSave: Array<CoreState.Common.Subset<CoreState.File.Doc>> = values.files.toSave;
    if (docsToSave.length) {
      promises.push(
        uploadDocListByCompanySkillAction({
          companyId,
          type: values.type,
          docs: docsToSave
        })
      )
    }

    try {
      await Promise.all(promises);
    } catch (e) {
      // handle error
    }
  }

  const handleDownload = (code: string, doc: CoreState.File.Doc) => {
    if (!companyId || !doc.id) return;

    download(doc);
  }

  useEffect(() => {
    if (!isSuccess) return;
    handleClose();
  }, [isSuccess])

  return (
    <CoreComponents.SkillFormDialog
      title="Add Skill"
      definitions={remainingDefs}
      isLoading={isLoading}
      isSaving={isSaving}
      onClose={() => handleClose()}
      onSubmit={(values) => handleSubmit(values)}
      onDownload={(_, doc) => handleDownload(_, doc)}
    />
  )
}
export default CompanyProfileSkillAdd;