import { CoreModules, CoreRouting } from "@build-buddy/core";
import { Card } from "@mui/material";
import { useRouteMatch } from "react-router";
import { CompanyRouteDefinitions } from "../CompanyRoutes";

const CompanyProfile = () => {
  const routing = CoreRouting.useRouting();
  const match = useRouteMatch<any>(CompanyRouteDefinitions.COMPANY_PUBLIC_PROFILE);
  const companyId = match?.params?.cid;

  const handleReviewsClick = () => {
    routing.go(CompanyRouteDefinitions.COMPANY_REVIEWS)
  }

  return (
    <Card
      sx={{
        "& >.MuiBox-root": {
          pt: 0,
        }
      }}
    >
      <CoreModules.Company.CompanyProfile
        onReviewsClick={() => handleReviewsClick()}
        companyId={companyId}
      />
    </Card>
  )
}
export default CompanyProfile;