import { useRouteMatch } from "react-router-dom";
import { CompanyProfileInsuranceRoutesDefinitions } from "../CompanyProfileInsuranceRoutes";
import { useMemo } from "react";

const useCompanyProfileInsuranceParams = () => {
  const match = useRouteMatch<any>(CompanyProfileInsuranceRoutesDefinitions.COMPANY_PROFILE_INSURANCE_EDIT);

  const params = useMemo(() => ({
    code: match?.params.iid,
  }), [match?.params]);

  return params;
}
export default useCompanyProfileInsuranceParams;