import { CoreComponents, CoreRouting, CoreState, CoreUtils } from "@build-buddy/core";
import { useCompanyParams } from "modules/company/_hooks";
import { useEffect, useState } from "react";
import { CompanyProfileRouteDefintions } from "../CompanyProfileRoutes";

const CompanyProfileTeamsInviteAdd = () => {
  const routing = CoreRouting.useRouting();

  // params
  const { companyId } = useCompanyParams();

  // mutations
  const [createInviteByCompanyAction, createInviteByCompanyQuery] = CoreState.Membership.createInviteByCompany.useMutation();

  // locals
  const [errors, setErrors] = useState<any>({});
  const isSaving = Boolean(createInviteByCompanyQuery.isLoading);
  const isSuccess = Boolean(createInviteByCompanyQuery.isSuccess);
  const isError = Boolean(createInviteByCompanyQuery.isError);

  // events
  const handleClose = () => {
    routing.go(CompanyProfileRouteDefintions.COMPANY_PROFILE);
  }

  const handleSubmit = (values: any) => {
    const invite: CoreState.Common.Subset<CoreState.Membership.Invite> = {
      name: values.name,
      email: values.email,
      contact: values.contact,
      type: values.access
    }

    createInviteByCompanyAction({ companyId, invite })
  }

  useEffect(() => {
    if (!isSuccess) return;
    handleClose();
  }, [isSuccess])

  useEffect(() => {
    const errors = CoreUtils.Converter.toFormikErrors([
      CoreUtils.ServiceResponseHelper.getServiceError(createInviteByCompanyQuery)      
    ]);
    setErrors(errors);
  }, [isError])

  return (
    <CoreComponents.TeamMembersFormDialog 
      title="Invite Member"
      accessTypes={[CoreState.Company.CompanyAccessLevel.Manager]}
      isSaving={isSaving}
      errors={errors}
      onSubmit={handleSubmit}
      onClose={handleClose}
    />
  )
}
export default CompanyProfileTeamsInviteAdd;