import { FormikContextType } from "formik"
import { CoreComponents } from "@build-buddy/core"
import { Card, CardContent, Grid, Typography } from "@mui/material"
import InitialDetailsFormTypes from "./InitialDetailsFormTypes"

type InitialDetailsUserProps = {
  formik: FormikContextType<InitialDetailsFormTypes>;
  isLoading: boolean;
}
const InitialDetailsUser = (props: InitialDetailsUserProps) => {
  const { formik } = props;
  return (
    <Card sx={{ mb: 2 }}>
      <CoreComponents.Loader show={props.isLoading} />
      <CardContent>
        <Typography variant="h2">
          Personal Details
        </Typography>
        <Grid container rowSpacing={1} columnSpacing={2} sx={{ mt: 3 }}>
          <Grid item xs={12} md={6}>
            <CoreComponents.FormikTextbox
              formik={formik}
              name="user.firstName"
              label="First name*"
              fullWidth
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <CoreComponents.FormikTextbox
              formik={formik}
              name="user.lastName"
              label="Last name*"
              fullWidth
            />
          </Grid>
          <Grid item xs={12} >
            <CoreComponents.FormikTextbox
              formik={formik}
              name="user.email"
              label="Email*"
              fullWidth
              disabled={true}
            />
          </Grid>
          <Grid item xs={12}>
            <CoreComponents.FormikMobileNumber
              formik={formik}
              name="user.mobile"
              label="Mobile Number*"
              tooltip="This number is linked to your account and is used for logging in."
            />
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  )
}
export default InitialDetailsUser;