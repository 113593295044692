import { Box, SxProps, Theme } from "@mui/material";

type JobViewQuotesListTemplateProps = {
  children: any;
  sx?: SxProps<Theme>;
  onClick?(): void;
}
const JobViewQuotesListTemplate = (props: JobViewQuotesListTemplateProps) => {
  return (
    <>
      <Box
        sx={{
          display: "grid",
          gridTemplateAreas: {
            xs: `'company actions'
                  'quote actions'
                  'date actions'
                `,
            sm: `'company date quote actions'`
          },
          gridTemplateColumns: {
            xs: "70% 100px",
            sm: "40% 130px auto 150px",
            md: "50% 150px auto 200px",
          },
          alignItems: "center",
          ...props.sx
        }}
        onClick={props.onClick && props.onClick}
      >
        {props.children}
      </Box>
    </>
  )
}
export default JobViewQuotesListTemplate;