import { CoreComponents, CoreState } from "@build-buddy/core";
import { Card, CardContent, Typography } from "@mui/material";
import { CommonComponents } from "common";
import { useSelector } from "common/state/store";
import { FormikContextType } from "formik";
import InitialDetailsFormTypes from "../InitialDetailsFormTypes";

type InitialDetailsCompanyCreateProps = {
  formik: FormikContextType<InitialDetailsFormTypes>
  isLoading: boolean;
}
const InitialDetailsCompanyCreate = (props: InitialDetailsCompanyCreateProps) => {
  const { formik } = props;

  const user = useSelector(CoreState.User.selectCurrentUser);

  return (
    <Card sx={{ mb: 2 }}>
      <CoreComponents.Loader show={props.isLoading} />
      <CardContent>
        <Typography
          variant="h2"
          sx={{ mb: 2 }}
        >
          Company Details
        </Typography>

        <CoreComponents.FormikTextbox
          name="company.name"
          label="Company Name*"
          fullWidth
          formik={formik}
        />

        <CommonComponents.FormikCompanyContactEmail 
          formik={formik}
          email={user?.email}
          checkboxName="company.usePersonalEmail"
          textboxName="company.email"
        />
        <CommonComponents.FormikCompanyContactNumber
          formik={formik}
          number={formik.values.user.mobile}
          checkboxName="company.usePersonalContactNumber"
          textboxName="company.contactNumber"
        />        
        <CoreComponents.FormikTextbox
          formik={formik}
          label="Company Public Email"
          name="company.publicEmailAddress"
          tooltip="This email will be featured in your company profile and serves as the primary means for new customers to initiate contact with you. Eg. sales@buildbuddy.au"
          fullWidth
        />
        <CoreComponents.FormikMobileNumber
          formik={formik}
          label="Company Public Contact Number"
          name="company.publicContactNumber"
          tooltip="This number will be featured in your company profile and serves as the primary means for new customers to initiate contact with you."
        />        
      </CardContent>
    </Card>
  )
}
export default InitialDetailsCompanyCreate;