import { createSelector } from "@reduxjs/toolkit";
import { BuildBuddyProStateType } from "common/state/store";

const selectRoot = (state: BuildBuddyProStateType) => state.AppReducer;

export const selectCurrentCompanyId = createSelector(
  selectRoot,
  (root) => root.company.id || ''
)

export const selectIsLoading = createSelector(
  selectRoot,
  (root) => root.isLoading
)