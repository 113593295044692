import { CoreComponents } from "@build-buddy/core";
import { Box } from "@mui/material";
import { FormikContextType, getIn } from "formik";
import { useEffect } from "react";

type FormikCompanyContactEmailProps = {
  formik: FormikContextType<any>;
  email?: string;
  checkboxName: string;
  textboxName: string;
}
const FormikCompanyContactEmail = (props: FormikCompanyContactEmailProps) => {
  const { formik, email, checkboxName, textboxName } = props;

  const isPersonalEmail = getIn(formik.values, checkboxName);

  useEffect(() => {
    if (!isPersonalEmail) {
      formik.setFieldValue(textboxName, "");
      return;
    }
    formik.setFieldValue(textboxName, email);
  }, [isPersonalEmail])

  return (
    <Box>
      <CoreComponents.Label
        label="Company Contact Email*"
        sx={{ mb: 0.75 }}
        tooltip="This email will be used for quote requests and business notifications from Build Buddy. You may want to set up a dedicated email address. Eg. notifications@buildbuddy.au"
      />
      <CoreComponents.FormikCheckbox
        formik={formik}
        name={checkboxName}
        label="Use my personal email"
      />
      <CoreComponents.FormikTextbox
        formik={formik}
        name={textboxName}
        disabled={Boolean(isPersonalEmail)}
        fullWidth
      />
    </Box>
  )
}
export default FormikCompanyContactEmail;