import { Avatar, CoreState, useMode } from "@build-buddy/core";
import { AppBar, Box, IconButton, Toolbar, useTheme } from "@mui/material";
import { CommonState } from "common";
import { useDispatch, useSelector } from "common/state/store";
import { CompanySelect } from "modules/company/company-select/CompanySelect";
import Offset from "../Offset";

interface MobileHeaderProps {
  avatar?: string;
  onMenuClick(): void;
  onAvatarClick(e: any): void;
}
const MobileHeader = (props: MobileHeaderProps) => {
  const dispatch = useDispatch()
  const theme = useTheme();

  const currentUserId = useSelector(CoreState.User.selectCurrentUserId);
  const companyId = useSelector(CommonState.App.selectCurrentCompanyId);

  const handleChange = (companyId: string) => {
    dispatch(CommonState.App.setCurrentCompany({ companyId }))
  }

  return (
    <>
      <AppBar position="fixed" sx={{ boxShadow: 0, background: theme.palette.common.white }}>
        <Box sx={{ background: theme.palette.common.white, p: 2 }}>
          <CompanySelect
            companyId={companyId}
            userId={currentUserId}
            onChange={handleChange}
          />
        </Box>
      </AppBar>
    </>
  );
};

interface DesktopHeaderProps {
  avatar?: string;
  onAvatarClick(e: any): void;
}
const DesktopHeader: React.FC<DesktopHeaderProps> = (props) => {
  const dispatch = useDispatch()
  const theme = useTheme();

  const currentUserId = useSelector(CoreState.User.selectCurrentUserId);
  const companyId = useSelector(CommonState.App.selectCurrentCompanyId);

  const handleChange = (companyId: string) => {
    dispatch(CommonState.App.setCurrentCompany({ companyId }))
  }

  return (
    <>
      <AppBar position="fixed" sx={{ boxShadow: 0, background: theme.palette.common.white }}>
        <Toolbar
          sx={{
            display: "flex",
            minHeight: "64px",
            px: 3,
            ml: `${theme.variables.drawerWidth}px`,
          }}
        >
          <Box sx={{ flex: 1 }}>
            <CompanySelect
              companyId={companyId}
              userId={currentUserId}
              onChange={handleChange}
            />
          </Box>
          <IconButton edge="end" onClick={props.onAvatarClick} sx={{ display: "flex", alignItems: "center" }}>
            <Avatar
              sx={{
                fontSize: "28px",
                bgcolor: "common.black",
                color: "common.white",
              }}
              src={props.avatar || ""}
            />
          </IconButton>
        </Toolbar>
      </AppBar>
      <Offset space={2} />
    </>
  );
};

interface HeaderProps {
  avatar?: string;
  onMenuClick(): void;
  onAvatarClick(e: any): void;
}
const Header = (props: HeaderProps) => {
  const mode = useMode();

  return mode.md ?
    <DesktopHeader
      avatar={props.avatar}
      onAvatarClick={props.onAvatarClick}
    />
    :
    <MobileHeader
      avatar={props.avatar}
      onMenuClick={props.onMenuClick}
      onAvatarClick={props.onAvatarClick}
    />;
};

export default Header;
