import { Box, SxProps, Theme, useTheme } from "@mui/material";

type JobViewQuoteFormPaymentScheduleTemplateProps = {
  sx?: SxProps<Theme>;
  children: any;
}
const JobViewQuoteFormPaymentScheduleTemplate = (props: JobViewQuoteFormPaymentScheduleTemplateProps) => {
  const theme = useTheme();
  return (
    <Box
      sx={{
        display: "grid",
        gridTemplateAreas: {
          xs: `'stage actions'
                'amount amount'
                'paymentType paymentType'
                `,
          md: `'stage paymentType amount actions'`,
        },
        gridColumnGap: theme.spacing(2),
        gridTemplateColumns: {
          xs: `80% 50px`,
          md: `50% 130px auto 25px`,
        },
        "&:last-of-type": {
          mb: 0,
          pb: 0
        },
        ...props.sx
      }}
    >
      {props.children}
    </Box>
  )
}
export default JobViewQuoteFormPaymentScheduleTemplate;