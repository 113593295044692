import { CoreModules } from "@build-buddy/core";
import { KeyboardArrowDown as KeyboardArrowDownIcon } from "@mui/icons-material";
import { Box, Stack, Typography } from "@mui/material";
import { useSelector } from "common/state/store";
import { useQueryJobSpecs } from "../_hooks";
import { selectJobSpecsRequest, selectJobSpecsStandard, selectJobSpecsUser } from "../_selectors";

const useVm = () => {
  // queries
  const queryJobSpecs = useQueryJobSpecs();

  // selectors
  const jobSpecsRequest = useSelector((s) => selectJobSpecsRequest(s, queryJobSpecs.quoteRequestSpecificationListParams));
  const standardJobSpecs = useSelector((s) => selectJobSpecsStandard(s, queryJobSpecs.quoteRequestSpecificationListParams));
  const userJobSpecs = useSelector((s) => selectJobSpecsUser(s, queryJobSpecs.quoteRequestSpecificationListParams));

  return {
    isLoading: jobSpecsRequest.isLoading,
    standardJobSpecs,
    userJobSpecs
  }
}
const JobViewScopeOfWork = () => {
  const vm = useVm();
  return (
    <>
      <Stack direction="row" alignItems="center" sx={{ mb: 1.5 }}>
        <KeyboardArrowDownIcon sx={{ mr: 1.25 }} />
        <Typography variant="h2">
          Specifications
        </Typography>
      </Stack>
      <Box sx={{ p: 0.5 }}>
        <CoreModules.Task.TaskView.TaskViewSpecs
          specs={vm.standardJobSpecs}
          isLoading={vm.isLoading}
        />
        <Typography variant="h3" sx={{ mt: 2, mb: 2 }}>User Specifications:</Typography>
        <CoreModules.Task.TaskView.TaskViewSpecs
          specs={vm.userJobSpecs}
          isLoading={vm.isLoading}
          isUserSpec={true}
        />
      </Box>
    </>
  )
}
export default JobViewScopeOfWork;