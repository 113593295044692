import { Stack, Typography, IconButton, useTheme } from "@mui/material"
import { CalendarToday as CalendarIcon, Edit as EditIcon, Replay as ReplayIcon, KeyboardArrowDown as ArrowDownIcon, KeyboardArrowUp as ArrowUpIcon } from "@mui/icons-material";
import { CoreComponents, CoreUtils } from "@build-buddy/core"

type JobViewQuoteFormTaskListViewProps = {
  name: string;
  startDate: string;
  endDate: string;
  isReadonly: boolean;
  isChanged: boolean;
  isForecast: boolean;
  isOpen?: boolean;
  onEdit(): void;
  onRevert(): void;
  onOpen?(): void;
}
const JobViewQuoteFormTaskListView = (props: JobViewQuoteFormTaskListViewProps) => {
  const theme = useTheme();
  const showDropdownIcon = Boolean(props.onOpen);

  return (
    <Stack direction="row">
      <Stack direction="row" alignItems="center" sx={{ flex: 1 }}>
        <CoreComponents.OutlinedIcon
          icon={CalendarIcon}
          iconStyle={{
            background: theme.palette.common.white,
          }}
          sx={{ mr: 1.5 }}
        />
        {props.isForecast &&
          <Typography variant="body1"> As forecasted </Typography>
        }
        {!props.isForecast &&
          <>
            <Typography variant="body1"> {CoreUtils.Formatter.date(props.startDate, "dd MMM yy")} </Typography>
            <Typography variant="body1" sx={{ mx: 0.5 }}> - </Typography>
            <Typography variant="body1"> {CoreUtils.Formatter.date(props.endDate, "dd MMM yy")} </Typography>
          </>
        }
      </Stack>
      {props.isChanged &&
        <IconButton onClick={props.onRevert}>
          <ReplayIcon />
        </IconButton>
      }
      {Boolean(!props.isReadonly && !props.isForecast) &&
        <>
          <IconButton onClick={props.onEdit}>
            <EditIcon />
          </IconButton>
          {showDropdownIcon &&
            (<IconButton onClick={props.onOpen} edge="end">
              {props.isOpen ? <ArrowUpIcon /> : <ArrowDownIcon />}
            </IconButton>)
          }
        </>
      }
    </Stack>
  )
}

export default JobViewQuoteFormTaskListView;