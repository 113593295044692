import { CoreState } from "@build-buddy/core";
import { createSelector } from "@reduxjs/toolkit";

type SelectJobMessageList = {
    projectId: string;
    stageId: string;
    taskId: string;
    quoteRequestId: string;
}

const selectRoot = createSelector(
    [(state: CoreState.RootState, params: SelectJobMessageList) => CoreState.NoteThread.getQuoteNoteThreadList.select(params)(state)],
    (jobMessageListQuery) => jobMessageListQuery
)

const selectJobMessageRequest = createSelector(
    selectRoot,
    (jobMessageListQuery) => CoreState.extractRequest(jobMessageListQuery)
)

const selectJobMessageList = createSelector(
    selectRoot,
    (jobMessageListQuery) => jobMessageListQuery.data
)

export { selectJobMessageList, selectJobMessageRequest };
