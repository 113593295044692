import { CoreComponents, CoreState } from "@build-buddy/core";
import { Box, Button } from "@mui/material";
import { useJobViewParams } from "modules/company/job/job-view/_hooks";
import { useState } from "react";
import addJobViewQualityAssuranceDefectsNoteItem from "../../_actions/addJobViewQualityAssuranceDefectsNoteItem";
import { useSelector } from "react-redux";
import { selectJobQuoteRequestOwnerId } from "modules/company/job/job-view/_selectors";

type JobViewQualityAssuranceDefectsActionsAwaitingProps = {
  defect: CoreState.Defect.Defect;
}
// this is declared due to the rule of hooks, since its a chain, if any hooks gets rendered and the number changes, it causes a violation
const JobViewQualityAssuranceDefectsActionsAwaiting = (props: JobViewQualityAssuranceDefectsActionsAwaitingProps) => {
  const { defect } = props;
  if (!defect) return null;
  if (defect.status !== CoreState.Defect.DefectStatus.AwaitingApproval) return null;
  return <InnerJobViewQualityAssuranceDefectsActionsAwaiting defect={defect} />
}
const InnerJobViewQualityAssuranceDefectsActionsAwaiting = (props: JobViewQualityAssuranceDefectsActionsAwaitingProps) => {
  const { defect } = props;

  // params
  const { projectId, stageId, taskId, quoteRequestId, companyId } = useJobViewParams();
  const params = { projectId, stageId, taskId, quoteRequestId, companyId };

  // mutations
  const [updateTaskDefectAction, updateTaskDefectQuery] = CoreState.Defect.updateDefect.useMutation();
  const [addJobViewQualityAssuranceDefectsNoteItemAction, addJobViewQualityAssuranceDefectsNoteItemQuery] = addJobViewQualityAssuranceDefectsNoteItem.useMutation();

  // selectors
  const quoteRequestOwnerId = useSelector((s: CoreState.RootState) => selectJobQuoteRequestOwnerId(s, params))

  // locals
  const [open, setOpen] = useState(false);
  const isAcceptSaving = updateTaskDefectQuery.isLoading;
  const isRejectSaving = isAcceptSaving || addJobViewQualityAssuranceDefectsNoteItemQuery.isLoading;
  const isSuccess = updateTaskDefectQuery.isSuccess && addJobViewQualityAssuranceDefectsNoteItemQuery.isSuccess;

  // event handlers
  const handlePopup = (e: React.SyntheticEvent | undefined, open: boolean) => {
    e?.stopPropagation();
    setOpen(open);
  }

  const handleAccept = (e: React.SyntheticEvent) => {
    e.stopPropagation();
    updateTaskDefectAction({
      projectId,
      stageId,
      taskId,
      defectId: defect.id,
      defect: { status: CoreState.Defect.DefectStatus.Approved },
    })
  }

  const handleSubmit = async (values: any) => {
    await updateTaskDefectAction({
      projectId,
      stageId,
      taskId,
      defectId: defect.id,
      defect: {
        status: CoreState.Defect.DefectStatus.InDispute,
      },
    })

    if (!quoteRequestOwnerId) return;

    addJobViewQualityAssuranceDefectsNoteItemAction({
      projectId,
      stageId,
      taskId,
      defectId: defect.id,
      noteThread: values.noteThread,
      docs: values.docs,
      toUserId: quoteRequestOwnerId
    })
  }

  return (
    <>
      <CoreComponents.Loader show={isAcceptSaving} />
      <Box sx={{ textAlign: "right" }} >
        <Button
          variant="outlined"
          color="neutral"
          sx={{ mr: 1 }}
          onClick={(e) => handlePopup(e, true)}
        >
          Reject
        </Button>
        <Button
          variant="contained"
          onClick={(e) => handleAccept(e)}
        >
          Accept
        </Button>
      </Box>
      <CoreComponents.AddNoteThread
        isSaving={isRejectSaving}
        isSuccess={isSuccess}
        isOpen={open}
        fields={{
          header: "Reject defect",
          note: "Reason for rejecting defect",
          files: "Upload supporting documents"
        }}
        onClose={() => handlePopup(undefined, false)}
        onAdd={(values) => handleSubmit(values)}
      />
    </>
  )
}
export default JobViewQualityAssuranceDefectsActionsAwaiting;