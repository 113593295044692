import { CoreRouting } from "@build-buddy/core";
import JobView from "./job-view/JobView";
import JobViewDescription from "./job-view/job-view-description/JobViewDescription";
import JobViewDocuments from "./job-view/job-view-documents/JobViewDocuments";
import JobViewMessages from "./job-view/job-view-messages/JobViewMessages";
import JobViewPayments from "./job-view/job-view-payments/JobViewPayments";
import JobViewExpenseView from "./job-view/job-view-payments/job-view-payment-expenses/JobViewExpenseView";
import JobViewPaymentReceiptViewer from "./job-view/job-view-payments/job-view-payment-schedule/JobViewPaymentReceiptViewer";
import JobViewQualityAssuranceRouteDefinitions from "./job-view/job-view-quality-assurance/JobViewQualityAssuranceRouteDefinitions";
import JobViewQuoteList from "./job-view/job-view-quote-list/JobViewQuoteList";
import JobViewQuoteAdd from "./job-view/job-view-quote/JobViewQuoteAdd";
import JobViewQuoteEdit from "./job-view/job-view-quote/JobViewQuoteEdit";
import JobViewQuoteView from "./job-view/job-view-quote/JobViewQuoteView";
import JobViewScopeOfWork from "./job-view/job-view-scope-of-work/JobViewScopeOfWork";
import JobViewVariationsRouteDefinitions from "./job-view/job-view-variations/JobViewVariationsRouteDefinitions";

const JobRouteDefinitions = {
  JOB_VIEW: {
    path: "/*/job/:pid/stage/:sid/task/:tid/quote/:qid",
    component: JobView
  },
  JOB_VIEW_DESCRIPTION: {
    label: "Overview",
    path: "/*/job/:pid/stage/:sid/task/:tid/quote/:qid/description",
    component: JobViewDescription
  },
  JOB_VIEW_SCOPE_OF_WORK: {
    label: "Scope of Works",
    path: "/*/job/:pid/stage/:sid/task/:tid/quote/:qid/scope-of-work",
    component: JobViewScopeOfWork
  },
  JOB_VIEW_DOCUMENTS: {
    label: "Documents",
    path: "/*/job/:pid/stage/:sid/task/:tid/quote/:qid/documents",
    component: JobViewDocuments
  },
  JOB_VIEW_PAYMENT_SCHEDULE: {
    label: "Payments",
    path: "/*/job/:pid/stage/:sid/task/:tid/quote/:qid/payment-schedule",
    component: JobViewPayments
  },
  JOB_VIEW_EXPENSE: {
    path: "/*/job/:pid/stage/:sid/task/:tid/quote/:qid/payment-schedule/:paymentScheduleId",
    component: JobViewExpenseView
  },
  JOB_VIEW_PAYMENT_RECEIPT: {
    label: "Payments",
    path: "/*/job/:pid/stage/:sid/task/:tid/quote/:qid/payment-schedule/payment-receipt/:paymentScheduleId",
    component: JobViewPaymentReceiptViewer
  },
  JOB_VIEW_QUOTES: {
    label: "My Quotes",
    path: "/*/job/:pid/stage/:sid/task/:tid/quote/:qid/quotes",
    component: JobViewQuoteList
  },
  JOB_VIEW_QUOTE_EDIT: {
    exact: true,
    path: "/*/job/:pid/stage/:sid/task/:tid/quote/:qid/quotes/:quoteId/edit",
    component: JobViewQuoteEdit
  },
  JOB_VIEW_QUOTE_VIEW: {
    exact: true,
    path: "/*/job/:pid/stage/:sid/task/:tid/quote/:qid/quotes/:quoteId",
    component: JobViewQuoteView
  },
  JOB_VIEW_QUOTE_ADD: {
    path: "/*/job/:pid/stage/:sid/task/:tid/quote/:qid/*/quote/add",
    component: JobViewQuoteAdd
  },
  JOB_VIEW_MESSAGES: {
    label: "Messages",
    path: "/*/job/:pid/stage/:sid/task/:tid/quote/:qid/messages",
    component: JobViewMessages
  },
  ...JobViewVariationsRouteDefinitions,
  ...JobViewQualityAssuranceRouteDefinitions
}

const rootRoutes = [
  JobRouteDefinitions.JOB_VIEW,
  JobRouteDefinitions.JOB_VIEW_QUOTE_ADD,
  JobRouteDefinitions.JOB_VIEW_QUOTE_EDIT,
  JobRouteDefinitions.JOB_VIEW_QUOTE_VIEW,
  JobRouteDefinitions.JOB_VIEW_QUALITY_ASSURANCE_DEFECTS_VIEW_NOTE_ADD,
];

const JobRoutes = () => {
  return (
    <CoreRouting.Routes routes={rootRoutes} />
  );
};

export { JobRouteDefinitions, JobRoutes };
