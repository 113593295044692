
import { Route } from "react-router";
import CompanyProfile from "./company-profile/CompanyProfile";
import CompanyPublicProfile from "./company-public-profile/CompanyPublicProfile";
import CompanyReviews from "./company-reviews/CompanyReviews";
import CompanyCreate from "./company-create/CompanyCreate";
import { DashboardRouteDefinitions, DashboardRoutes } from "./dashboard/DashboardRoutes";
import { JobRouteDefinitions, JobRoutes } from "./job/JobRoutes";
import CompanyGuard from "./CompanyGuard";

const CompanyRouteDefinitions = {
  ...DashboardRouteDefinitions,
  ...JobRouteDefinitions,
  COMPANY_ROOT: {
    path: "/company",
    component: CompanyProfile
  },
  COMPANY: {
    path: "/company/:cid",
    component: CompanyProfile
  },
  COMPANY_ADD: {
    path: "/*/company-add",
    component: CompanyCreate,
  },
  COMPANY_PROFILE: {
    path: "/company/:cid/profile",
    component: CompanyProfile
  },
  COMPANY_PUBLIC_PROFILE: {
    path: "/company/:cid/public-profile",
    component: CompanyPublicProfile
  },
  COMPANY_REVIEWS: {
    path: "/company/:cid/reviews",
    component: CompanyReviews
  }
}

const CompanyRoutes = () => {
  return (
    <>
      <Route
        path={CompanyRouteDefinitions.COMPANY_ADD.path}
        component={CompanyRouteDefinitions.COMPANY_ADD.component}
      />
      <CompanyGuard path={["/company"]}>
        <DashboardRoutes />
        <JobRoutes />

        <Route
          path={CompanyRouteDefinitions.COMPANY_PROFILE.path}
          component={CompanyRouteDefinitions.COMPANY_PROFILE.component}
        />
        <Route
          path={CompanyRouteDefinitions.COMPANY_REVIEWS.path}
          component={CompanyRouteDefinitions.COMPANY_REVIEWS.component}
        />
        <Route
          path={CompanyRouteDefinitions.COMPANY_PUBLIC_PROFILE.path}
          component={CompanyRouteDefinitions.COMPANY_PUBLIC_PROFILE.component}
        />
      </CompanyGuard>
    </>
  );
};

export { CompanyRouteDefinitions, CompanyRoutes };
