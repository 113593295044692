import { Box } from "@mui/material"
import JobViewExpenses from "./job-view-payment-expenses/JobViewExpenses"
import JobViewPaymentSchedule from "./job-view-payment-schedule/JobViewPaymentSchedule"

const JobViewPayments = () => {
  return (
    <>
      <JobViewPaymentSchedule />
      {/* <Box mt={5}>
        <JobViewExpenses />
      </Box> */}
    </>
  )
}

export default JobViewPayments