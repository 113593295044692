
import ServiceAreaRouteDefinitions from "modules/onboarding/components/setup-service-area/ServiceAreaRouteDefinitions";
import { Route } from "react-router";

const CompanyProfileServiceAreasRoutes = () => {
  return (
    <>
      <Route
        path={ServiceAreaRouteDefinitions.SERVICE_AREA_ADD.path}
        component={ServiceAreaRouteDefinitions.SERVICE_AREA_ADD.component}
      />
      <Route
        path={ServiceAreaRouteDefinitions.SERVICE_AREA_EDIT.path}
        component={ServiceAreaRouteDefinitions.SERVICE_AREA_EDIT.component}
      />
    </>
  );
};

export default CompanyProfileServiceAreasRoutes;
