import { Chip, Stack, Typography, useTheme } from "@mui/material";
import { CommonState } from "common";
import { Error as ErrorIcon } from '@mui/icons-material'

type JobStatusProps = {
  status?: CommonState.Job.JobStatus;
  isSpecsModified?: boolean;
}
const JobStatus = (props: JobStatusProps) => {
  const theme = useTheme();

  if (!props.status) return null;

  const self = {
    bg: theme.palette.primary.main,
    fg: theme.palette.common.white
  };

  const other = {
    bg: theme.palette.common.white,
    fg: theme.palette.primary.main
  }

  const system = {
    bg: theme.palette.grey[600],
    fg: theme.palette.common.white
  }

  const completed = {
    bg: theme.palette.success.main,
    fg: theme.palette.common.white
  }

  const kvp = {
    [CommonState.Job.JobStatus.NA]: {
      label: "Not Applicable",
      colour: other
    },
    [CommonState.Job.JobStatus.Closed]: {
      label: "Closed",
      colour: other
    },
    [CommonState.Job.JobStatus.New]: {
      label: "New",
      colour: self
    },
    [CommonState.Job.JobStatus.QuoteSubmitted]: {
      label: "Quote Submitted",
      colour: other
    },
    [CommonState.Job.JobStatus.QuoteDeclined]: {
      label: "Quote Declined",
      colour: self
    },
    [CommonState.Job.JobStatus.AwaitingConfirmation]: {
      label: "Awaiting Confirmation",
      colour: self
    },
    [CommonState.Job.JobStatus.AwaitingToStart]: {
      label: "Awaiting Start",
      colour: self
    },
    [CommonState.Job.JobStatus.AwaitingConstruction]: {
      label: "Awaiting Construction",
      colour: system
    },
    [CommonState.Job.JobStatus.InProgress]: {
      label: "Work In Progress",
      colour: self
    },
    [CommonState.Job.JobStatus.AwaitingOwner]: {
      label: "With Owner",
      colour: other
    },
    [CommonState.Job.JobStatus.InQA]: {
      label: "In Review",
      colour: other
    },
    [CommonState.Job.JobStatus.AwaitingSignOff]: {
      label: "Awaiting Sign-Off",
      colour: other
    },
    [CommonState.Job.JobStatus.Completed]: {
      label: "Completed",
      colour: completed
    }
  }

  return (
    <Stack direction="row" gap={1}>
      <Chip
        size="small"
        label={kvp[props.status].label}
        sx={{
          textTransform: "uppercase",
          fontWeight: "bold",
          color: kvp[props.status].colour.fg,
          backgroundColor: kvp[props.status].colour.bg,
          border: `1px solid ${kvp[props.status].colour.fg}`
        }}
      />
      {props.isSpecsModified &&
        <Stack
          direction="row"
          alignItems={{xs: 'flex-start', md: "center"}}
          gap={0.75}
          sx={{
            border: `1px solid ${theme.palette.primary.main}`,
            borderRadius: '12px',
            pr: 0.5
          }}
        >
          <ErrorIcon sx={{ fontSize: 23, color: theme.palette.primary.main }} />
          <Typography variant="subtitle2" color={'primary'}>Owner has amended the Scope Of Works.</Typography>
        </Stack>
      }
    </Stack>
  )
}
export default JobStatus;