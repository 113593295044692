import { CoreComponents, CoreState, CoreUtils, OnboardingActions, OnboardingHeader } from "@build-buddy/core";
import { Box, Card, CardContent, Grow } from "@mui/material";
import { useDispatch, useSelector } from "common/state/store";
import { useFormik } from "formik";
import { useEffect } from "react";
import InitialDetailsFormTypes from "./InitialDetailsFormTypes";
import InitialDetailsFormValidation from "./InitialDetailsFormValidation";
import InitialDetailsUser from "./InitialDetailsUser";
import InitialDetailsCompany from "./initial-details-company/InitialDetailsCompany";

const InitialDetails = () => {
  const dispatch = useDispatch();

  // params
  const userId = useSelector(CoreState.User.selectCurrentUserId);
  const getCompanyListByUserParams = { userId };
  const getUserParams = { userId };

  // mutataions
  const [updateUserAction, updateUserQuery] = CoreState.User.updateUser.useMutation();
  const [addCompanyAction, addCompanyQuery] = CoreState.Company.addCompany.useMutation();

  // queries
  const getUser = CoreState.User.getUser.useQuery(getUserParams);

  // locals
  const companies = useSelector((s) => CoreState.Company.selectCompanyListByUser(s, getCompanyListByUserParams));
  const user = useSelector(CoreState.User.selectCurrentUser);
  const company = companies[0];
  const isLoading = updateUserQuery.isLoading;
  const isRefetching = getUser.isFetching;
  const isSuccess = Boolean(updateUserQuery.isSuccess) && Boolean(addCompanyQuery.isUninitialized || addCompanyQuery.isSuccess);
  const isError = Boolean(updateUserQuery.isError) || Boolean(addCompanyQuery.isError);

  const formik = useFormik<InitialDetailsFormTypes>({
    initialValues: {
      user: {
        firstName: user?.firstName || "",
        lastName: user?.lastName || "",
        mobile: user?.mobile || "",
        email: user?.email || "",
      },
      company: {
        name: "",
        email: "",
        contactNumber: "",
        publicEmailAddress: "",
        publicContactNumber: "",
        usePersonalEmail: false,
        usePersonalContactNumber: false,
      },
      tnc: false,
    },
    onSubmit: async (values) => {
      if (!userId) return;
      const updateUserResp = await updateUserAction({
        userId,
        user: {
          firstName: values.user.firstName,
          lastName: values.user.lastName,
          mobile: values.user.mobile,
          version: user?.version
        }
      });

      if (updateUserResp.error || company) return;

      await addCompanyAction({
        company: {
          name: values.company.name,
          email: values.company.email,
          contactNumber: values.company.contactNumber,
          usePersonalContactNumber: values.company.usePersonalContactNumber,
          usePersonalEmail: values.company.usePersonalEmail,
          publicContactNumber: values.company.publicContactNumber,
          publicEmailAddress: values.company.publicEmailAddress
        }
      })
    },
    validationSchema: InitialDetailsFormValidation,
  });

  // side effects
  useEffect(() => {
    if (!company) return;
    formik.setValues({
      ...formik.values,
      company: {
        name: company.name || "",
        email: company.email || "",
        contactNumber: company.contactNumber || "",
        publicContactNumber: company.publicContactNumber || "",
        publicEmailAddress: company.publicEmailAddress || "",
        usePersonalContactNumber: Boolean(company.usePersonalContactNumber),
        usePersonalEmail: Boolean(company.usePersonalEmail),
      }
    })
  }, [company])

  useEffect(() => {
    if (!isSuccess || isRefetching) return;
    dispatch(OnboardingActions.nextStep());
  }, [isSuccess, isRefetching])

  useEffect(() => {
    const errors = CoreUtils.Converter.toFormikErrors([
      { prefix: "user", response: CoreUtils.ServiceResponseHelper.getServiceError(updateUserQuery) },
      { prefix: "company", response: CoreUtils.ServiceResponseHelper.getServiceError(addCompanyQuery) }
    ]);
    formik.setErrors(errors);
  }, [isError])

  return (
    <>
      <OnboardingHeader />
      <Grow in={true}>
        <Box sx={{ maxWidth: 600, width: '100%', margin: "0 auto" }}>
          <InitialDetailsUser formik={formik} isLoading={isLoading} />
          <InitialDetailsCompany formik={formik} isLoading={isLoading} />
          <Card sx={{ mb: 4 }}>
            <CardContent>
              <CoreComponents.TermsAndConditions
                name="tnc"
                error={Boolean(formik.touched?.tnc) && formik.errors?.tnc as string}
                value={formik.values.tnc}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
              />
              <CoreComponents.Actions
                isLoading={isLoading}
                onSubmitClick={() => formik.handleSubmit()}
              />
            </CardContent>
          </Card>
        </Box>
      </Grow>
    </>
  )
}
export default InitialDetails;