import { CoreRouting, CoreState, CoreUtils } from "@build-buddy/core";
import { useSelector } from "common/state/store";
import { CompanyFormDialog } from "modules/company/_components";
import { useCompanyParams } from "modules/company/_hooks";
import { useEffect, useState } from "react";
import { CompanyProfileRouteDefintions } from "../CompanyProfileRoutes";

const CompanyProfileDetailEdit = () => {
  const routing = CoreRouting.useRouting();

  const { companyId } = useCompanyParams();
  const getCompanyProfileParams = { companyId };
  const getAddressByCompanyParams = { companyId, type: "Primary" };

  // queries
  const getCompanyQuery = CoreState.Company.getCompany.useQuery(getCompanyProfileParams, { skip: !companyId });
  const getAddressByCompanyQuery = CoreState.Address.getAddressByCompany.useQuery(getAddressByCompanyParams, { skip: !companyId });

  // mutations
  const [updateCompanyAction, updateCompanyQuery] = CoreState.Company.updateCompany.useMutation();
  const [addAddressByCompanyAction, addAddressByCompanyQuery] = CoreState.Address.addAddressByCompany.useMutation();

  // locals
  const [errors, setErrors] = useState<any>();
  const company = useSelector((s) => CoreState.Company.selectCompany(s, getCompanyProfileParams));
  const address = useSelector((s) => CoreState.Address.selectAddressByCompany(s, getAddressByCompanyParams));
  const isLoading = getCompanyQuery.isLoading || getAddressByCompanyQuery.isLoading;
  const isSaving = Boolean(updateCompanyQuery.isLoading && !updateCompanyQuery.isUninitialized);
  const isSuccess = Boolean(updateCompanyQuery.isSuccess && !updateCompanyQuery.isUninitialized);
  const updateCompanyQueryError = CoreUtils.ServiceResponseHelper.getServiceError(updateCompanyQuery);

  // handlers
  const handleSubmit = (address: CoreState.Common.Subset<CoreState.Address.Address>, company: CoreState.Common.Subset<CoreState.Company.Company>) => {
    updateCompanyAction({
      companyId,
      company
    });

    addAddressByCompanyAction({
      companyId,
      address
    })
  }

  useEffect(() => {
    const errors = CoreUtils.Converter.toFormikErrors([updateCompanyQueryError]);
    setErrors(errors);
  }, [updateCompanyQueryError])
  
  useEffect(() => {
    if (!isSuccess) return;
    routing.go(CompanyProfileRouteDefintions.COMPANY_PROFILE);
  }, [isSuccess])

  const handleClose = () => {    
    routing.go(CompanyProfileRouteDefintions.COMPANY_PROFILE);
  }

  return (
    <CompanyFormDialog
      title="Edit Company Details"
      address={address}
      company={company}
      formErrors={errors}
      isSaving={isSaving}
      isLoading={isLoading}
      onClose={handleClose}
      onSubmit={handleSubmit}
    />
  )
}
export default CompanyProfileDetailEdit;
