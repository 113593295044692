import * as yup from 'yup';

const ServiceAreaFormValidation: any = yup.object().shape({
  selectedState: yup.string().required('State is required'),
  serviceAreas: yup.object().test(
    'at-least-one-selected',
    'At least one service area must be selected',
    (serviceAreas: any) => {
      return !serviceAreas || serviceAreas.isChecked || serviceAreas.isIndeterminate
    }
  )
});

export default ServiceAreaFormValidation;