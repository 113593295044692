import * as dtos from "@build-buddy/core/dist/state/common/dtos/worksite-management.generated";
import { DatePicker } from "@mui/x-date-pickers";

const x: dtos.WorkOrderData = {}

const transformWorkOrderData = () => {
  
}


function transform() {

}





const Sandbox = () => {


  
  // const x = new Something()
  // x.doSomething();

  // const y = new SomethingElse();
  // y.doSomething().doSomething().doSomethingElse().

  return (
    <DatePicker value={new Date()} />
  )

}
export default Sandbox;