import { CoreState } from "@build-buddy/core";
import { createSelector } from "@reduxjs/toolkit";

export const selectJobDocumentsContract = createSelector(
  [CoreState.File.selectDocListByTask],
  (documents) => {
    const contractTags = ["Contract", "SignedByOwnerBuilder"]
    return documents.filter((document) => document.tags?.find(
      (tag) => contractTags.includes(tag.code)
    ))[0];
  }
)