import { CoreComponents, CoreState } from "@build-buddy/core";
import { DialogActions, DialogContent } from "@mui/material";
import { Formik } from "formik";
import CompanyProfileTNCFormValidation from "./CompanyProfileTNCFormValidation";

interface CompanyProfileTNCFormProps {
  termsAndConditions?: CoreState.Company.TermsAndConditions;
  onSubmit: (values: any) => void;
}

const CompanyProfileTNCForm = (props: CompanyProfileTNCFormProps) => {
  const { termsAndConditions } = props;

  return (
    <Formik
      initialValues={{
        content: termsAndConditions?.content || "",
      }}
      onSubmit={(values) => {
        props.onSubmit(values)
      }}
      validationSchema={CompanyProfileTNCFormValidation}
    >
      {(formik) => (
        <>
          <DialogContent>
            <CoreComponents.FormikHtmlEditor
              formik={formik}
              name="content"
            />
          </DialogContent>
          <DialogActions>
            <CoreComponents.Actions
              onSubmitClick={() => formik.handleSubmit()}
              submitText="Save"
            />
          </DialogActions>
        </>
      )}
    </Formik>
  )
}
export default CompanyProfileTNCForm;