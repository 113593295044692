import { useSelector } from 'common/state/store';
import {
  selectJobViewDocumentsTab,
  selectJobViewDescriptionTab,
  selectJobViewScopeOfWorkTab,
  selectJobViewQuotesTab,
  selectJobViewMessagesTab,
  selectJobViewCompanPaymentsTab,
  selectJobViewCompanyVariationTab,
  selectJobViewQualityAssuranceTab
} from '../_selectors';
import useJobViewParams from './useJobViewParams';
import useQueryJobMessageList from './useQueryJobMessageList';

const useJobViewTabs = () => {
  // params
  const params = useJobViewParams()
  const { projectId, taskId, quoteRequestId, companyId } = params;
  const documentParams = { projectId, taskId, companyId }
  const qualityAssuranceParams = { quoteRequestId, companyId }

  // queries
  const queryJobMessageList = useQueryJobMessageList();

  const jobViewDescriptionTab = useSelector(selectJobViewDescriptionTab);
  const jobViewDocumentsTab = useSelector((s) => selectJobViewDocumentsTab(s, documentParams));
  const jobViewScopeOfWorkTab = useSelector((s) => selectJobViewScopeOfWorkTab(s, params));
  const jobViewQuotesTab = useSelector((s) => selectJobViewQuotesTab(s, params));
  const jobViewVariationsTab = useSelector((s) => selectJobViewCompanyVariationTab(s, params));
  const jobViewMessagesTab = useSelector((s) => selectJobViewMessagesTab(s, queryJobMessageList.quoteNoteThreadListParams));
  const jobViewPaymentsTab = useSelector((s) => selectJobViewCompanPaymentsTab(s, params));
  const jobViewQualityAssuranceTab = useSelector((s) => selectJobViewQualityAssuranceTab(s, qualityAssuranceParams));

  return {
    jobViewDescriptionTab,
    jobViewDocumentsTab,
    jobViewScopeOfWorkTab,
    jobViewQuotesTab,
    jobViewVariationsTab,
    jobViewMessagesTab,
    jobViewPaymentsTab,
    jobViewQualityAssuranceTab
  }
}

export default useJobViewTabs