import { CoreUtils } from "@build-buddy/core";
import { Add as AddIcon } from "@mui/icons-material";
import { Box, Button, Typography, useTheme } from "@mui/material";
import { useFormikContext } from "formik";
import { useEffect, useState } from "react";
import JobViewQuoteFormValues from "../JobViewQuoteFormValues";
import JobViewQuoteFormPaymentScheduleFormList from "./JobViewQuoteFormPaymentScheduleFormList";
import JobViewQuoteFormPaymentScheduleTemplate from "./JobViewQuoteFormPaymentScheduleTemplate";

const JobViewQuoteFormPaymentScheduleForm = () => {
  // state
  const [total, setTotal] = useState(0)

  // hooks
  const theme = useTheme();
  const formik = useFormikContext<JobViewQuoteFormValues>();

  useEffect(() => {
    setTotal(calculate());
  }, [formik.values.paymentSchedule])

  // handlers
  const calculate = () => {
    const total = formik.values.paymentSchedule.reduce((a, b) => Number(a) + Number(b.amount), 0);
    formik.setFieldValue("totalCost", total);
    return total;
  }

  const handleAdd = () => {
    formik.setFieldValue("paymentSchedule", [...formik.values.paymentSchedule, { name: "", amount: 0, isImmediate: false, disabled: false }])
  }

  return (
    <>
      <JobViewQuoteFormPaymentScheduleFormList />
      <JobViewQuoteFormPaymentScheduleTemplate
        sx={{
          mt: 2,
          gridTemplateAreas: {
            xs: `'stage amount'`,
            md: `'stage paymentType amount actions'`,
          },
          gridTemplateColumns: {
            xs: `40% 150px`,
            sm: `50% 130px auto 25px`,
          },
        }}>
        <Typography
          variant="subtitle2"
          sx={{
            gridArea: "stage",
            fontWeight: "bold"
          }}
        >
          Total:
        </Typography>
        <Box
          sx={{
            gridArea: "amount",
            mb: 2,
            pr: 2,
            textAlign: "right"
          }}
        >
          {CoreUtils.Formatter.currency(total)}
        </Box>
      </JobViewQuoteFormPaymentScheduleTemplate>
      <Button
        variant="none"
        onClick={() => handleAdd()}
        sx={{
          mx: -3,
          px: 3,
          py: 2,
          width: `calc(100% + ${theme.spacing(6)})`,
          color: theme.palette.primary.main,
          justifyContent: "flex-start",
          borderRadius: 0,
          borderBottomRightRadius: 16,
          borderBottomLeftRadius: 16
        }}
      >
        <AddIcon sx={{ mr: 1.25 }} />
        Add payment stage
      </Button>
    </>
  )
}
export default JobViewQuoteFormPaymentScheduleForm;