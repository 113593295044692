import { CoreComponents, CoreRouting, CoreState } from "@build-buddy/core";
import { Box } from "@mui/material";
import { useSelector } from "common/state/store";
import { useCompanyParams } from "modules/company/_hooks";
import { useEffect, useState } from "react";
import { CompanyProfileTeamsRoutes, CompanyProfileTeamsRoutesDefinitions } from "./CompanyProfileTeamsRoutes";

const CompanyProfileTeams = () => {
  const routing = CoreRouting.useRouting();

  // params
  const { companyId } = useCompanyParams();
  const getMemberListByCompanyParams = { companyId };
  const getInviteListByCompanyParams = { companyId };

  // queries
  const getMemberListByCompanyQuery = CoreState.Membership.getMemberListByCompany.useQuery(getMemberListByCompanyParams);
  const getInviteListByCompanyQuery = CoreState.Membership.getInviteListByCompany.useQuery(getInviteListByCompanyParams);

  // mutations
  const [createInviteByCompanyAction, createInviteByCompanyQuery] = CoreState.Membership.createInviteByCompany.useMutation();
  const [deleteInviteByCompanyAction, deleteInviteByCompanyQuery] = CoreState.Membership.deleteInviteByCompany.useMutation();
  const [deleteMemberByCompanyAction, deleteMemberByCompanyQuery] = CoreState.Membership.deleteMemberByCompany.useMutation();

  // locals
  const [isDeleting, setIsDeleting] = useState<Set<string>>(new Set());
  const [isResending, setIsResending] = useState<Set<string>>(new Set());
  const isLoading = Boolean(getMemberListByCompanyQuery.isLoading) || Boolean(getInviteListByCompanyQuery.isLoading);
  const members = useSelector((s) => CoreState.Membership.selectMemberListByCompany(s, getMemberListByCompanyParams));
  const invites = useSelector((s) => CoreState.Membership.selectInviteListByCompany(s, getInviteListByCompanyParams));

  // events
  const handleInvite = () => {
    routing.go(CompanyProfileTeamsRoutesDefinitions.COMPANY_PROFILE_TEAMS_INVITE)
  }

  const handleEdit = (id: string) => { 
    const params = { mid: id }
    routing.go(CompanyProfileTeamsRoutesDefinitions.COMPANY_PROFILE_TEAMS_MEMBER_EDIT, { params });
  }

  const handleRemove = (id: string) => {
    setIsDeleting(isResending.add(id))
    deleteMemberByCompanyAction({ companyId, memberId: id })
  }

  const handleDelete = (id: string) => {
    setIsDeleting(isResending.add(id))
    deleteInviteByCompanyAction({ companyId, invitationId: id })
  }

  const handleResend = (invite: CoreState.Membership.Invite) => {
    setIsResending(isResending.add(invite.id))
    createInviteByCompanyAction({ companyId, invite })
  }

  // effects
  useEffect(() => {
    if (!Boolean(deleteInviteByCompanyQuery.isSuccess || deleteInviteByCompanyQuery.isError)) return;
    const id = deleteInviteByCompanyQuery.originalArgs?.invitationId || "";
    setIsDeleting((prevSet) => {
      prevSet.delete(id);
      return new Set(prevSet);
    });
  }, [deleteInviteByCompanyQuery])

  useEffect(() => {
    if (!Boolean(deleteMemberByCompanyQuery.isSuccess || deleteMemberByCompanyQuery.isError)) return;
    const id = deleteMemberByCompanyQuery.originalArgs?.memberId || "";
    setIsDeleting((prevSet) => {
      prevSet.delete(id);      
      return new Set(prevSet);
    });
  }, [deleteMemberByCompanyQuery])

  useEffect(() => {
    if (!Boolean(createInviteByCompanyQuery.isSuccess || createInviteByCompanyQuery.isError)) return;
    const id = createInviteByCompanyQuery.originalArgs?.invite.id || "";
    setIsResending((prevSet) => {
      prevSet.delete(id);      
      return new Set(prevSet);
    });
  }, [createInviteByCompanyQuery])
  
  return (
    <>
      <Box sx={{ mb: 2 }}>
        <CoreComponents.TeamMembersAccordion 
          title="Team members"
          description="Members of your business"
          members={members}
          invites={invites}
          isResending={isResending}
          isDeleting={isDeleting}
          handleAdd={() => handleInvite()}
          handleEdit={(m) => handleEdit(m.id)}
          handleRemove={(m) => handleRemove(m.id)}
          handleResend={(m) => handleResend(m)}
          handleDelete={(m) => handleDelete(m.id)}
        />
      </Box>
      <CompanyProfileTeamsRoutes />
    </>
  )
}
export default CompanyProfileTeams;