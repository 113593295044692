import { CoreModules, CoreRouting } from "@build-buddy/core";
import { ArrowBack as ArrowBackIcon } from "@mui/icons-material";
import { Box, IconButton, Stack, Typography, useTheme } from "@mui/material";
import { useJobViewParams } from "modules/company/job/job-view/_hooks";
import { CompanyRouteDefinitions } from "../CompanyRoutes";

const CompanyReviews = () => {
  const theme = useTheme();
  const { companyId } = useJobViewParams();
  const routing = CoreRouting.useRouting();

  return (
    <>
      <Box
        sx={{
          background: theme.palette.common.white,
          pt: 2,
          mt: -2,
          mx: -3,
          px: 3,
          pb: 2,
          mb: 3
        }}
      >
        <Stack direction="row" alignItems="center">
          <IconButton
            edge="start"
            sx={{ mr: 1 }}
            onClick={() => routing.go(CompanyRouteDefinitions.COMPANY_PUBLIC_PROFILE)}
          >
            <ArrowBackIcon />
          </IconButton>
          <Typography variant="h5">
            Back to profile
          </Typography>
        </Stack>
      </Box>

      <CoreModules.Company.CompanyReviews companyId={companyId} />
    </>
  )
}
export default CompanyReviews;