import { CoreComponents, CoreState } from "@build-buddy/core";
import ServiceAreaAccordionListItem from "./ServiceAreaAccordionListItem";

interface ServiceAreaAccordionListProps {
  serviceAreas?: Array<CoreState.Company.ServiceAreaTrimmed>;
  isLoading?: boolean;
  onAdd(): void;
}

const ServiceAreaAccordionList = (props: ServiceAreaAccordionListProps) => {
  const { serviceAreas, isLoading, onAdd } = props;

  return (
    <CoreComponents.PreContent
      isLoading={Boolean(isLoading)}
      isEmpty={!serviceAreas || !serviceAreas.length}
      empty={
        <CoreComponents.Empty
          buttonProps={{ label: "Add Service Area" }}
          isProfile={true}
          title="No Service Areas Added"
          description="Select the service areas your company can service to ensure you receive relevant quotes."
          sx={{ my: 4 }}
          onClick={onAdd}
        />
      }
    >
      {serviceAreas?.map((serviceArea, i) => (
        <ServiceAreaAccordionListItem
          key={i}
          serviceAreaTrimmed={serviceArea}
        />
      ))}
    </CoreComponents.PreContent>
  )
}

export default ServiceAreaAccordionList;