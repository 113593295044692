import { CoreComponents, CoreModules, CoreRouting, CoreState } from "@build-buddy/core";
import { CommonComponents, CommonState } from "common";
import { useSelector } from "common/state/store";
import JobSubView from "./JobSubView";
import { useJobViewParams, useQueryJobSpecs } from "./_hooks";
import { selectIsJobSpecsModified, selectJobStatus, selectJobTask } from "./_selectors";
import JobViewActions from "./job-view-actions/JobViewActions";
import JobViewContent from "./job-view-content/JobViewContent";
import JobViewDrawer from "./job-view-drawer/JobViewDrawer";

const POLLING_INTERVAL_IN_MILLISECONDS = 30000;

const JobView = () => {
  const routing = CoreRouting.useRouting();

  // Params
  const jobParams = useJobViewParams();
  const queryJobSpecs = useQueryJobSpecs();

  const { projectId, stageId, taskId, quoteRequestId, companyId } = jobParams;
  const qualityAssuranceParams = { companyId, quoteRequestId };
  const quoteListByCompanyParams = { projectId, quoteRequestId, companyId };

  // queries
  const quoteListByCompanyQuery = CoreState.Quote.getQuoteListByCompany.useQuery(quoteListByCompanyParams)
  const getJobQuery = CommonState.Job.getJob.useQuery({ projectId, stageId, taskId, quoteRequestId, companyId });
  const jobMessageQuery = CoreState.NoteThread.getQuoteNoteThreadList.useQuery(
    { projectId, stageId, taskId, quoteRequestId },
    { pollingInterval: POLLING_INTERVAL_IN_MILLISECONDS }
  );
  const documentsQuery = CoreState.File.getDocListByTask.useQuery({
    projectId,
    taskId,
    companyId,
  });
  const defectsQuery = CoreState.Defect.getDefectListByTask.useQuery(jobParams)
  const variationsQuery = CoreState.Variation.getProjectTaskVariationList.useQuery(jobParams)
  const specsListQUery = CoreState.Specification.getQuoteRequestSpecificationList.useQuery(jobParams);
  const jobViewQualityAssuranceChecklistQuery = CoreState.QualityAssurance.getQualityAssuranceListByQuoteRequest.useQuery(qualityAssuranceParams);

  // selectors
  const jobTask = useSelector((s) => selectJobTask(s, { projectId, stageId, taskId, quoteRequestId, companyId }));
  const jobStatus = useSelector((s) => selectJobStatus(s, { projectId, stageId, taskId, quoteRequestId, companyId }));

  // Check if SOW is modified or Not.
  const isSpecsModified = useSelector((s) => selectIsJobSpecsModified(s, queryJobSpecs.quoteRequestSpecificationListParams));
  // Show alert in only Select subtask type.
  const showSpecsModifiedAlert = isSpecsModified && (jobTask?.type === CoreState.Task.TaskType.Select);

  const dpText = jobTask?.bbProDisplayText ?? jobTask?.name;
  const displayText = jobStatus === CommonState.Job.JobStatus.Completed ? `[Completed] ${dpText}` : dpText;

  const handleClose = () => {
    routing.back();
  }

  return (
    <CoreModules.Task.TaskView.TaskView
      onClose={handleClose}
    >
      <CoreComponents.PreContent
        isLoading={getJobQuery.isLoading}
        isEmpty={false}
        loader={<CoreModules.Task.TaskView.TaskViewSkeleton onClose={handleClose} />}
      >
        <CoreComponents.Loader show={getJobQuery.isFetching} />
        <CoreModules.Task.TaskView.TaskViewHeader
          onClose={() => handleClose()}
          onNavigate={() => { }}
          tasks={[]}
          status={() => (
            <CommonComponents.JobStatus
              status={jobStatus}
              isSpecsModified={showSpecsModifiedAlert}
            />
          )
          }
          currentTask={{} as any}
        />
        <CoreModules.Task.TaskView.TaskViewContent
          name={displayText}
          description={jobTask?.description}
          actions={<JobViewActions />}
          content={<JobViewContent />}
          drawer={<JobViewDrawer />}
          subview={<JobSubView />}
        />
      </CoreComponents.PreContent>
    </CoreModules.Task.TaskView.TaskView>
  )
}

export default JobView;