import { CoreState } from "@build-buddy/core";
import { createSelector } from "@reduxjs/toolkit";

interface SelectJobQualityAssuranceParams {
  quoteRequestId: string;
  companyId: string;
}
const selectRoot = createSelector(
  [(state: CoreState.RootState, params: SelectJobQualityAssuranceParams) => CoreState.QualityAssurance.getQualityAssuranceListByQuoteRequest.select(params)(state)],
  (jobQualityAssuranceQuery) => jobQualityAssuranceQuery
)

const selectJobQualityAssuranceListByQuoteRequest = createSelector(
  selectRoot,
  (jobQualityAssuranceQuery) => jobQualityAssuranceQuery.data ?? [],
)

export const selectJobQualityAssuranceListByQuoteRequestGrouped = createSelector(
  selectJobQualityAssuranceListByQuoteRequest,
  (qualityAssuranceList) => {
    if (!qualityAssuranceList) return {};

    return qualityAssuranceList.reduce((acc, qaItem) => {
      acc[qaItem.stageName] = [...acc?.[qaItem.stageName] ?? [], qaItem];
      return acc;
    }, {} as Record<string, Array<CoreState.QualityAssurance.QualityAssurance>>)
  }
)

export const selectJobQualityAssuranceListByQuoteRequestGroupedLength = createSelector(
  selectJobQualityAssuranceListByQuoteRequestGrouped,
  (checklistRecord) => {
    return Object.values(checklistRecord ?? {})
      .flat()
      .filter(item => item.status !== CoreState.QualityAssurance.QualityAssuranceStatus.Completed && !item.isDisabled)
      .length
  }
)