import { CoreModules } from "@build-buddy/core";
import { useJobViewParams } from "../_hooks";

const JobViewDocuments = () => {
  const { projectId, taskId, companyId } = useJobViewParams();  
  return (
    <CoreModules.Task.TaskView.TaskViewDocuments 
      projectId={projectId}
      taskId={taskId}
      companyId={companyId}
    />
  )
}
export default JobViewDocuments