import {
    CompanyIcon,
    CoreHooks,
    CoreRouting,
    CoreState
} from '@build-buddy/core';

import {
    FeedbackIcon,
    Menu,
    ProfileMenu
} from "@build-buddy/core/dist/components";

import {
    Business as BusinessIcon,
    Dashboard as DashboardIcon,
    Logout as LogoutIcon,
    SupportAgent as SupportIcon,
    PersonAddAlt as PersonAddAltIcon
} from "@mui/icons-material";

import {
    Box,
    Container,
    useTheme
} from "@mui/material";

import logo from "assets/bbp-logo.png";

import RouteDefinitions from 'common/RouteDefinitions';

import { CompanyRouteDefinitions } from 'modules/company';
import { DashboardRouteDefinitions } from 'modules/company/dashboard';
import { InvitationRouteDefinitions } from "modules/invitation";
import { UserProfileRouteDefinitions } from 'modules/user/profile';

import { FC, useState } from "react";

import { useLocation } from "react-router";
import { Link } from "react-router-dom";

import { CommonState } from 'common';
import Header from "./Header";
import { useSelector } from 'common/state/store';

interface StandardProps {
  children: any;
}

function getUserName(user: CoreState.User.User | undefined) {
  return user?.firstName || user?.lastName
    ? `${user.firstName || ''} ${user.lastName || ''}`.trim()
    : (user?.email || "-")
}

const Standard: FC<StandardProps> = (props: StandardProps) => {
  // local state
  const [open, setOpen] = useState(false);
  const [avatarEl, setAvatarEl] = useState<Element | null>(null);

  // hooks
  const routing = CoreRouting.useRouting();
  const mode = CoreHooks.useMode();
  const location = useLocation();
  const theme = useTheme();

  // selectors
  const user = useSelector(CoreState.User.selectCurrentUser);
  const companyId = useSelector(CommonState.App.selectCurrentCompanyId);
  const avatar = useSelector((state) => CoreState.File.selectAvatarByUser(state, { userId: user?.id || "" }));
  const userHasAvatar = useSelector(CoreState.User.selectCurrentUserHasAvatar);

  // queries
  const getAvatarByUserQuery = CoreState.File.getAvatarByUser.useQuery({ userId: user?.id }, { skip: (!user?.id || !userHasAvatar) });

  // vars
  const userName = getUserName(user);

  const dashaboardActive = ["/dashboard"].some((s) => location.pathname.includes(s));

  const menuItems = [
    {
      icon: DashboardIcon,
      label: "Dashboard",
      active: dashaboardActive,
      disabled: false,
      onClick: () => {
        const params = { cid: companyId };
        routing.go(DashboardRouteDefinitions.DASHBOARD_ROOT, { params });
      }
    },
    {
      icon: BusinessIcon,
      label: "Business Profile",
      active: ["/profile"].some((s) => location.pathname.includes(s)),
      disabled: false,
      onClick: () => {
        const params = { cid: companyId };
        routing.go(CompanyRouteDefinitions.COMPANY_PROFILE, { params })
      },
    },
    {
      icon: CompanyIcon,
      label: "Public Business Profile",
      active: ["/public-profile"].some((s) => location.pathname.includes(s)),
      disabled: false,
      onClick: () => {
        const params = { cid: companyId };
        routing.go(CompanyRouteDefinitions.COMPANY_PUBLIC_PROFILE, { params })
      },
    },
  ];

  const footerMenuItems = [
    // {
    //   icon: SupportIcon,
    //   label: "Support",
    //   active: Boolean([RouteDefinitions.Help.SUPPORT.path].some((s) => location.pathname.includes(s))),
    //   disabled: false,
    //   onClick: () => {
    //     routing.go(RouteDefinitions.Help.SUPPORT)
    //   }
    // },
    {
      icon: FeedbackIcon,
      label: "Submit Feedback",
      active: Boolean([RouteDefinitions.Help.SUBMIT_FEEDBACK.path].some((s) => location.pathname.includes(s))),
      disabled: false,
      onClick: () => {
        routing.go(RouteDefinitions.Help.SUBMIT_FEEDBACK)
      }
    }
  ]

  if (mode.isMobile) {
    footerMenuItems.push({
      icon: LogoutIcon as any,
      label: "Logout",
      active: false,
      disabled: false,
      onClick: () => null,
    })
    footerMenuItems.unshift({
      icon: PersonAddAltIcon as any,
      label: "Business Invitations",
      active: ["/invitation"].some((s) => location.pathname.includes(s)),
      disabled: false,
      onClick: () => {
        const params = { cid: companyId };
        routing.go(InvitationRouteDefinitions.INVITATION, { params });
      }
    })
  }

  if (!user) return null;

  return (
    <Container
      maxWidth={
        false}
      sx={{
        pb: theme.variables.mobileNavbarSpacing
      }}
    >
      <Header
        avatar={avatar}
        onMenuClick={() => setOpen(!open)}
        onAvatarClick={(e) => {
          setAvatarEl(e.currentTarget);
        }}
      />
      <Menu
        user={{
          name: userName,
          avatar,
          createdDate: user.createdDate,
          profileLink: UserProfileRouteDefinitions.USER_PROFILE.path
        }}
        open={open}
        logo={<Link to="/">
          <img
            src={logo}
            style={{ width: "30px" }}
            alt="logo"
          />
        </Link>}
        onClose={() => {
          setOpen(false);
        }}
        menuItems={menuItems}
        footerItems={footerMenuItems}
        onAvatarClick={(e) => {
          setAvatarEl(e.currentTarget);
        }}
        onNotificationsClick={() => null}
      />
      <ProfileMenu
        user={{
          name: userName,
          avatar,
          createdDate: user.createdDate,
        }}
        organisationId={companyId}
        invitationType="Business Invitations"
        anchor={avatarEl}
        onClose={() => setAvatarEl(null)}
        onClick={() => routing.go(InvitationRouteDefinitions.INVITATION, { params: { cid: companyId } })}
        onProfileViewClick={() => routing.go(UserProfileRouteDefinitions.USER_PROFILE)}
      />
      <Box
        sx={{
          ml: {
            xs: 0,
            md: theme.variables.drawerWidth / 8,
          },
          mt: mode.md ? 0 : 10,
        }}
      >
        {props.children}
      </Box>
    </Container>
  );
};

export default Standard;
