import { CoreComponents, CoreState } from "@build-buddy/core";
import { Box, Card, CardContent, Icon, Typography, } from "@mui/material";
import { CommonState } from "common";
import { useSelector } from "common/state/store";
import DashboardBrowseJobList from "./DashboardBrowseJobList";

const DashboardBrowse = () => {
  const currentCompanyId = useSelector(CommonState.App.selectCurrentCompanyId);
  const isUserLimited = useSelector(CoreState.User.selectCurrentUserIsLimited);

  return (
    <Card>
      <CardContent
        sx={{
          pb: "0 !important",
          position: "relative"
        }}
      >
        {isUserLimited &&
          <CoreComponents.DisabledContent
            title="Complete Profile"
            subTitle="In order to use this site, please complete your profile and contact Build Buddy on 1300 990 990 to complete the onboarding process."
            sx={{
              position: "absolute",
              background: "rgba(255,255,255,0.85)",
              zIndex: 999,
              m: -3,
            }}
          />
        }
        <Typography variant="h2" sx={{ mb: 1 }}> Browse Tasks </Typography>

        <CoreComponents.Tabs
          sx={{
            flex: 1,
            display: "flex",
            flexDirection: "column"
          }}
          routes={[
            {
              label: "New Tasks",
              path: `/company/${currentCompanyId}/dashboard/new-tasks`,
              component: () => <DashboardBrowseJobList />
            },
            {
              label: "Saved",
              path: `/company/${currentCompanyId}/dashboard/saved-tasks`,
              component: () => <DashboardBrowseJobList />
            },
            {
              label: "Active",
              path: `/company/${currentCompanyId}/dashboard/active`,
              component: () => <DashboardBrowseJobList />
            },
            {
              label: "Completed",
              path: `/company/${currentCompanyId}/dashboard/completed`,
              component: () => <DashboardBrowseJobList />
            }
          ]}
          TabsProps={{
            sx: {
              mx: -3,
              px: 1.5
            }
          }}
        />

      </CardContent>
    </Card>
  )
}
export default DashboardBrowse;

