import { CoreState, CoreComponents } from "@build-buddy/core";
import { Theme } from "@emotion/react";
import { SxProps } from "@mui/material";

type JobViewQualityAssuranceDefectsDotProgressProps = {
  status: CoreState.Defect.DefectStatus;
  sx?: SxProps<Theme>
  size?: number;
  hideLabel?: boolean;
}
const JobViewQualityAssuranceDefectsDotProgress = (props: JobViewQualityAssuranceDefectsDotProgressProps) => {
  const { status, size, hideLabel, sx } = props;

  const na = {};
  const success = { color: "success" };
  const primary = { color: "primary" };
  const primaryOutlined = { color: "primary", variant: "outlined" };
  const error = { color: "error" };

  const kvp: any = {
    [CoreState.Defect.DefectStatus.AwaitingApproval]: {
      color: [success, na, na, na],
      label: "Raised"
    },
    [CoreState.Defect.DefectStatus.Approved]: {
      color: [success, primary, na, na],
      label: "Accepted"
    },
    [CoreState.Defect.DefectStatus.Rejected]: {
      color: [success, success, primaryOutlined, na],
      label: "Review rejected"
    },
    [CoreState.Defect.DefectStatus.InReview]: {
      color: [success, success, success, na],
      label: "Awaiting sign-off"
    },
    [CoreState.Defect.DefectStatus.InDispute]: {
      color: [success, primary, na, na],
      label: "Disputed"
    },
    [CoreState.Defect.DefectStatus.Completed]: {
      color: [success, success, success, success],
      label: "Completed"
    },
    [CoreState.Defect.DefectStatus.Cancelled]: {
      color: [error, error, error, error],
      label: "Cancelled"
    }
  }
  
  return (
    <CoreComponents.DotProgress
      label={hideLabel ? "" : kvp[status].label}
      statuses={kvp[status].color}
      size={size}
      sx={{ ...sx }}
    />
  )
}
export default JobViewQualityAssuranceDefectsDotProgress;