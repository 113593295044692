import { useFormik } from "formik"
import { Alert, Box, Button, Stack } from "@mui/material"
import { Done as DoneIcon, Close as CloseIcon } from "@mui/icons-material";
import { CoreComponents, CoreState, CoreUtils } from "@build-buddy/core"
import { useJobViewParams } from "modules/company/job/job-view/_hooks";
import { useEffect, useState } from "react";

type JobViewQuoteFormTaskListEditProps = {
  id: string;
  name: string;
  startDate: Date;
  endDate: Date;
  onCancel(): void;
  onConfirm(values: any): void;
}
const JobViewQuoteFormTaskListEdit = (props: JobViewQuoteFormTaskListEditProps) => {
  const { taskId, projectId, companyId } = useJobViewParams();
  
  // mutations
  const [checkDatesTaskAction, checkDatesTaskQuery] = CoreState.Task.checkDatesTask.useMutation();

  // locals
  const [error, setError] = useState<string | undefined>();
  const isSuccess = Boolean(checkDatesTaskQuery.isSuccess);
  const isError = Boolean(checkDatesTaskQuery.isError);

  const formik = useFormik({
    initialValues: {
      startDate: props.startDate,
      endDate: props.endDate
    },
    onSubmit: (values) => {
      checkDatesTaskAction({
        projectId,
        parentTaskId: taskId,
        taskId: props.id,
        startDate: values.startDate,
        endDate: values.endDate,
        companyId
      })
    }
  })

  useEffect(() => {
    if (!isSuccess) return;
    props.onConfirm(formik.values);
  }, [isSuccess])

  useEffect(() => {
    if (!isError) return;
    setError("The dates are invalid");
  }, [isError])

  return (
    <>
      {Boolean(error) &&
        <Alert
          severity="error"
          variant="filled"
          sx={{ mb: 2 }}
          onClose={() => setError("")}
        >
          {error}
        </Alert>
      }
      <Stack
        direction="row"
        alignItems="center"
      >
        <CoreComponents.Loader show={checkDatesTaskQuery.isLoading} />
        <CoreComponents.DatePickerRange
          formik={formik}
          StartProps={{
            id: "startDate",
            label: "Start Date",
            sx: { mr: 2 }
          }}
          EndProps={{
            id: "endDate",
            label: "End Date",
            sx: { mr: 2 }
          }}
        />
        <Box>
          <Button
            variant="outlined"
            color="neutral"
            sx={{
              p: { xs: 1 },
              minWidth: { xs: "auto", md: "inherit" },
              mr: 0.75
            }}
            onClick={props.onCancel}
          >
            <CloseIcon />
          </Button>
          <Button
            variant="contained"
            sx={{
              p: { xs: 1 },
              minWidth: { xs: "auto", md: "inherit" },
            }}
            onClick={() => formik.handleSubmit()}
          >
            <DoneIcon />
          </Button>
        </Box>
      </Stack>
    </>
  )
}

export default JobViewQuoteFormTaskListEdit;