import { CoreState, TypeGuards } from "@build-buddy/core";
import Job from "./models/Job";
import transformJob from "./transformers/transformJob";
import transformTask from "@build-buddy/core/dist/state/task/transformers/transformTask";
import transformQuoteRequest from "@build-buddy/core/dist/state/quote/transformers/transformQuoteRequest";
import transformQuote from "@build-buddy/core/dist/state/quote/transformers/transformQuote";

type GetJobParams = {
  companyId: string;
  projectId: string;
  stageId: string;
  taskId: string;
  quoteRequestId: string;
}
const getJob = CoreState.ApiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getJob: builder.query<Job | null, GetJobParams>({
      queryFn: async (params, _queryApi, _extraOptions, fetchWithBaseQuery): Promise<any> => {
        // normally we would do something like this
        // _queryApi.dispatch(CoreState.Task.getProjectTask.initiate({ ... }))
        // but there is a bug in rtkQuery with how "pending" works which is not making this work
        // https://github.com/reduxjs/redux-toolkit/issues/1915        
        const taskFetch = fetchWithBaseQuery({
          url: [
            `${CoreState.baseUrl("worksite")}/worksite-management/v1/projects/${params.projectId}`,
            `/tasks/${params.taskId}`
          ].join(""),
          method: "GET"
        })

        const quoteRequestFetch = fetchWithBaseQuery({
          url: [
            `${CoreState.baseUrl("worksite")}/worksite-management/v1/projects/${params.projectId}`,
            `/tasks/${params.taskId}`,
            `/quoteRequests/${params.quoteRequestId}`
          ].join(""),
          method: "GET"
        });

        const workOrdersFetch = fetchWithBaseQuery({
          url: [
            `${CoreState.baseUrl("worksite")}/worksite-management/v1/projects/${params.projectId}`,
            `/quoteRequests/${params.quoteRequestId}`,
            `/workOrders`,
            `/organisation`
          ].join(""),
          method: "GET",
          headers: {
            OrganisationPublicId: params.companyId
          }
        })

        const [taskResp, quoteRequestResp, workOrdersResp] = await Promise.all([taskFetch, quoteRequestFetch, workOrdersFetch]);

        if (taskResp.error || quoteRequestResp.error || workOrdersResp.error) return { data: null };

        // map taskDto into task
        const taskDto = taskResp.data as CoreState.Common.DTO.TaskDTO;
        const taskList = transformTask.toModel(taskDto);
        const currentTask = taskList?.find(x => x.isCurrent);

        // map quoteRequestDto in taskQuoteRequest
        const quoteRequestDto = quoteRequestResp.data as CoreState.Common.DTO.QuoteRequestDTO;
        const quoteRequest = transformQuoteRequest.toModel(quoteRequestDto);

        // map workOrdersDto in taskQuotes. Then find the taskQuote based off the taskQuoteRequestId
        const workOrderDtos = workOrdersResp.data as Array<CoreState.Common.DTO.WorkOrderDTO>;
        const quotes = workOrderDtos.map(wo => transformQuote.toModel(wo)).filter(TypeGuards.nonNullable);

        // create the model
        const model = transformJob.toModel(currentTask, quoteRequest, quotes);

        return { data: model };
      },
      providesTags: [
        CoreState.Task.TaskTags.Task,
        CoreState.Quote.QuoteTags.Quote,
        CoreState.Quote.QuoteTags.QuoteRequest,
      ]
    }),
  }),
})

export default getJob.endpoints.getJob;
