import { CoreComponents, CoreState } from "@build-buddy/core";
import { JobStatus } from "common/state/job";
import { useSelector } from "common/state/store";
import { useJobViewTabs, useQueryJob } from "../_hooks";
import { selectJob, selectJobQuotePaymentSchedule, selectJobRequest, selectJobStatus, selectJobTask, selectShowVariationsTab } from "../_selectors";
import JobViewContentAddress from "./JobViewContentAddress";
import JobViewContentDetails from "./JobViewContentDetails";

const useVm = () => {
  const jobViewTabs = useJobViewTabs()
  // queries
  const queryJob = useQueryJob();

  // selectors
  const job = useSelector((s) => selectJob(s, queryJob.jobParams));
  const jobTask = useSelector((s) => selectJobTask(s, queryJob.jobParams));
  const jobRequest = useSelector((s) => selectJobRequest(s, queryJob.jobParams));
  const jobStatus = useSelector((s) => selectJobStatus(s, queryJob.jobParams));
  const paymentSchedule = useSelector((s) => selectJobQuotePaymentSchedule(s, queryJob.jobParams))
  const showVariationsTab = useSelector((s) => selectShowVariationsTab(s, queryJob.jobParams));

  const tabs = (() => {
    if (!job) return [];

    const rtn = [
      jobViewTabs.jobViewDescriptionTab,
      jobViewTabs.jobViewScopeOfWorkTab,
      jobViewTabs.jobViewDocumentsTab,
      ...(jobStatus !== JobStatus.Closed ? ([jobViewTabs.jobViewMessagesTab]) : ""),
    ]

    const showQuoteTabs = Boolean(job.quotes);

    const showPayTabs = Boolean(paymentSchedule) && job.task.type !== CoreState.Task.TaskType.Select;

    if (showQuoteTabs) rtn.push(jobViewTabs.jobViewQuotesTab)
    if (showVariationsTab) rtn.push(jobViewTabs.jobViewVariationsTab)
    if (showPayTabs) rtn.push(jobViewTabs.jobViewPaymentsTab)

    rtn.push(jobViewTabs.jobViewQualityAssuranceTab)

    return rtn;
  })();

  return {
    isLoading: jobRequest.isLoading,
    task: jobTask,
    tabs
  }
}

const JobViewContent = () => {
  const vm = useVm();

  if (!vm.tabs) return null;

  return (
    <>
      <JobViewContentAddress />
      <JobViewContentDetails />
      <CoreComponents.Tabs
        sx={{
          flex: 1,
          display: "flex",
          flexDirection: "column"
        }}
        routes={vm.tabs}
        TabsProps={{
          sx: {
            mx: -3,
            px: 1.5
          }
        }}
      />
    </>
  )
}
export default JobViewContent;