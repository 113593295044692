import { CoreComponents, CoreRouting, CoreState } from "@build-buddy/core";
import { DialogActions, DialogContent } from "@mui/material";
import { Formik } from "formik";
import { useCompanyParams } from "modules/company/_hooks";
import { useEffect } from "react";
import { CompanyProfileRouteDefintions } from "../CompanyProfileRoutes";
import CompanyProfileBrochureEditFormValidation from "./CompanyProfileBrochureEditFormValidation";

interface CompanyProfileBrochureEditFormProps {
  brochure?: CoreState.Company.Brochure;
}
const CompanyProfileBrochureEditForm = (props: CompanyProfileBrochureEditFormProps) => {
  const { brochure } = props;
  const routing = CoreRouting.useRouting();
  const { companyId } = useCompanyParams();

  const [updateBrochureByCompanyAction, updateBrochureByCompanyQuery] = CoreState.Company.updateBrochureByCompany.useMutation();
  const [uploadDocListByCompanyBrochureAction, uploadDocListByCompanyBrochureQuery] = CoreState.File.uploadDocListByCompanyBrochure.useMutation();
  const [deleteDocListByCompanyBrochureAction, deleteDocListByCompanyBrochureQuery] = CoreState.File.deleteDocListByCompanyBrochure.useMutation();

  const isSaving =
    Boolean(updateBrochureByCompanyQuery.isLoading) ||
    Boolean(uploadDocListByCompanyBrochureQuery.isLoading) ||
    Boolean(deleteDocListByCompanyBrochureQuery.isLoading)

  const isSuccess =
    Boolean(updateBrochureByCompanyQuery.isSuccess) &&
    Boolean(uploadDocListByCompanyBrochureQuery.isUninitialized || uploadDocListByCompanyBrochureQuery.isSuccess) &&
    Boolean(deleteDocListByCompanyBrochureQuery.isUninitialized || deleteDocListByCompanyBrochureQuery.isSuccess);

  useEffect(() => {
    if(!isSuccess) return;
    routing.go(CompanyProfileRouteDefintions.COMPANY_PROFILE);
  }, [isSuccess]);

  return (
    <Formik
      initialValues={{
        content: brochure?.content || "",
        files: {
          toSave: brochure?.documents || [],
          toRemove: [],
        },
      }}
      onSubmit={async (values) => {
        const promises = [];
        const newBrochure: CoreState.Common.Subset<CoreState.Company.Brochure> = {
          content: values.content,
          version: brochure?.version,
        }
        promises.push(updateBrochureByCompanyAction({ companyId, brochure: newBrochure }));

        if (values.files.toSave.length) {
          promises.push(uploadDocListByCompanyBrochureAction({ companyId, docs: values.files.toSave }))
        }

        if (values.files.toRemove.length) {
          promises.push(deleteDocListByCompanyBrochureAction({ companyId, docs: values.files.toRemove }))
        }

        await Promise.all(promises);
      }}
      validationSchema={CompanyProfileBrochureEditFormValidation}
    >
      {(formik) => (
        <>
          <DialogContent>
            <CoreComponents.Loader show={isSaving} />
            <CoreComponents.FormikHtmlEditor
              name="content"
            />
            <CoreComponents.FormikUploadFile
              name="files"
              acceptFiles={[".jpg", ".jpeg", ".gif", ".png"]}
              handleDownload={() => { }}
            />
          </DialogContent>
          <DialogActions>
            <CoreComponents.Actions
              onSubmitClick={() => formik.handleSubmit()}
              submitText="Save"
            />
          </DialogActions>
        </>
      )}
    </Formik>
  )
}
export default CompanyProfileBrochureEditForm;