import { CoreModules } from "@build-buddy/core";
import { CommonState } from "common";
import { useSelector } from "common/state/store";

const UserProfile = () => {
  const companyId = useSelector(CommonState.App.selectCurrentCompanyId);
  
  return (
    <CoreModules.User.UserProfile
      companyId={companyId}
    />
  )
}

export default UserProfile;