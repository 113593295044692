import { CoreComponents, CoreRouting, CoreState } from "@build-buddy/core";
import { Dialog } from "@mui/material";
import { useCompanyParams } from "modules/company/_hooks";
import { CompanyProfileRouteDefintions } from "../CompanyProfileRoutes";
import { useSelector } from "react-redux";
import CompanyProfileTNCEditForm from "./CompanyProfileTNCForm";
import { useEffect } from "react";

const CompanyProfileTNCAdd = () => {
  const routing = CoreRouting.useRouting();

  // params
  const { companyId } = useCompanyParams();
  const getTermsAndConditionsByCompanyParams = { companyId };
  
  // queries
  const getTermsAndConditionsByCompanyQuery = CoreState.Company.getTermsAndConditionsByCompany.useQuery(getTermsAndConditionsByCompanyParams)

  const isLoading = getTermsAndConditionsByCompanyQuery.isLoading;
  const tnc = useSelector((s: CoreState.RootState) => CoreState.Company.selectTermsAndConditionsByCompany(s, getTermsAndConditionsByCompanyParams));

  const handleClose = () => {
    routing.go(CompanyProfileRouteDefintions.COMPANY_PROFILE);
  }

  // mutations
  const [addTermsAndConditionsByCompanyAction, addTermsAndConditionsByCompanyQuery] = CoreState.Company.addTermsAndConditionsByCompany.useMutation();

  // locals
  const isSaving = Boolean(addTermsAndConditionsByCompanyQuery.isLoading)
  const isSuccess = Boolean(addTermsAndConditionsByCompanyQuery.isSuccess)

  const onSubmit= async(values: any) => {
    const tnc: CoreState.Common.Subset<CoreState.Company.TermsAndConditions> = {
      id: values.termsAndConditions?.id,
      content: values.content,
    }
    addTermsAndConditionsByCompanyAction({ companyId, tnc });
  }

  useEffect(() => {
    if (!isSuccess) return;
    getTermsAndConditionsByCompanyQuery.refetch();
    routing.go(CompanyProfileRouteDefintions.COMPANY_PROFILE);
  }, [isSuccess]);

  return (
    <Dialog open={true} onClose={() => handleClose()}>
      <CoreComponents.Loader show={isSaving} />
      <CoreComponents.DialogHeader onClose={() => handleClose()} title="Add terms and conditions" />
      <CoreComponents.PreContent
        isLoading={isLoading}
        isEmpty={false}
      >
        <CompanyProfileTNCEditForm 
          termsAndConditions={tnc}
          onSubmit={onSubmit}
        />
      </CoreComponents.PreContent>
    </Dialog>
  )
}
export default CompanyProfileTNCAdd;