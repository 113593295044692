import { CoreRouting } from "@build-buddy/core";
import OtherRouteDefinitions from "./OtherRouteDefinitions";

const RootOtherRoutes = [OtherRouteDefinitions.SANDBOX];

const OtherRoutes = () => {
  return <CoreRouting.Routes routes={RootOtherRoutes} />;
};

export default OtherRoutes;
