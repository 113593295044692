import { CoreComponents, CoreState } from "@build-buddy/core";
import { Subset } from "@build-buddy/core/dist/state/common";
import { Box, DialogActions, DialogContent, MenuItem, Stack } from "@mui/material";
import { useSelector } from "common/state/store";
import { useFormik } from "formik";
import { useEffect } from "react";
import CompanyFormDialogContentValidation from "./CompanyFormDialogContentValidation";

interface CompanyFormDialogContentProps {
  isSaving: boolean;
  formErrors: Record<string, string>;
  company?: CoreState.Company.Company;
  address?: CoreState.Address.Address;
  onSubmit(address: Subset<CoreState.Address.Address>, company: Subset<CoreState.Company.Company>): void;
  onClose(): void;
}

function loadInitialValues(currentUserEmail: string, currentUserMobile: string, company?: CoreState.Company.Company, address?: CoreState.Address.Address) {
  return {
    name: company?.name || "",
    type: company?.type || "",
    abn: company?.abn || "",
    gstStatus: company?.gstRegistered || "",
    address: {
      search: address?.fullAddress || "",
      number: address?.number || "",
      street1: address?.street1 || "",
      street2: address?.street2 || "",
      suburb: address?.suburb || "",
      state: address?.state || "",
      postcode: address?.postcode || "",
      country: address?.country || "",
      longitude: address?.longitude,
      latitude: address?.latitude,
    },
    isPersonalEmail: company?.usePersonalEmail ?? false,
    isPersonalPhone: company?.usePersonalContactNumber ?? false,
    contactNumber: company?.contactNumber,
    email: company?.email,
    publicPhone: company?.publicContactNumber,
    publicEmail: company?.publicEmailAddress,
    website: company?.website,
    current: {
      email: currentUserEmail,
      mobile: currentUserMobile,
    }
  }
}

const CompanyFormDialogContent = (props: CompanyFormDialogContentProps) => {
  const {
    isSaving,
    formErrors,
    company,
    address,
    onSubmit,
    onClose,
  } = props;

  const currentUserMobile = useSelector(CoreState.User.selectCurrentUserMobile);
  const currentUserEmail = useSelector(CoreState.User.selectCurrentUserEmail);

  const formik = useFormik({
    initialValues: loadInitialValues(currentUserEmail, currentUserMobile, company, address),
    onSubmit: (values: any) => {
      const companyValues: CoreState.Common.Subset<CoreState.Company.Company> = {
        name: values.name,
        type: values.type,
        abn: values.abn,
        gstRegistered: values.gstStatus,
        usePersonalEmail: values.isPersonalEmail,
        usePersonalContactNumber: values.isPersonalPhone,
        contactNumber: values.contactNumber,
        email: values.email,
        publicContactNumber: values.publicPhone,
        publicEmailAddress: values.publicEmail,
        website: values.website,
        version: company?.version,
        flags: company?.flags
      };

      const address: CoreState.Common.Subset<CoreState.Address.Address> = {
        number: values.address.number,
        street1: values.address.street1,
        street2: values.address.street2,
        suburb: values.address.suburb,
        state: values.address.state,
        postcode: values.address.postcode,
        country: values.address.country,
        longitude: values.address.longitude,
        latitude: values.address.latitude,
        type: "Primary"
      }
      onSubmit(address, companyValues);
    },
    validationSchema: CompanyFormDialogContentValidation,
  });

  const handlePlaceChange = (place: any) => {
    formik.setFieldValue("address", {
      search: place.search,
      number: place.number,
      street1: [place.streetNumber, place.street].filter(Boolean).join(" "),
      street2: place.street2,
      suburb: place.suburb,
      state: place.state,
      postcode: place.postcode,
      country: place.country,
      longitude: place.longitude,
      latitude: place.latitude,
    });
  };

  const handlePlaceTextChange = () => {
  }

  useEffect(() => {
    if (!formErrors || !Object.keys(formErrors).length) return;
    formik.setErrors(formErrors)
  }, [formErrors])

  useEffect(() => {
    if (!formik.values.isPersonalEmail) return;
    if (formik.values.email === currentUserEmail) return;
    formik.setFieldValue("email", currentUserEmail);
  }, [formik.values.isPersonalEmail])

  useEffect(() => {
    if (!formik.values.isPersonalPhone) return;
    if (formik.values.contactNumber === currentUserMobile) return;
    formik.setFieldValue("contactNumber", currentUserMobile);
  }, [formik.values.isPersonalPhone])

  return (
    <>
      <CoreComponents.Loader show={isSaving} />
      <DialogContent>
        <CoreComponents.FormikErrorFocus formik={formik} />
        <CoreComponents.FormikTextbox
          formik={formik}
          label="Business Name*"
          name="name"
          fullWidth
        />
        <CoreComponents.FormikAutocompletePlace
          formik={formik}
          name="address.search"
          label="Business Address*"
          onPlaceChange={handlePlaceChange}
          onTextChange={handlePlaceTextChange}
        />
        <CoreComponents.FormikSelect
          formik={formik}
          label="Business Type*"
          name="type"
          fullWidth
        >
          <MenuItem value="Sole Trader"> Sole Trader </MenuItem>
          <MenuItem value="Trust"> Trust </MenuItem>
          <MenuItem value="Partnership"> Partnership </MenuItem>
          <MenuItem value="Company">Company</MenuItem>
        </CoreComponents.FormikSelect>
        <Stack direction="row" columnGap={1}>
          <CoreComponents.FormikTextbox
            formik={formik}
            label="ABN*"
            name="abn"
            fullWidth
          />
          <CoreComponents.FormikSelect
            formik={formik}
            displayEmpty={true}
            label="GST Status*"
            name="gstStatus"
            fullWidth
          >
            <MenuItem value="true"> Registered </MenuItem>
            <MenuItem value="false"> Unregistered </MenuItem>
          </CoreComponents.FormikSelect>
        </Stack>
        <Box>
          <CoreComponents.Label
            label="Company Contact Email*"
            tooltip="This email will be used for quote requests and business notifications from Build Buddy. You may want to set up a dedicated email address. Eg. notifications@buildbuddy.au"
          />
          <CoreComponents.FormikCheckbox
            formik={formik}
            name="isPersonalEmail"
            label="Use my personal email"
          />
          <CoreComponents.FormikTextbox
            formik={formik}
            name="email"
            disabled={Boolean(formik.values.isPersonalEmail)}
            fullWidth
          />
        </Box>
        <Box>
          <CoreComponents.Label
            label="Company Contact Number*"
            tooltip="This number will be used for quote requests and business SMS notifications from Build Buddy."
          />
          <CoreComponents.FormikCheckbox
            formik={formik}
            name="isPersonalPhone"
            label="Use my personal number"
          />
          <CoreComponents.FormikMobileNumber
            formik={formik}
            name="contactNumber"
            disabled={Boolean(formik.values.isPersonalPhone)}
          />
        </Box>
        <CoreComponents.FormikTextbox
          formik={formik}
          label="Company Public Email"
          name="publicEmail"
          tooltip="This email will be featured in your company profile and serves as the primary means for new customers to initiate contact with you. Eg. sales@buildbuddy.au"
          fullWidth
        />
        <CoreComponents.FormikMobileNumber
          formik={formik}
          label="Company Public Contact Number"
          name="publicPhone"
          tooltip="This number will be featured in your company profile and serves as the primary means for new customers to initiate contact with you."
        />
        <CoreComponents.FormikTextbox
          formik={formik}
          name="website"
          label="Website"
          fullWidth
        />
      </DialogContent>
      <DialogActions>
        <CoreComponents.Actions
          onCancelClick={() => onClose()}
          onSubmitClick={() => formik.handleSubmit()}
          submitText="Save"
        />
      </DialogActions>
    </>
  )
}

export default CompanyFormDialogContent;