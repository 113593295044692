import CompanyProfileBrochure from "./company-profile-brochure/CompanyProfileBrochure";
import CompanyProfileDetail from "./company-profile-detail/CompanyProfileDetail";
import CompanyProfileInsurance from "./company-profile-insurance/CompanyProfileInsurance";
import CompanyProfileServiceAreas from "./company-profile-service-areas/CompanyProfileServiceAreas";
import CompanyProfileSettings from "./company-profile-settings/CompanyProfileSettings";
import CompanyProfileSkill from "./company-profile-skill/CompanyProfileSkill";
import CompanyProfileTeams from "./company-profile-teams/CompanyProfileTeams";
import CompanyProfileTNC from "./company-profile-tnc/CompanyProfileTNC";

const CompanyProfile = () => {
  return (
    <>
      <CompanyProfileDetail />
      <CompanyProfileInsurance />
      <CompanyProfileSkill />
      <CompanyProfileBrochure />
      <CompanyProfileTNC />
      <CompanyProfileServiceAreas />
      <CompanyProfileTeams />
      <CompanyProfileSettings />
    </>
  )
}
export default CompanyProfile;