import { CoreState } from "@build-buddy/core";
import { Chip } from "@mui/material";

interface JobViewQuoteListItemStatusProps {
  status: CoreState.Quote.QuoteStatus
}
const JobViewQuoteListItemStatus = (props: JobViewQuoteListItemStatusProps) => {
  const { status } = props;
    
  const kvpChip: any = {
    [CoreState.Quote.QuoteStatus.Accepted]: {
      color: "success",
      label: "Accepted"
    },
    [CoreState.Quote.QuoteStatus.AwaitingConfirmation]: {
      color: "info",
      label: "Awaiting Confirmation"
    },
    [CoreState.Quote.QuoteStatus.Declined]: {
      color: "error",
      label: "Declined"
    },
  }

  const chip = kvpChip[status];

  if(!chip) return null;

  return (
    <Chip 
      size="small" 
      label={chip.label} 
      color={chip.color}
      sx={{ fontWeight: "bold", textTransform: "uppercase" }}
    />
  )
}
export default JobViewQuoteListItemStatus;