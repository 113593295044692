import { getIn, useFormikContext } from "formik"
import { Box, useTheme } from "@mui/material"
import { CoreComponents } from "@build-buddy/core"
import JobViewQuoteFormValues from "../JobViewQuoteFormValues";
import JobViewQuoteFormTaskListView from "./JobViewQuoteFormTaskListView";
import JobViewQuoteFormTaskListEdit from "./JobViewQuoteFormTaskListEdit";

type JobViewQuoteFormTaskListItemProps = {
  name: string;
  isEdit: boolean;
  isChanged: boolean;
  isForecast: boolean;
  isReadonly: boolean;
  isOpen?: boolean;
  currentItem: number;
  onChange(values: any): void;
  onCancel(): void;
  onRevert(): void;
  onEdit(): void;
  onOpen?(): void;
}
const JobViewQuoteFormTaskListItem = (props: JobViewQuoteFormTaskListItemProps) => {
  const theme = useTheme();
  const formik = useFormikContext<JobViewQuoteFormValues>();
  const id = getIn(formik.values, `${props.name}.id`);
  const startDate = getIn(formik.values, `${props.name}.startDate`)
  const endDate = getIn(formik.values, `${props.name}.endDate`);
  const bg = props.isChanged ? theme.palette.orange[200] : "inherit";

  return (
    <>
      <Box
        sx={{
          mx: -3,
          py: 2,
          px: 3,
          borderTop: `solid 1px ${theme.palette.grey[200]}`,
          background: bg,
          position: "relative"
        }}
      >
        <CoreComponents.TaskLabel
          label={formik.values.tasks[props.currentItem].name}
        />

        {props.isEdit &&
          <JobViewQuoteFormTaskListEdit
            id={id}
            name={props.name}
            startDate={startDate}
            endDate={endDate}
            onConfirm={props.onChange}
            onCancel={props.onCancel}
          />
        }

        {!props.isEdit &&
          <JobViewQuoteFormTaskListView
            name={props.name}
            startDate={startDate}
            endDate={endDate}
            isForecast={props.isForecast}
            isChanged={props.isChanged}
            isReadonly={props.isReadonly}
            isOpen={props.isOpen}
            onEdit={props.onEdit}
            onRevert={props.onRevert}
            onOpen={props.onOpen}
          />
        }
      </Box>
    </>
  )
}

export default JobViewQuoteFormTaskListItem;