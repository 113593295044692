import { Box } from "@mui/material";
import { Route, Switch } from "react-router-dom";
import { CompanyProfileRouteDefintions } from "../CompanyProfileRoutes";
import CompanyProfileDetailView from "./CompanyProfileDetailView";

const CompanyProfileDetail = () => {
  return (
    <>
      <Box sx={{ mb: 2 }}>
        <CompanyProfileDetailView />
      </Box>
      <Switch>
        <Route
          path={CompanyProfileRouteDefintions.COMPANY_PROFILE_EDIT.path}
          component={CompanyProfileRouteDefintions.COMPANY_PROFILE_EDIT.component}
        />
      </Switch>
    </>
  )
}

export default CompanyProfileDetail;