import { CoreState } from "@build-buddy/core";
import { createAsyncThunk } from "@reduxjs/toolkit";

interface InitialiseParams {
  userId: string;
}
const initialise: any = createAsyncThunk("app/initialise",
  async (params: InitialiseParams, { getState, rejectWithValue, dispatch }) => {
    const getUserResp = await dispatch(CoreState.User.getUser.initiate({ userId: params.userId }));
    const getCompanyListByUserResp = await dispatch(CoreState.Company.getCompanyListByUser.initiate({ userId: params.userId })); 
    const getInvitesListByUserresp = await dispatch(CoreState.Membership.getInviteListByUser.initiate({ userId: params.userId }));
    
    // If companyId is already present in url we use that otherwise default to the 1st companyId
    const urlSplit = window.location.pathname.split('/');
    const prevCompanyId = (urlSplit.length > 2) ? urlSplit[2] : undefined;
    const isValidCompanyId = getCompanyListByUserResp.data?.find((company) => company.id === prevCompanyId);
    const companyId = isValidCompanyId ? prevCompanyId : getCompanyListByUserResp.data?.[0]?.id;

    if(companyId) await dispatch(CoreState.Company.getCompany.initiate({ companyId }));

    return {
      companyId
    }
  }
);

export default initialise;