
// import { CurrentSelectors } from "common/state";

interface MinimalProps {
  children: any;
}
const Minimal = (props: MinimalProps) => {

  // const current = useSelector(CurrentSelectors.selectCurrent);
  // const getUserInvitationListQuery = Core.CoreState.Invitation.getUserInvitationList.useQuery({ userId: current.userId });

  // const steps = getUserInvitationListQuery.data?.companyInvitation.length ? ["Verify your account", "Your details"] : ["Verify your account", "Your details", "Setup your company"];

  return (
   <> {props.children} </>
  );
};
export default Minimal;
