import { CoreComponents, CoreState, OnboardingActions, OnboardingHeader } from "@build-buddy/core";
import { Edit as EditIcon } from "@mui/icons-material";
import { Card, CardContent, Grid, Grow } from "@mui/material";
import { useDispatch, useSelector } from "common/state/store";
import { useFormik } from "formik";
import { useEffect, useState } from "react";
import MoreCompanyDetailsValidation from "./MoreCompanyDetailsValidation";

const MoreCompanyDetails = () => {
  const dispatch = useDispatch();

  // params
  const userId = useSelector(CoreState.User.selectCurrentUserId);
  const companyListParams = { userId };

  // mutations
  const [updateCompanyAction, updateCompanyQuery] = CoreState.Company.updateCompany.useMutation();
  const [uploadLogoAction, uploadLogoQuery] = CoreState.File.uploadAvatarByCompany.useMutation();
  const [addCompanyAddressAction, addCompanyAddressQuery] = CoreState.Address.addAddressByCompany.useMutation();

  // locals
  const [open, setOpen] = useState(false);
  const company = useSelector((s) => CoreState.Company.selectCompanyListByUserCurrentCompany(s, companyListParams))
  const isSaving = updateCompanyQuery.isLoading || addCompanyAddressQuery.isLoading || uploadLogoQuery.isLoading;
  const isSuccess = updateCompanyQuery.isSuccess && addCompanyAddressQuery.isSuccess && uploadLogoQuery.isSuccess;

  const formik = useFormik({
    initialValues: {
      logo: {
        url: "",
        file: "",
      },
      address: {
        search: "",
        number: "",
        street1: "",
        street2: "",
        suburb: "",
        state: "",
        postcode: "",
        country: "",
        longitude: "",
        latitude: "",
      },
      website: "",
    },
    onSubmit: (values: any) => {
      updateCompanyDetails(values)
    },
    validationSchema: MoreCompanyDetailsValidation,
  });

  const updateCompanyDetails = async (values: any) => {
    if (!company.id) return;

    const updateCompanyResp = await updateCompanyAction({
      companyId: company.id,
      company: {
        website: values.website,
        version: company?.version
      }
    });

    if ("error" in updateCompanyResp) return;
    if (!updateCompanyResp.data || !updateCompanyResp.data.success) return;

    await addCompanyAddressAction({
      companyId: company.id,
      address: {
        number: values.address.number,
        street1: values.address.street1,
        street2: values.address.street2,
        suburb: values.address.suburb,
        state: values.address.state,
        postcode: values.address.postcode,
        country: values.address.country,
        longitude: values.address.longitude,
        latitude: values.address.latitude,
        type: "Primary"
      }
    })

    await uploadLogoAction({
      companyId: company.id,
      logo: { file: values.logo.file }
    })
  }

  const handleLogoChange = (url: string, file: File) => {
    formik.setFieldValue("logo", { url, file });
    setOpen(false);
  };

  const handlePlaceChange = (place: any) => {
    formik.setFieldValue("address", {
      search: place.search,
      number: place.number,
      street1: [place.streetNumber, place.street].filter(Boolean).join(" "),
      street2: place.street2,
      suburb: place.suburb,
      state: place.state,
      postcode: place.postcode,
      country: place.country,
      longitude: place.longitude,
      latitude: place.latitude,
    });
  };

  useEffect(() => {
    if (!isSuccess) return;
    dispatch(OnboardingActions.nextStep());
  }, [isSuccess]);

  return (
    <>
      <OnboardingHeader />
      <Grow in={true}>
        <Card sx={{ maxWidth: 600, p: 2, pb: 0, mx: "auto" }}>
          <CardContent>
            <Grid container rowSpacing={1} columnSpacing={2} sx={{ mt: 2 }}>
              <Grid item xs={12}>
                <CoreComponents.Label label="Upload Your Avatar" sx={{ mb: 1 }} />
                <CoreComponents.UploadAvatar
                  src={formik.values.logo.url}
                  icon={EditIcon}
                  open={open}
                  onSave={handleLogoChange}
                  onClick={() => setOpen(true)}
                  sx={{ mb: 2 }}
                />
              </Grid>
              <Grid item xs={12}>
                <CoreComponents.FormikAutocompletePlace
                  formik={formik}
                  label="Current Business Address*"
                  name="address.search"
                  onPlaceChange={(p: any) => {
                    handlePlaceChange(p);
                  }}
                  onTextChange={(e: any) => {
                    formik.setFieldValue("address", {});
                    formik.handleChange(e);
                  }}
                  size="small"
                />
              </Grid>
              <Grid item xs={12}>
                <CoreComponents.FormikTextbox
                  formik={formik}
                  name="website"
                  label="Website"
                  fullWidth
                  size="small"
                />
              </Grid>

              <Grid item xs={12}>
                <CoreComponents.Actions
                  sx={{
                    flexDirection: "column-reverse",
                    mt: 2
                  }}
                  buttonSx={{
                    mt: 2,
                    mr: 0,
                  }}
                  onSubmitClick={() => formik.handleSubmit()}
                />
              </Grid>
            </Grid>
          </CardContent>
          <CoreComponents.Loader show={isSaving} />
        </Card>
      </Grow>
    </>
  );
};
export default MoreCompanyDetails;
