import { CoreState, coreReducer } from "@build-buddy/core/dist";
import { ApiSlice } from "@build-buddy/core/dist/state";
import { configureStore } from "@reduxjs/toolkit";
import { OnboardingSlice } from "modules/onboarding/OnboardingSlice";
import {
  TypedUseSelectorHook,
  useDispatch as useReduxDispatch,
  useSelector as useReduxSelector
} from "react-redux";
import AppReducer from "./app/AppReducer";

const configureAppStore = () => {
  return configureStore({
    reducer: {
      // core
      ...coreReducer,

      // NEW
      AppReducer,
      [ApiSlice.reducerPath]: ApiSlice.reducer,
      [OnboardingSlice.reducerPath]: OnboardingSlice.reducer,
    },
    middleware: (getDefaultMiddleware) => {
      return getDefaultMiddleware()
        .concat(ApiSlice.middleware)
        .concat(CoreState.Notification.NotificationHandlerMiddleware)
    }
  });
};

const store = configureAppStore();

export type BuildBuddyProStateType = ReturnType<typeof store['getState']>;
export const useSelector: TypedUseSelectorHook<BuildBuddyProStateType> = useReduxSelector;
export const useDispatch: () => typeof store['dispatch'] = useReduxDispatch;

export default store;
