import { CoreModules, CoreState } from "@build-buddy/core";
import { useRouting } from "@build-buddy/core/dist/routing";
import { Add as AddIcon } from "@mui/icons-material";
import { Box, Button, Stack, Typography, useTheme } from "@mui/material";
import { CommonState } from "common";
import { JobStatus } from "common/state/job";
import { useSelector } from "common/state/store";
import { Route } from "react-router";
import { useQueryJob } from "../_hooks";
import { selectJobStatus } from "../_selectors";
import useJobView from "../useJobView";
import JobViewVariationActions from "./JobViewVariationActions";
import JobViewVariationsRouteDefinitions from "./JobViewVariationsRouteDefinitions";

const useVm = () => {
  const jobView = useJobView();
  const queryJob = useQueryJob();
  const getTaskDefects = CoreState.Variation.getProjectTaskVariationList.useQuery({
    projectId: jobView.params.projectId,
    stageId: jobView.params.stageId,
    taskId: jobView.params.taskId
  });
  const jobStatus = useSelector((s) => selectJobStatus(s, queryJob.jobParams));
  const showVariationActions = [
    CommonState.Job.JobStatus.InProgress,
    CommonState.Job.JobStatus.InQA,
    CommonState.Job.JobStatus.AwaitingSignOff,
    CommonState.Job.JobStatus.AwaitingConstruction,
    CommonState.Job.JobStatus.AwaitingOwner,
    CommonState.Job.JobStatus.AwaitingToStart,
  ].includes(jobStatus as JobStatus);

  return {
    isDefectsLoading: getTaskDefects.isLoading,
    defects: getTaskDefects.data,
    disableVariationActions: !showVariationActions
  }
}
const JobViewVariations = () => {
  const vm = useVm();
  const taskView = useJobView();
  const theme = useTheme();
  const routing = useRouting()

  const handleAdd = (type: string) => {
    const params = {
      pid: taskView.params.projectId,
      sid: taskView.params.stageId,
      qid: taskView.params.quoteRequestId,
      tid: taskView.params.taskId,
    };
    routing.go(JobViewVariationsRouteDefinitions.JOB_VIEW_VARIATIONS_ADD, { params });
  }

  return (
    <>
      <Stack direction={"column"} sx={{ display: "flex", gap: 4 }}>
        <Box sx={{ display: 'flex', flexDirection: 'column', gap: 1 }}>
          <Stack direction="column" >
            <Stack direction="row" alignItems="center" sx={{ display: "flex", gap: 1 }}>
              <Typography
                variant="h2"
                sx={{
                  fontSize: "18px",
                  fontWeight: "600"
                }}>
                Variations
              </Typography>
            </Stack>
            <Button
              variant="none"
              hidden={vm.disableVariationActions}
              onClick={() => handleAdd('variation')}
              sx={{
                color: theme.palette.primary.main,
                justifyContent: "flex-start",
                borderRadius: 0,
                mt: 1
              }}
            >
              <AddIcon sx={{ mr: 1.25 }} />
              Add Variation
            </Button>

          </Stack>
          <CoreModules.Task.TaskView.TaskViewVariations
            variations={vm.defects}
            isLoading={vm.isDefectsLoading}
            actions={JobViewVariationActions}
          />
        </Box>
      </Stack>
      <Route
        path={JobViewVariationsRouteDefinitions.JOB_VIEW_VARIATIONS_ADD.path}
        component={JobViewVariationsRouteDefinitions.JOB_VIEW_VARIATIONS_ADD.component}
      />
      <Route
        path={JobViewVariationsRouteDefinitions.JOB_VIEW_VARIATIONS_EDIT.path}
        component={JobViewVariationsRouteDefinitions.JOB_VIEW_VARIATIONS_EDIT.component}
      />
    </>
  );
}
export default JobViewVariations;