import { OnboardingHeader } from "@build-buddy/core";
import { Route, Switch } from "react-router-dom";

import SetupServiceAreaContent from "./SetupServiceAreaContent";
import ServiceAreaRouteDefinitions from "./ServiceAreaRouteDefinitions";

const SetupServiceArea = () => {
  return (
    <>
      <OnboardingHeader />
      <SetupServiceAreaContent />
      <Switch>
        <Route
          path={ServiceAreaRouteDefinitions.SERVICE_AREA_ADD.path}
          component={ServiceAreaRouteDefinitions.SERVICE_AREA_ADD.component}
        />
        <Route
          path={ServiceAreaRouteDefinitions.SERVICE_AREA_EDIT.path}
          component={ServiceAreaRouteDefinitions.SERVICE_AREA_EDIT.component}
        />
      </Switch>
    </>
  )
}

export default SetupServiceArea;