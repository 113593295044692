import * as CoreState from "@build-buddy/core/dist/state";
import { createSelector } from "@reduxjs/toolkit";

export interface SelectIsCompanyHaveWalletParams {
  userId: string;
  companyId: string;
}

const selectIsCompanyHaveWallet = createSelector(
  [(state: CoreState.RootState, params: SelectIsCompanyHaveWalletParams) => (
    CoreState.Wallet.getFinancialDetails.select({
      userId: params.userId,
      companyId: params.companyId,
    })(state)
  )],
  (financialsDetails) => Boolean(financialsDetails.data?.walletDetails?.hasOrgWallet)
)

export default selectIsCompanyHaveWallet

