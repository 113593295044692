import { CoreState } from "@build-buddy/core";
import { createSelector } from "@reduxjs/toolkit";

export interface SelectJobViewQuoteListParams extends CoreState.Quote.SelectQuoteListByCompanyParams {};
export const selectJobViewQuoteList = createSelector(
  [CoreState.Quote.selectQuoteListByCompany],
  (quoteList) => quoteList
)

export interface SelectJobViewQuoteListByStatusParams extends SelectJobViewQuoteListParams {
  status: CoreState.Quote.QuoteStatus
};
export const selectJobViewQuoteListByStatus = createSelector(
  [
    (state, params) => selectJobViewQuoteList(state, params),
    (_, params) => ({ status: params.status })
  ], 
  (quoteList, meta) => {
    if(!quoteList) return;
    return quoteList.filter(x => x.status === meta.status);
  }
)