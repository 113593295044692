import { CoreComponents, CoreState } from "@build-buddy/core";
import { Button } from "@mui/material";
import { useSelector } from "common/state/store";
import { useState } from "react";
import { useJobViewParams, useQueryJob } from "../../_hooks";
import { selectJobQuoteCurrent, selectJobTask } from "../../_selectors";
import { CommonState } from "common";

const JobViewActionsStart = () => {
  const { projectId } = useJobViewParams();

  // states
  const [showConfirmationModal, setShowConfirmationModal] = useState(false)

  // queries
  const queryJob = useQueryJob();
  const query = CommonState.Job.getJob.useQuery(queryJob.jobParams)

  // selectors
  const jobTask = useSelector((s) => selectJobTask(s, queryJob.jobParams));
  const jobQuote = useSelector((s) => selectJobQuoteCurrent(s, queryJob.jobParams));

  // Flags
  const isPaymentRequested = jobQuote?.paymentSchedule?.find(schedule => schedule.status !== CoreState.Quote.PaymentScheduleStatus.NA)

  // mutations
  const [startTaskAction, startTaskQuery] = CoreState.Task.startTask.useMutation();

  // event handlers
  const startJob = () => {
    if (!jobTask) return;

    startTaskAction({
      projectId: projectId,
      taskId: jobTask.parentId,
      subTaskId: jobTask.id
    })
  }
  return (
    <>
      <Button
        variant="contained"
        color="primary"
        onClick={() => !isPaymentRequested ? setShowConfirmationModal(true) : startJob()}
        sx={{
          flex: {
            xs: 1,
            md: "inherit"
          }
        }}
      >
        Start Job
      </Button>

      <CoreComponents.Confirmation
        open={showConfirmationModal}
        loading={startTaskQuery.isLoading}
        title="Reminder!"
        text="Don't forget to Request Payment from the Payment tab, if applicable."
        confirmText="Start Job"
        onCancel={() => setShowConfirmationModal(false)}
        onConfirm={startJob}
        onClose={() => setShowConfirmationModal(false)}
      />
    </>
  )
}

export default JobViewActionsStart;