import { createSelector } from "@reduxjs/toolkit"
import { CoreState } from "@build-buddy/core"
import { PaymentScheduleStatus } from "@build-buddy/core/dist/state/quote"
import { selectActiveJobSpecs } from "./selectJobSpecs"
import { selectJobQualityAssuranceListByQuoteRequestGroupedLength } from "./selectJobQualityAssurance"
import { selectJobViewQuoteList } from "../job-view-quote-list/_selectors/selectJobViewQuoteList"
import { JobRouteDefinitions } from "../../JobRoutes"
import { selectJobQuoteCurrent } from "./selectJob"
import { BuildBuddyProStateType } from "common/state/store"

export const selectJobViewDescriptionTab = createSelector(
  (state: BuildBuddyProStateType) => state,
  () => {
    return {
      ...JobRouteDefinitions.JOB_VIEW_DESCRIPTION,
      count: 0,
      label: "Description",
    }
  }
)

export const selectJobViewDocumentsTab = createSelector(
  [CoreState.File.selectDocListByTaskExcludedTags],
  (result) => {
    return {
      ...JobRouteDefinitions.JOB_VIEW_DOCUMENTS,
      label: "Documents",
      count: result.length
    }
  }
)

export const selectJobViewScopeOfWorkTab = createSelector(
  [selectActiveJobSpecs],
  (result) => {
    return {
      ...JobRouteDefinitions.JOB_VIEW_SCOPE_OF_WORK,
      label: "Scope Of Works",
      count: result?.length
    }
  }
)

export const selectJobViewQuotesTab = createSelector(
  [selectJobViewQuoteList],
  (result) => {
    return {
      ...JobRouteDefinitions.JOB_VIEW_QUOTES,
      label: "My Quotes",
      count: result?.length
    }
  }
)

export const selectJobViewMessagesTab = createSelector(
  [(state: CoreState.RootState, params) => CoreState.NoteThread.getQuoteNoteThreadList.select(params)(state)],
  (result) => {
    return {
      ...JobRouteDefinitions.JOB_VIEW_MESSAGES,
      label: "Messages",
      count: result.data?.length
    }
  }
)

export const selectJobViewCompanyVariationTab = createSelector(
  [CoreState.Variation.selectVariationListByProjectUnresolvedVariationList],
  (variations) => {
    return {
      ...JobRouteDefinitions.JOB_VIEW_VARIATIONS,
      label: "Variations",
      count: variations?.length ?? 0
    }
  }
)

export const selectJobViewCompanPaymentsTab = createSelector(
  [selectJobQuoteCurrent],
  (result) => {
    const unpaid = result?.paymentSchedule?.filter((payment) => ![PaymentScheduleStatus.Paid, PaymentScheduleStatus.NotRequired].includes(payment.status))
    return {
      ...JobRouteDefinitions.JOB_VIEW_PAYMENT_SCHEDULE,
      label: "Payments",
      count: unpaid?.length ?? 0
    }
  }
)

export const selectJobViewQualityAssuranceTab = createSelector(
  [(state: CoreState.RootState, params) => selectJobQualityAssuranceListByQuoteRequestGroupedLength(state, params)],
  (count) => {
    return {
      ...JobRouteDefinitions.JOB_VIEW_QUALITY_ASSURANCE,
      count,
    };
  }
)
