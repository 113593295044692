import ServiceAreaAdd from "./ServiceAreaAdd";
import ServiceAreaEdit from "./ServiceAreaEdit";

const ServiceAreaRouteDefinitions = {
  SERVICE_AREA_ADD: {
    name: "Add Service Area",
    path: "*/service-area/add",
    component: ServiceAreaAdd,
  },
  SERVICE_AREA_EDIT: {
    name: "Edit Service Area",
    path: "*/service-area/:said/edit",
    component: ServiceAreaEdit,
  }
}

export default ServiceAreaRouteDefinitions;