import { CommonState } from "common";
import { useSelector } from "common/state/store";
import { useQueryJob } from "../_hooks";
import { selectJobStatus } from "../_selectors";
import JobViewActionsConfirm from "./job-view-actions-confirm/JobViewActionsConfirm";
import JobViewActionsMessageContainer from "./job-view-actions-message/JobViewActionsMessageContainer";
import JobViewActionsQuote from "./job-view-actions-quote/JobViewActionsQuote";
import JobViewActionsReview from "./job-view-actions-review/JobViewActionsReview";
import JobViewActionsStart from "./job-view-actions-start/JobViewActionsStart";

const useVm = () => {
  // queries
  const queryJob = useQueryJob();

  // selectors
  const jobStatus = useSelector((s) => selectJobStatus(s, queryJob.jobParams));

  return {
    status: jobStatus,
  };
}

const JobViewActions = () => {
  const vm = useVm();

  if (!vm.status) return <></>;

  const kvp: any = {
    [CommonState.Job.JobStatus.New]: (
      <JobViewActionsQuote onClick={() => { }} />
    ),
    [CommonState.Job.JobStatus.QuoteDeclined]: (
      <JobViewActionsQuote onClick={() => { }} />
    ),
    [CommonState.Job.JobStatus.QuoteSubmitted]: (
      <></>
    ),
    [CommonState.Job.JobStatus.AwaitingConfirmation]: (
      <>
        <JobViewActionsConfirm />
      </>
    ),
    [CommonState.Job.JobStatus.AwaitingToStart]: (
      <>
        <JobViewActionsStart />
      </>
    ),
    [CommonState.Job.JobStatus.InProgress]: (
      <>
        <JobViewActionsReview />
      </>
    ),
    [CommonState.Job.JobStatus.AwaitingSignOff]: (
      <></>
    ),
  }

  return (
    <>
      {kvp[vm.status]}
      <JobViewActionsMessageContainer />
    </>
  )
}
export default JobViewActions;