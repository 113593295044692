import { CoreState } from "@build-buddy/core";
import JobStatus from "../../models/JobStatus";

const transformJobStatusIsAwaitingConstruction = (
  task: CoreState.Task.Task,
  taskQuoteRequest: CoreState.Quote.QuoteRequest,
  quotes?: Array<CoreState.Quote.Quote>
) => {

  if (task.type!==CoreState.Task.TaskType.AwaitingDetailST) return

  return JobStatus.AwaitingConstruction;
}
export default transformJobStatusIsAwaitingConstruction