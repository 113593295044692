import { Box, Tooltip, Typography, useTheme } from "@mui/material";
import JobViewQuoteFormPaymentScheduleTemplate from "./JobViewQuoteFormPaymentScheduleTemplate";
import { QuestionMark as QuestionMarkIcon } from '@mui/icons-material';
import { useMode } from "@build-buddy/core";

const JobViewQuoteFormPaymentScheduleHeader = () => {
  const theme = useTheme();
  const mode = useMode()

  if(mode.isMobile) return null;

  return (
    <JobViewQuoteFormPaymentScheduleTemplate
      sx={{ 
        borderBottom:`dotted 1px ${theme.palette.grey[300]}`,
        mx: -3,
        px: 3,
        pb: 1.5
      }}
    >
      <Typography variant="caption" sx={{ gridArea: 'stage', fontWeight: "bold", fontSize: "12px" }}> Stage </Typography>
      <Box
        sx={{ gridArea: 'paymentType', display: 'flex', alignItems: 'center', justifyContent: 'center'}}
      >
        <Typography
          variant="caption"
          sx={{ fontWeight: "bold", fontSize: "12px" }}
        >
          Payment Type
        </Typography>
        <Tooltip
          title={
            <>
              <Typography fontSize={10}>Wallet: Payment is allocated via the Digital Wallet on commencement and released on completion</Typography>
              <Typography fontSize={10}>Direct: Payment is made directly via Pay Now upon request</Typography>
            </>
          }
        >
          <Box
            sx={{
              background: '#aaa',
              color: 'white',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              borderRadius: '50%',
              p: 1,
              height: 15,
              width: 15,
              cursor: 'pointer',
              ml: 1
            }}
          >
            <QuestionMarkIcon sx={{ fontSize: 12 }} />
          </Box>
        </Tooltip>
      </Box>
      <Typography variant="caption" sx={{ gridArea: 'amount', textAlign: "right", fontWeight: "bold", fontSize: "12px" }}> Amount </Typography>
      <Typography variant="caption" sx={{ gridArea: 'actions', fontWeight: "bold" }}> &nbsp; </Typography>
    </JobViewQuoteFormPaymentScheduleTemplate>
  )
}

export default JobViewQuoteFormPaymentScheduleHeader;