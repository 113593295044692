import { CoreState, CoreUtils } from "@build-buddy/core";
import JobViewQualityAssuranceDefectsActionsApproved from "./JobViewQualityAssuranceDefectsActionsApproved";
import JobViewQualityAssuranceDefectsActionsAwaiting from "./JobViewQualityAssuranceDefectsActionsAwaiting";
import JobViewQualityAssuranceDefectsActionsInDispute from "./JobViewQualityAssuranceDefectsActionsInDispute";
import JobViewQualityAssuranceDefectsActionsRejected from "./JobViewQualityAssuranceDefectsActionsRejected";

type JobViewQualityAssuranceDefectsActionsProps = {
  defect: CoreState.Defect.Defect;
}
const JobViewQualityAssuranceDefectsActions = (props: JobViewQualityAssuranceDefectsActionsProps) => {
  const { defect } = props;

  const rtn = CoreUtils.chainOfResponsibility<JobViewQualityAssuranceDefectsActionsProps>({ defect })
    .add(JobViewQualityAssuranceDefectsActionsAwaiting)
    .add(JobViewQualityAssuranceDefectsActionsApproved)
    .add(JobViewQualityAssuranceDefectsActionsRejected)
    .add(JobViewQualityAssuranceDefectsActionsInDispute)
    .execute<any>();

  return rtn || null;
}
export default JobViewQualityAssuranceDefectsActions;