import { CoreState } from "@build-buddy/core";
import { AuthProvider } from "@build-buddy/core/dist/modules/auth";
import { AppInsightsContext } from "@microsoft/applicationinsights-react-js";
import { ThemeProvider } from "@mui/material";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFnsV3";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { defaultTheme } from "common";
import { Provider } from "react-redux";
import { BrowserRouter } from "react-router-dom";
import { reactPlugin } from "./AppInsights";
import config from "./config.json";
import store from "./state/store";

const Providers = (props: any) => {
  const { auth0ClientId } = config;
  return (
    <Provider store={store}>
      <ThemeProvider theme={defaultTheme}>
        <CoreState.Notification.SnackbarProvider>
          <LocalizationProvider dateAdapter={AdapterDateFns}>
              <AppInsightsContext.Provider value={reactPlugin}>
                <AuthProvider clientId={auth0ClientId}>
                  <BrowserRouter>{props.children}</BrowserRouter>
                </AuthProvider>
              </AppInsightsContext.Provider>
          </LocalizationProvider>
        </CoreState.Notification.SnackbarProvider>
      </ThemeProvider>
    </Provider>
  );
};

export default Providers;
