import { CoreComponents, CoreRouting, CoreState } from "@build-buddy/core";
import { useSelector } from "common/state/store";
import { useCompanyParams } from "modules/company/_hooks";
import { useEffect } from "react";
import { CompanyProfileRouteDefintions } from "../CompanyProfileRoutes";
import { useCompanyProfileSkillParams } from "./_hooks";

const CompanyProfileInsuranceAdd = () => {
  const { download } = CoreState.File.useDownloadQueue();
  const routing = CoreRouting.useRouting();

  // params
  const { companyId } = useCompanyParams();
  const { code } = useCompanyProfileSkillParams();
  const getSkillListByCompanyParams = { companyId, type: code };

  // queries
  const getSkillByCompanyQuery = CoreState.Skill.getSkillByCompany.useQuery(getSkillListByCompanyParams);
  CoreState.Skill.getSkillDefinitions.useQuery();

  // mutations
  const [updateSkillByCompanyAction, updateSkillByCompanyQuery] = CoreState.Skill.updateSkillByCompany.useMutation();
  const [uploadDocListByCompanySkillAction, uploadDocListByCompanySkillQuery] = CoreState.File.uploadDocListByCompanySkill.useMutation();
  const [deleteDocListByCompanySkillAction, deleteDocListByCompanySkillQuery] = CoreState.File.deleteDocListByCompanySkill.useMutation();

  // locals
  const skill = useSelector((s) => CoreState.Skill.selectSkillByCompany(s, getSkillListByCompanyParams));
  const definitions = useSelector(CoreState.Skill.selectSkillDefinitions);
  const isLoading = Boolean(getSkillByCompanyQuery.isLoading);
  const isSaving = Boolean(updateSkillByCompanyQuery.isLoading || uploadDocListByCompanySkillQuery.isLoading);
  const isSuccess =
    // addInsurance success
    Boolean(updateSkillByCompanyQuery.isSuccess) &&
    // if we initiated the uploadDoc, then we check its success, otherwise its always true
    Boolean(uploadDocListByCompanySkillQuery.isUninitialized || uploadDocListByCompanySkillQuery.isSuccess) &&
    Boolean(deleteDocListByCompanySkillQuery.isUninitialized || deleteDocListByCompanySkillQuery.isSuccess)

  // events
  const handleClose = () => {
    routing.go(CompanyProfileRouteDefintions.COMPANY_PROFILE)
  }

  const handleSubmit = async (values: any) => {
    if (!companyId) return;

    const promises = [];

    const skillValues: CoreState.Common.Subset<CoreState.Skill.Skill> = {
      type: values.type,
      reference: values.number,
      expiry: values.expiry,
      subSkills: values.subSkills.map((s: string) => ({ type: s })),
      version: skill?.version,
    }
    promises.push(
      updateSkillByCompanyAction({
        companyId,
        skill: skillValues
      })
    )

    const docsToSave: Array<CoreState.Common.Subset<CoreState.File.Doc>> = values.files.toSave;
    if (docsToSave.length) {
      promises.push(
        uploadDocListByCompanySkillAction({
          companyId,
          type: values.type,
          docs: docsToSave,
        })
      )
    }

    const docsToRemove: Array<CoreState.Common.Subset<CoreState.File.Doc>> = values.files.toRemove;
    if (docsToRemove.length) {
      promises.push(
        deleteDocListByCompanySkillAction({
          companyId,
          type: values.type,
          docs: docsToRemove,
        })
      )
    }

    try {
      await Promise.all(promises);
    } catch (e) {
      // handle error
    }
  }

  const handleDownload = (doc: CoreState.File.Doc) => {
    download(doc);
  }

  useEffect(() => {
    if (!isSuccess) return;
    handleClose();
  }, [isSuccess])

  if (!skill) return null;

  return (
    <CoreComponents.SkillFormDialog
      title="Edit Skill"
      skill={skill}
      definitions={definitions}
      isLoading={isLoading}
      isSaving={isSaving}
      onClose={() => handleClose()}
      onSubmit={(values) => handleSubmit(values)}
      onDownload={(_, doc) => handleDownload(doc)}
    />
  )
}
export default CompanyProfileInsuranceAdd;