import { CoreRouting } from "@build-buddy/core";
import { JobRouteDefinitions } from "modules/company/job/JobRoutes";
import { useQueryJob } from "../../_hooks";
import JobViewActionsMessage from "./JobViewActionsMessage";

const JobViewActionsMessageContainer = () => {
  const routing = CoreRouting.useRouting();
  const queryJob = useQueryJob();

  const handleMessageButtonClick = () => {
    routing.go(JobRouteDefinitions.JOB_VIEW_MESSAGES, { params: queryJob.jobParams })
  }

  return (
    <>
      <JobViewActionsMessage onClick={handleMessageButtonClick} />
    </>
  )
}

export default JobViewActionsMessageContainer;