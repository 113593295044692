import { CoreRouting, CoreState } from "@build-buddy/core";
import { RouteDefinitions } from "common";
import { useEffect } from "react";
import { useJobViewParams } from "../_hooks";
import JobViewQuoteForm from "./job-view-quote-form/JobViewQuoteForm";
import JobViewQuoteFormValues from "./job-view-quote-form/JobViewQuoteFormValues";
import { JobRouteDefinitions } from "../../JobRoutes";

const JobViewQuoteAdd = () => {
  // hooks
  const routing = CoreRouting.useRouting();

  // params
  const { projectId, quoteRequestId, companyId, taskId } = useJobViewParams();

  // mutations
  const [addQuoteAction, addQuoteQuery] = CoreState.Quote.addQuote.useMutation();
  const [uploadDocListByQuoteAction, uploadDocListByQuoteQuery] = CoreState.File.uploadDocListByQuote.useMutation();

  // locals
  const isSaving = addQuoteQuery.isLoading || uploadDocListByQuoteQuery.isLoading;
  const isSuccess = addQuoteQuery.isSuccess || uploadDocListByQuoteQuery.isSuccess

  // event handlers
  const handleClose = () => {
    const params = { tid: taskId };
    routing.go(JobRouteDefinitions.JOB_VIEW_QUOTES, { params });
  }

  const handleSave = async (values: JobViewQuoteFormValues) => {
    const addQuoteResp = await addQuoteAction({
      projectId: projectId,
      quoteRequestId: quoteRequestId,
      taskQuote: {
        company: {
          id: companyId
        },
        cost: values.totalCost,
        status: CoreState.Quote.QuoteStatus.Submitted,
        paymentSchedule: values.paymentSchedule.map((ps: any) => ({
          amount: ps.amount,
          description: ps.name,
          status: CoreState.Quote.PaymentScheduleStatus.NA,
          paymentType: ps.isImmediate ? CoreState.Quote.PaymentScheduleType.ImmediatePayment : CoreState.Quote.PaymentScheduleType.StagePayment
        })),
        manageTasks: values.tasks.map((t: any) => ({
          taskPublicId: t.id,
          name: t.name,
          startDate: t.startDate,
          endDate: t.endDate,
          changed: t.changed,
          originalStartDate: t.originalStartDate,
          originalEndDate: t.originalEndDate,
        })),
        specialConditions: values.specialConditions
      }
    })

    if ('error' in addQuoteResp) return;

    const taskQuote = addQuoteResp.data;

    if (!taskQuote) return;

    if (!values.files || !values.files.toSave || !values.files.toSave.length) return;

    await uploadDocListByQuoteAction({
      projectId: projectId,
      quoteRequestId: quoteRequestId,
      workOrderId: taskQuote.id,
      docs: values.files.toSave,
      companyId: companyId
    })
  }

  // side effects
  useEffect(() => {
    if (!isSuccess) return;
    handleClose();
  }, [isSuccess])

  return (
    <JobViewQuoteForm
      title="Submit Quote"
      saveButtonText="Submit"
      isSaving={isSaving}
      isOpen={true}
      onClose={() => handleClose()}
      onSave={handleSave}
    />
  )
}
export default JobViewQuoteAdd;