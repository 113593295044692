
import { Route } from "react-router";
import CompanyProfileSkillAdd from "./CompanyProfileSkillAdd";
import CompanyProfileSkillEdit from "./CompanyProfileSkillEdit";

const CompanyProfileSkillRoutesDefinitions = {
  COMPANY_PROFILE_SKILL_ADD: {
    path: "/company/:cid/profile/skill/add",
    component: CompanyProfileSkillAdd,
  },
  COMPANY_PROFILE_SKILL_EDIT: {
    path: "/company/:cid/profile/skill/:id/edit",
    component: CompanyProfileSkillEdit,
  }
}

const CompanyProfileSkillRoutes = () => {
  return (
    <>
      <Route
        path={CompanyProfileSkillRoutesDefinitions.COMPANY_PROFILE_SKILL_ADD.path}
        component={CompanyProfileSkillRoutesDefinitions.COMPANY_PROFILE_SKILL_ADD.component}
      />
      <Route
        path={CompanyProfileSkillRoutesDefinitions.COMPANY_PROFILE_SKILL_EDIT.path}
        component={CompanyProfileSkillRoutesDefinitions.COMPANY_PROFILE_SKILL_EDIT.component}
      />
    </>
  );
};

export { CompanyProfileSkillRoutesDefinitions, CompanyProfileSkillRoutes };
