import { ChatIcon } from "@build-buddy/core";
import { IconButton } from "@mui/material";

interface JobViewActionsMessageProps {
  onClick(): void;
}

const JobViewActionsMessage = (props: JobViewActionsMessageProps) => {
  return (
    <>
      <IconButton
        onClick={props.onClick}
        sx={{ mr: 0.5 }}
      >
        <ChatIcon />
      </IconButton>
    </>
  )
}

export default JobViewActionsMessage;