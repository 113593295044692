import { CoreModules } from "@build-buddy/core";

const InvitationRouteDefinitions = {
  ...CoreModules.Membership.MembershipRouteDefinitions
};

const InvitationRoutes = () => {
  return <CoreModules.Membership.MembershipRoutes />
};

export { InvitationRoutes, InvitationRouteDefinitions };