import { CoreComponents, CoreState, TypeGuards } from "@build-buddy/core";
import { useDispatch, useSelector } from "common/state/store";
import { useEffect } from "react";
import { useJobViewParams, useQueryJob } from "../../_hooks";
import { selectJobDocumentsContract, selectJobQuoteCurrent, selectJobQuoteManageTaskChanged } from "../../_selectors";

type JobViewAcceptQuoteConfirmSignProps = {
  onClose(): void;
}
const JobViewAcceptQuoteConfirmSign = (props: JobViewAcceptQuoteConfirmSignProps) => {
  const dispatch = useDispatch();
  const queryJob = useQueryJob();

  // params
  const { projectId, taskId, companyId } = useJobViewParams();
  const getDocListByTaskParams = { projectId, taskId, companyId };

  // queries
  const getDocListByTaskQuery = CoreState.File.getDocListByTask.useQuery(getDocListByTaskParams);

  // mutations
  const [acceptQuoteAction, acceptQuoteQuery] = CoreState.Quote.acceptQuote.useMutation();
  const [changeDatesTaskAction, changeDatesTaskQuery] = CoreState.Task.changeDatesTask.useMutation();

  // selectors
  const jobQuote = useSelector((s) => selectJobQuoteCurrent(s, queryJob.jobParams));
  const contract = useSelector((s) => selectJobDocumentsContract(s, getDocListByTaskParams));
  const fileState = useSelector((s) => CoreState.File.selectFileState(s, contract?.id));
  const jobQuoteManageTaskChanged = useSelector((s) => selectJobQuoteManageTaskChanged(s, queryJob.jobParams));

  const isLoading = getDocListByTaskQuery.isLoading;
  const isSaving = acceptQuoteQuery.isLoading || changeDatesTaskQuery.isLoading; 
  const isSaved = acceptQuoteQuery.isSuccess && !acceptQuoteQuery.isUninitialized;
  const quoteId = jobQuote?.id;
  const error = TypeGuards.getFetchBaseQueryError(getDocListByTaskQuery);

  const handleClose = () => {
    props.onClose();
  }

  const handleSubmit = (value: string) => {
    if (!quoteId) return;
    acceptQuoteAction({
      projectId,
      quoteId,
      signature: value
    })

    if (!jobQuoteManageTaskChanged) return;

    changeDatesTaskAction({
      projectId,
      parentTaskId: taskId,
      taskId: jobQuoteManageTaskChanged.taskPublicId,
      startDate: new Date(jobQuoteManageTaskChanged.startDate),
      endDate: new Date(jobQuoteManageTaskChanged.endDate),
      companyId
    })
  }

  useEffect(() => {
    if (!contract) return;
    dispatch(CoreState.File.downloadDocByType(contract));
  }, [contract])

  useEffect(() => {
    if (!isSaved) return;
    handleClose();
  }, [isSaved])

  return (
    <CoreComponents.DocumentSign
      open={true}
      doc={contract}
      url={fileState?.url}
      isLoading={isLoading}
      isSaving={isSaving}
      onClose={handleClose}
      onSubmit={handleSubmit}
      error={error}
    />
  )
}
export default JobViewAcceptQuoteConfirmSign;