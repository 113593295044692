import { alphanumSort, CoreComponents, CoreState, Truncate } from "@build-buddy/core";
import { Box, MenuItem, Skeleton, Stack, useTheme } from "@mui/material";
import { useSelector } from "common/state/store";
import { useState } from "react";
import { selectIsBuildBuddySupport } from "../__selectors";


export const CompanySelectList = (props: any) => {
  const { organisations } = props;
  const theme = useTheme();

  const [searchText, setSearchText] = useState('')

  const userId = useSelector(CoreState.User.selectCurrentUserId);
  const isBuildBuddySupport = useSelector((state) => selectIsBuildBuddySupport(state, { userId }))

  const handleClick = (item: any) => {
    props.onClick(item.id);
  };

  const filteredCompanies = (() => {
    if (!searchText) return organisations;
    const query = searchText.replace(/\s+/g, '').toLowerCase();
    return organisations?.filter((org: any) => {
      const organisationName = org.name.toLowerCase()
      return organisationName.includes(query);
    });
  })();

  const handleKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
    event.stopPropagation();
  };

  if (!organisations) return <></>;

  const data = [...filteredCompanies].sort(alphanumSort);

  return (
    <>
      <Box>
        {isBuildBuddySupport &&
          <MenuItem sx={{ borderBottom: `1px solid ${theme.palette.grey[200]}`, pb: 1 }}>
            <CoreComponents.Textbox
              name="searchText"
              size="small"
              fullWidth
              value={searchText}
              onKeyDown={handleKeyDown}
              onChange={(e) => {
                setSearchText(e.target.value)
              }}
              placeholder="Search Organisations"
              sx={{ mb: 0, mt: 0.5 }}
            />
          </MenuItem>}
        {data?.map((item, idx) => (
          <MenuItem
            key={idx}
            sx={{
              py: 1.5,
              borderBottom: `dashed 1px ${theme.palette.divider}`,
              "&:last-child": {
                borderBottom: "none",
              },
            }}
            onClick={() => handleClick(item)}
          >
            <Stack
              alignItems="center"
              direction="row"
              sx={{
                overflow: "hidden",
                maxWidth: {
                  xs: 200,
                  sm: 300,
                },
              }}
            >
              <Truncate
                lines={1}
                text={item.name || ""}
                TypographyProps={{
                  variant: "body1",
                  fontWeight: 500,
                }}
              />
            </Stack>
          </MenuItem>
        ))}
      </Box>
      {!organisations && (
        <Skeleton height={40} sx={{ mt: -0.25, mx: 1.75, mb: 1.5 }} />
      )}
    </>
  );
};
