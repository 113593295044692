import { CoreRouting, CoreState } from "@build-buddy/core";
import { Edit as EditIcon, Visibility as VisibilityIcon } from "@mui/icons-material";
import { Box, IconButton } from "@mui/material";
import { JobRouteDefinitions } from "modules/company/job/JobRoutes";
import JobViewQuoteListItemStatus from "./JobViewQuoteListItemStatus";

interface JobViewQuoteListItemActionsProps {
  quote: CoreState.Quote.Quote;
}

const JobViewQuoteListItemActions = (props: JobViewQuoteListItemActionsProps) => {
  const { quote } = props;
  const routing = CoreRouting.useRouting()

  // locals
  const showEditAction = [CoreState.Quote.QuoteStatus.Submitted].includes(quote.status)
  const showStatus = [
    CoreState.Quote.QuoteStatus.Accepted,
    CoreState.Quote.QuoteStatus.AwaitingConfirmation,
    CoreState.Quote.QuoteStatus.Declined
  ].includes(quote.status);

  // event handlers
  const handleEditClick = () => {
    if (!quote) return;
    const params = { quoteId: quote.id }
    routing.go(
      JobRouteDefinitions.JOB_VIEW_QUOTE_EDIT,
      { params }
    );
  }

  const handleViewClick = () => {
    if (!quote) return;
    const params = { quoteId: quote.id }
    routing.go(
      JobRouteDefinitions.JOB_VIEW_QUOTE_VIEW,
      { params }
    );
  }

  return (
    <Box
      sx={{
        display: "flex",
        direction: "row",
        alignItems: "center",
        justifyContent: "flex-end"
      }}
    >
      <IconButton
        onClick={() => handleViewClick()}
        sx={{ mr: 0.5 }}
      >
        <VisibilityIcon />
      </IconButton>
      {showEditAction &&
        <IconButton
          onClick={() => handleEditClick()}
        >
          <EditIcon />
        </IconButton>
      }
      {showStatus &&
        <Box
          sx={{
            textAlign: "right"
          }}
        >
          <JobViewQuoteListItemStatus status={quote.status} />
        </Box>
      }
    </Box>
  )
}

export default JobViewQuoteListItemActions