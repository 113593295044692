import { ChatRouteDefinitions } from "@build-buddy/core";
import { CompanyRouteDefinitions } from "modules/company/CompanyRoutes";
import { HelpRoutesRouteDefinitions } from "modules/help/HelpRoutes";
import { InvitationRouteDefinitions } from "modules/invitation";
import OnboardingRoutesDefinitions from "modules/onboarding/OnboardingRouteDefinitions";
import { UserProfileRouteDefinitions } from "modules/user/profile/UserProfilesRoutes";
import { UserWalletRouteDefinitions } from "modules/user/wallet/WalletModule";

interface RouteDefintion {
  [key: string]: {
    [key: string]: any
  }
}
const RouteDefinitions: RouteDefintion = {
  Company: {
    ...CompanyRouteDefinitions,
  },
  Invitation: {
    ...InvitationRouteDefinitions
  },
  // TODO: Refactor
  Onboarding: {
    ...OnboardingRoutesDefinitions
  },
  Chat: {
    ...ChatRouteDefinitions
  },
  UserProfile: {
    ...UserProfileRouteDefinitions
  },
  Wallet: {
    ...UserWalletRouteDefinitions
  },
  Help: {
    ...HelpRoutesRouteDefinitions
  },
}

export default RouteDefinitions;